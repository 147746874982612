<div class="m-1">
    <div class="card shadow mb-2">
        <div class="card-header py-3">
            <div class="row">
                <i class="fas fa-school fa-3x wlm-detail-title-icon text-primary"></i>
                <h4 class="wlm-detail-title-text text-primary">Institución</h4>
            </div>
            <div class="row">
            <p>Nombre:     <b>{{school.name}}</b></p>
            <p>Dirreccion: <b>{{school.full_address}}</b></p>
            <p>Telefono:   <b>{{school.telephone_number}}</b></p>
            <p>Email:      <b>{{school.email}}</b></p>
            <p>Descripcion:<b>{{school.description}}</b></p>
            </div>
            <div class="row">
            <div class="btn-group btn-group-sm justify-content-md-end shadow-none" role="group" aria-label="">
                <button type="button" 
                    (click)="back()" 
                    title="Volver"
                    class="btn btn-outline-primary wlm-button-detail shadow-none">
                    <i class="fa fa-lg fa-arrow-left"></i>
                    <span class="d-none d-lg-block">Volver</span>
                </button>
                <button type="button" 
                    routerLink="/school/{{schoolId}}/{{schoolName}}/edit"
                    title="Editar escuela"
                    class="btn btn-outline-primary shadow-none">
                    <i class="fas fa-lg fa-pen-to-square text-warning"></i>
                    <span class="d-none d-lg-block">Editar Escuela</span>
                </button>
                <button type="button" 
                    (click)="deleteHandler()" 
                    title="Eliminar escuela"
                    class="btn btn-outline-primary shadow-none">
                    <i class="fa fa-lg fa-regular fa-trash  text-danger"></i>
                    <span class="d-none d-lg-block">Eliminar Escuela</span>
                </button>
                <button type="button" 
                    (click)="setViewType(0)" 
                    title="Mostrar Mapa"
                    class="btn btn-outline-primary shadow-none">
                    <i class="fas fa-lg fa-map-location wlm-text-green"></i>
                    <span class="d-none d-lg-block">Mostrar Mapa</span>
                </button>
                <button type="button" 
                    (click)="setViewType(1)" 
                    title="Ver Cursos"
                    class="btn btn-outline-primary shadow-none">
                    <i class="fas fa-graduation-cap fa-lg  text-warning wlm-text-green"></i>
                    <span class="d-none d-lg-block">Ver Cursos</span>
                </button>
            </div>
        </div>
    </div>
        <div class="card-body p-0">
            <ng-container  *ngIf="viewTap === viewTapCourse">
                <app-course-container [schoolReference]="schoolReference"></app-course-container>
            </ng-container>
            <ng-container  *ngIf="viewTap === viewTapMap">
                <app-map [enabled]="false" [addressPoints]="mapPoint"></app-map>
            </ng-container>
        </div>
    </div>

</div>

