import { NgModule } from "@angular/core";
import { GlobalSearchImplementationService } from "./global-search-implementacion.service";
import { GlobalSearchService } from "src/domain/services/global-search.service";
import { NotificationService } from "src/domain/services/notification.service";
import { NotificationImplementationService } from "./notification-implementation.service";
import { SpinnerService } from "src/domain/services/spinner.service";
import { ToastService } from "src/domain/services/toast.service";
import { SpinnerImplementationService } from "./spinner-implementation.service";
import { ToastImplementationService } from "./toast-implementation.service";
import { LocalStorageService } from "src/domain/services/local-storage.service";
import { LocalStorageImplementationService } from "./local-storage-implementation.service";
import { ModalImplementationService } from "./modal-implementation.service";
import { ModalService } from "src/domain/services/modal.service";
import { NavService } from "src/domain/services/nav.service";
import { NavImplementationService } from "./nav-implementation.service";



@NgModule({
    providers: [
       {provide:GlobalSearchService, useClass: GlobalSearchImplementationService},
       {provide:NotificationService, useClass: NotificationImplementationService},
       {provide:LocalStorageService, useClass: LocalStorageImplementationService},
       {provide:SpinnerService, useClass: SpinnerImplementationService},
       {provide:ToastService, useClass: ToastImplementationService},
       {provide:ModalService, useClass: ModalImplementationService},
       {provide:NotificationService, useClass: NotificationImplementationService},
       {provide:NavService, useClass: NavImplementationService},
    ],
    imports: [
       
    ],
})
export class ServicesModule { }