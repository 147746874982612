<div class="bg-gradient-primary">
<div class="container">

  <!-- Outer Row -->
  <div class="row justify-content-center">

      <div class="col-xl-10 col-lg-12 col-md-9">

          <div class="card o-hidden border-0 shadow-lg my-5">
              <div class="card-body p-0">
                  <!-- Nested Row within Card Body -->
                  <div class="row">
                      <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                      <div class="col-lg-6">
                          <div class="p-5">
                            <div class="text-center">
                                <img src="presentation/assets/logo.png" alt="" style="max-width: 80px;">
                            </div>
                              <div class="text-center">
                                  <h1 class="h4 text-gray-900 mb-4">Bienvenido de nuevo!</h1>
                              </div>
                              <form [formGroup]="form" (submit)="loginHandler()" class="user">
                                  <div class="form-group">
                                      <input type="email" class="form-control form-control-user" 
                                          name="email"
                                          autocomplete="username"
                                          placeholder="ingrese su email..." 
                                          formControlName="email" [class.is-invalid]="!email.valid && email.touched">
                                          <div *ngIf="email.errors?.['required']" class="invalid-feedback">
                                            debe completar este campo
                                          </div>
                                  </div>
                                  <div class="form-group">
                                      <input type="password" name="password" class="form-control form-control-user"
                                          placeholder="ingrese su clave"
                                          autocomplete="on"
                                          formControlName="password" [class.is-invalid]="!password.valid && password.touched">
                                          <div *ngIf="password.errors?.['required']" class="invalid-feedback">
                                            debe completar este campo.
                                          </div>
                                  </div>
                                <div class="form-check form-control-sm">
                                    <input id="remember_me" name="remember_me" class="form-check-input" type="checkbox" formControlName="remember_me">
                                    <label class="form-check-label" for="remember_me">
                                      Recuerdame
                                    </label>
                                  </div>
                                  <div class="form-group">
                                  </div>
                                  <button type="submit" class="btn btn-primary btn-user btn-block">
                                      Iniciar
                                  </button>
       
                              </form>
           
                              <div class="text-center mt-2">
                                  <a class="small" href="" routerLink="/recover">Olvido su clave?</a>
                              </div>
                              <div class="text-center">
                                  <a class="small" routerLink="/register">Registrate!</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

      </div>

  </div>

</div>
</div>