import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { SubscriptorModel } from 'src/domain/models/subscriptor.model';
import { SubscriptorRepository } from 'src/domain/repositories/subscriptor.repository';

@Injectable({
    providedIn: 'root',
})
export class SubscriptorGetByIdUseCase implements UseCase<number, SubscriptorModel | undefined> {

    constructor(private subscriptorRepository: SubscriptorRepository) { }

    execute(
        id: number,
    ): Observable<SubscriptorModel | undefined> {
        return this.subscriptorRepository.getById(id);
    }
}
