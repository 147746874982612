import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BoostrapUseCase } from 'src/domain/usecases/common/boostrap.usecase';
import { ParentModel } from 'src/domain/models/parent.model';
import { ParentGetByStudentUseCase } from 'src/domain/usecases/parent/parent-get-by-student.usecase';
import { ParentDeleteUseCase } from 'src/domain/usecases/parent/parent-delete.usercase';
import { ParentGetAllUseCase } from 'src/domain/usecases/parent/parent-get-all.usecase';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';



@Component({
  selector: 'app-parent-container',
  templateUrl: './parent-container.component.html',
  styleUrls: ['./parent-container.component.css']
})
export class ParentContainerComponent {
  viewAsTable:Boolean = false;
  @Input() studentId:number = 0;
   

  canCreate: Boolean = false;
  notRecords:Boolean = true;
  loading:Boolean = true;
  parents: ParentModel[] = [];
  
  constructor(private parentDeleteUseCase: ParentDeleteUseCase,
              private parentGetByStudentUseCase:ParentGetByStudentUseCase,
              private boostrapUseCase: BoostrapUseCase,
              private parentGetAllUseCase:ParentGetAllUseCase,
              private contextUseCase:ContextUseCase) { 

}
  ngOnDestroy(): void {
    this.boostrapUseCase.destroyBoostrap();
  }
  ngOnInit(): void {
    this.contextUseCase.hideToggledNav();
    this.boostrapUseCase.initBoostrap();
    this.loadRecords(false);

    if(this.studentId != null && this.studentId != 0)
      this.canCreate = true;
  }

  loadRecords(force:Boolean):void{
      this.loading = true;
      this.notRecords = false;

    if(this.studentId == undefined || this.studentId == 0){
      this.parentGetAllUseCase.execute(force).subscribe({ 
        next:(parents)=>{
          this.parents = parents;
          this.loading = false;
          
          if(parents.length == 0)
            this.notRecords = true;
        
        }
      })
    }
    else{
      this.parentGetByStudentUseCase.execute(this.studentId).subscribe({ 
        next:(parents)=>{
          this.loading = false;
          this.parents = parents;
          
          if(parents.length == 0)
            this.notRecords = true;
        }
      })
    }
  }

  deleteHandler(parent: ParentModel):void{
     this.parentDeleteUseCase.execute(parent).subscribe({
       next:()=>{
          this.loadRecords(true);
       },
       complete:()=>{
          this.loading = false;
       }
     });
  }

}
