import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { SchoolModel } from 'src/domain/models/school.model';
import { SchoolRepository } from 'src/domain/repositories/school.repository';
import { NotificationUseCase } from '../common/notification.usecase';

@Injectable({
    providedIn: 'root',
})
export class SchoolDeleteUseCase implements UseCase<SchoolModel, void> {

    constructor(private schoolRepository: SchoolRepository,
                private notificationUseCase:NotificationUseCase) { }

    execute(
        school: SchoolModel,
    ): Observable<void> {
        return new Observable<void>((observable)=>{
            let suscription = this.notificationUseCase.showQuestion(`Eliminar`, `¿Desea eliminar la escuela ${school.name}?`).subscribe((response)=>{
                                    if(response == true){
                                        observable.next();
                                        this.schoolRepository.delete(school).subscribe(()=> observable.complete());
                                    }
                                
                                    suscription.unsubscribe();
                
                                });
        });
    }
}
