import { Component, Input, OnInit } from '@angular/core';
import { ScheduleModel } from 'src/domain/models/schedule.model';
import { NotificationUseCase } from 'src/domain/usecases/common/notification.usecase';
import { ScheduleGetByCourseUseCase } from 'src/domain/usecases/schedule/schedule-get-by-course.usecase';

@Component({
  selector: 'app-schedule-container',
  templateUrl: './schedule-container.component.html',
  styleUrls: ['./schedule-container.component.css']
})
export class ScheduleContainerComponent implements OnInit {

  viewAsTable:Boolean = false;
  @Input() schoolId:BigInt | undefined;
  @Input() courseId!:number;
  @Input() schedules:ScheduleModel[] = [];
  @Input() readOnly:Boolean = false;
  

  notRecords:Boolean = false;
  loading:Boolean = false;
  schedule: ScheduleModel | undefined;

  constructor(private scheduleGetByCourseUseCase:ScheduleGetByCourseUseCase,
              private notificationUseCase:NotificationUseCase) { }

  ngOnInit(): void {
    this.loadSchedules();
  }

  deleteHandler(schedule:ScheduleModel){
    this.notificationUseCase.showQuestion('Eliminar', '¿Desea eliminar el horario?').subscribe((response)=>{
debugger
      if(response == true){
     //    this.calendarService.delete(calendarElement.id);
       //  this.loadRecords(true);
      }
  });
  }

  loadSchedules():void{
    this.loading = true;
 
debugger
   // if(this.schoolId == undefined){
      this.scheduleGetByCourseUseCase.execute(this.courseId).subscribe((schedules)=>{
        this.schedules = schedules;
        debugger
        this.loading = false;
 
      })
  /*  }else{
      this.calendarService.getAll().subscribe({
        next:(schedules)=>{
        this.schedules = schedules;
        this.loading = false;

        if(schedules.length == 0)
          this.notRecords = true;
        },
        error:(error)=>{
          this.loading = false;
        }
      })
      

    }
    */
  }


}
