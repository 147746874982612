import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export abstract class SpinnerService {
  abstract showSpinner():void;
  abstract hideSpinner():void;
  abstract ChangeStateSpinnerEvent:EventEmitter<Boolean>;


}
