import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { icon, latLng, LeafletMouseEvent, marker, Marker, tileLayer } from 'leaflet';
import * as L from 'leaflet';
import { Router } from '@angular/router';
import { AddressModel } from 'src/domain/models/address.model';
import { SchoolModel } from 'src/domain/models/school.model';
import { MapAddressGateway } from 'src/domain/gateways/map-address.gateway';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, AfterViewInit {
  private map: any;
  private markets:any[] = [];
  private market!:any;

  @Input() enabled: Boolean = true;
  @Input() addressPoint:AddressModel | undefined;
  @Input() addressPoints:AddressModel[] = [];  
  @Output() addressPointEvent = new EventEmitter<AddressModel | undefined>();  



    //Documentacion: https://github.com/bluehalo/ngx-leaflet
    //http://tombatossals.github.io/angular-leaflet-directive/examples/0000-viewer.html#/markers/add-remove-example
  
  constructor(private router: Router, private mapAddressGateway:MapAddressGateway,
              private changeDetector: ChangeDetectorRef) {


   }


  ngOnInit(): void {
    
  }

  initMap(): void {

    this.map = L.map('map', {
      center: [-34.92707126021697, -57.95150756835938],
      zoom: 12
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map);

    if(this.enabled)
      this.map.on('click', (e:any) => { this.clickMapHandler(e)});


    this.map.invalidateSize();

  }



  ngAfterViewInit(): void {
    this.initMap();
    this.setPoints();
  }


  builderMarkerOptions(address:AddressModel){
    return {
      title: address.title,
      target: address.target,
      icon: icon({
        iconSize: [25,41],
        iconAnchor: [13,41],
        iconUrl: '/presentation/assets/map/marker-icon.png',
        iconRetinaUrl: '/presentation/assets/map/marker-icon-2x.png',
        shadowUrl: '/presentation/assets/map/marker-shadow.png'
      })};
  }

  setPoints() {
      
      let latlngs: any[] = [];
      this.markets = [];

      if(this.addressPoint != null)
        this.addressPoints.push(this.addressPoint);

      if(this.addressPoints.length == 0)
        return;

      this.addressPoints.forEach(addressPoint => {
        this.market = L.marker([addressPoint.lat, addressPoint.lon], this.builderMarkerOptions(addressPoint))
                          .addTo(this.map)
                          .bindPopup(this.builderPopup(addressPoint)); 
       
                          
        this.markets.push(this.market);
        latlngs.push([addressPoint.lat, addressPoint.lon]);
  
      });  

      let polyline = L.polyline(latlngs, {color: 'none'}).addTo(this.map);
      this.map.fitBounds(polyline.getBounds());
  }


  builderPopup(addressPoint: AddressModel):string{
    let school = addressPoint.target as SchoolModel;

     if(school == null)
       return '';

     let html = '<p><b>' + school.name + '</b></p>'
     html = html + '<p>Direccion: ' + school.full_address + '</p>'
     html = html + '<a href="/school/' + school.id.toString() + '/' + encodeURIComponent(school.name) +'/detail">Ver Escuela</a>'

    return html;

  }

 
 
  clickMapHandler($event:any) {

    this.markets = [];
    this.addressPoints = [];

    if(this.market != null)
      this.map.removeLayer(this.market);

    let title = '';
    this.mapAddressGateway.getAddress($event.latlng.lat, $event.latlng.lng).subscribe({
        next:(response)=>{
            title = this.builderAddressName(response.address);

            this.addressPointEvent.emit(new AddressModel($event.latlng.lat, $event.latlng.lng, title, title, null, 0));

            this.market = L.marker([$event.latlng.lat, $event.latlng.lng], {
              title: title,
              icon: icon({
                iconSize: [25,41],
                iconAnchor: [13,41],
                iconUrl: 'marker-icon.png',
                iconRetinaUrl: 'marker-icon-2x.png',
                shadowUrl: '/presentation/assets/marker-shadow.png'
              })}).addTo(this.map); 
        }
    });

    
  }
  
  builderAddressName(address:any):string{
    let title = '';

    if(address.road)
      title = address.road + ' ';
    
    if(address.house_number)
      title = title + address.house_number + ', ';
      
    if(address.city)
      title = title + address.city + ', ';
    
    if(address.village)
      title =  title + address.village + ', ';

    if(address.state_district)
      title =  title + address.state_district + ', ';

    if(address.state)
      title =  title + address.state + ', ';

    return title;
  }


}

