import { Component, ComponentRef, Input, OnInit, ViewChild, ViewContainerRef,ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { ScheduleItemComponent } from '../schedule-item/schedule-item.component';
import { ScheduleModel } from 'src/domain/models/schedule.model';

@Component({
  selector: 'app-schedule-detail',
  templateUrl: './schedule-detail.component.html',
  styleUrls: ['./schedule-detail.component.css']
})
export class ScheduleDetailComponent implements OnInit, AfterViewChecked {


  scheduleLength:number = 0;
  @ViewChild('items', {read: ViewContainerRef}) items!: ViewContainerRef;

  @Input() schedules:ScheduleModel[] = [];

  constructor(private changeDetector: ChangeDetectorRef){

  }
  
  ngOnInit(): void {
    this.scheduleLength = this.schedules.length;
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    this.scheduleLength = this.schedules.length;
    this.items.clear();
    this.schedules.forEach(schedule => {
      this.addDayComponent(schedule.day, schedule.time_from, schedule.time_to);
    });
  }


  addDayComponent(day:number, timeFrom:string, timeTo:string){

    let comp = this.items.createComponent(ScheduleItemComponent) as ComponentRef<ScheduleItemComponent>;
 
    comp.instance.day = day;
    comp.instance.timeFrom = timeFrom;
    comp.instance.timeTo =  timeTo;
    comp.instance.readOnly = true;
   }
}
