import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { ParentGetByIdUseCase } from 'src/domain/usecases/parent/parent-get-by-id-usecase';
import { ParentDeleteUseCase } from 'src/domain/usecases/parent/parent-delete.usercase';
import { ParentModel } from 'src/domain/models/parent.model';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';

@Component({
  selector: 'app-parent-detail',
  templateUrl: './parent-detail.component.html',
  styleUrls: ['./parent-detail.component.css']
})
export class ParentDetailComponent implements OnInit, OnDestroy {

  parentId!: number;
  parent: ParentModel = ParentModel.empty();
  loading:Boolean = true;


  constructor(private router: Router,
    private activatedRoute:ActivatedRoute,
    private location:Location,
    private parentGetByIdUseCase:ParentGetByIdUseCase,
    private parentDeleteUseCase:ParentDeleteUseCase,
    private contextUseCase:ContextUseCase) { 

    }
  ngOnDestroy(): void {
    
  }

  ngOnInit(): void {
    this.loadParent();
  }

  loadParent() {
    this.parentId = this.activatedRoute.snapshot.params['id'];
    this.contextUseCase.hideToggledNav();
    this.parentGetByIdUseCase.execute(this.parentId).subscribe({
        next:(parent)=>{
           this.parent = parent;
           this.loading = false;
        }
      })
  }


  back(){
    this.location.back();
  }



  deleteHandler(){
    this.parentDeleteUseCase.execute(this.parent).subscribe({
      complete:()=>{
        this.back();
      }
    });
  }

}
