import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReferenceModel } from 'src/domain/models/reference.model';
import { LookupRepository } from 'src/domain/repositories/lookup.repository';

@Injectable({
    providedIn: 'root',
})
export class LookupUseCase{

    constructor(private lookupRepository: LookupRepository) { }


    search(entityName:string, textSearch:string):Observable<ReferenceModel[]>{
        return this.lookupRepository.search(entityName, textSearch);
    }

    
}
