<div class="bg-gradient-primary">

    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-6 d-none d-lg-block bg-register-image"></div>
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-2">Crea una clave nueva</h1>
                                        <p class="mb-4">
                                        </p>
                                    </div>
                                    <form [formGroup]="form" class="user" (submit)="saveHandler()">
                                        <div class="form-group">
                                            <input type="password" class="form-control form-control-user"
                                            aria-describedby="emailHelp"
                                           placeholder="Ingrese su nueva clave..."
                                           formControlName="new_password" [class.is-invalid]="!new_password.valid && new_password.touched">
                                            <div *ngIf="new_password.errors?.['required']" class="invalid-feedback">
                                              debe completar este campo
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="password" class="form-control form-control-user"
                                            aria-describedby="emailHelp"
                                           placeholder="Repita su nueva clave..."
                                           formControlName="confirmation_password" [class.is-invalid]="!confirmation_password.valid && confirmation_password.touched">
                                            <div *ngIf="confirmation_password.errors?.['required']" class="invalid-feedback">
                                              debe completar este campo
                                            </div>
                                            <div *ngIf="confirmation_password.errors?.['matchValidator']" class="invalid-feedback">
                                                la clave y la confirmación no coinciden
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-primary btn-user btn-block">
                                            Guardar Nueva Clave
                                        </button>
                                    </form>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</div>