import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CourseModel } from 'src/domain/models/course.model';


@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.css']
})
export class CourseListComponent implements OnInit {
  @Input() useClassFull: Boolean = false;
  @Input() courses: CourseModel[] = [];
  @Output() deleteEvent = new EventEmitter<CourseModel>();
  @Output() detachEvent = new EventEmitter<CourseModel>();

  constructor() { 
    
  }

  
  ngOnInit(): void {
     
  }

  deleteHandler(course:CourseModel){
    this.deleteEvent.emit(course);
  }

  detachCourseHandler(course:CourseModel){
    this.detachEvent.emit(course);
  }

}
