import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { Injectable } from '@angular/core';
import { UserRepository } from 'src/domain/repositories/user.repository';
import { PasswordChangeModel } from 'src/domain/models/password-change.model';


@Injectable({
    providedIn: 'root',
})
export class UserPasswordChangeUseCase implements UseCase<PasswordChangeModel, void> {

    constructor(private userRepository: UserRepository) { }

    execute(passwordChange: PasswordChangeModel): Observable<void> {
         return this.userRepository.passwordChange(passwordChange);
    }
}
