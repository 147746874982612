import { Observable } from 'rxjs';
import { CourseModel } from '../models/course.model';
import { BaseRepository } from './base.repository';


export abstract class CourseRepository implements BaseRepository<CourseModel> {
    abstract getById(id: number): Observable<CourseModel>;
    abstract getAll(force:Boolean): Observable<CourseModel[]>;
    abstract create(model:CourseModel): Observable<CourseModel>;
    abstract update(model:CourseModel): Observable<CourseModel>;
    abstract getCurrent():CourseModel | undefined;
    abstract delete(model:CourseModel):Observable<void>;
    abstract getCouresBySchool(schoolId:number):Observable<CourseModel[]>;
    abstract getCouresByStudent(studentId:number):Observable<CourseModel[]>;

}
