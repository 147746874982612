import { Directive, ElementRef, Input, OnInit, ViewContainerRef } from '@angular/core';
import { UserGetUserSessionUseCase } from 'src/domain/usecases/user/user-get-session.usecase';

@Directive({
  selector: '[HasPermisionDirective]'
})
export class HasPermisionDirective implements OnInit {

  @Input() RoleRequired!:string

  private userRoles: string[] | undefined;

  constructor(
    private element: ElementRef,
    private viewContainer: ViewContainerRef,
    private userGetUserSessionUseCase:UserGetUserSessionUseCase) { 
     

  }

  ngOnInit() {
      this.loadUserRoles();
  }


  loadUserRoles() {
    this.userGetUserSessionUseCase.execute().subscribe((user)=>{
        this.userRoles = user.roles;
        this.updateView();
    })

  }

  private updateView() {
    if (this.userRoles != null && this.userRoles.includes(this.RoleRequired) == false){ 
      let el : HTMLElement = this.element.nativeElement;
      if(el.parentNode != null)
        el.parentNode.removeChild(el);
    }
    
  }

 

}