import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StudentModel } from 'src/domain/models/student.model';
import { StudentDeleteUseCase } from 'src/domain/usecases/student/student-delete-usecase';
import { StudentGetAllUseCase } from 'src/domain/usecases/student/student-get-all.usercase';
import { StudentGetByCourseUseCase } from 'src/domain/usecases/student/student-get-by-course';
import { StudentGetBySchoolUseCase } from 'src/domain/usecases/student/student-get-by-school';
import { BoostrapUseCase } from 'src/domain/usecases/common/boostrap.usecase';
import { StudentDetachCourseUseCase } from 'src/domain/usecases/student/student-detach-course.usecase';
import { RelationAction } from 'src/domain/models/relationAction.model';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';

@Component({
  selector: 'app-student-container',
  templateUrl: './student-container.component.html',
  styleUrls: ['./student-container.component.css']
})
export class StudentContainerComponent implements OnInit, OnDestroy, AfterViewChecked  {

  viewAsTable:Boolean = false;
  @Input() schoolId:number | undefined;
  @Input() courseId: number | undefined;

  notRecords:Boolean = true;
  loading:Boolean = true;
  students: StudentModel[] = [];
  canCreate: Boolean = false;
  canImport:Boolean = false;
  
  constructor(private studentDeleteUseCase: StudentDeleteUseCase,
              private studentGetAll: StudentGetAllUseCase,
              private studentGetByCourseUseCase:StudentGetByCourseUseCase,
              private studentGetBySchoolUseCase:StudentGetBySchoolUseCase,
              private studentDetachCourseUseCase:StudentDetachCourseUseCase,
              private boostrapUseCase: BoostrapUseCase,
              private changeDetector: ChangeDetectorRef,
              private contextUseCase:ContextUseCase) { 

}
  ngOnDestroy(): void {
    this.boostrapUseCase.destroyBoostrap();
  }
  ngOnInit(): void {
    this.contextUseCase.hideToggledNav();
    this.boostrapUseCase.initBoostrap();
    this.loadRecords(true);
  }

  ngAfterViewChecked(): void {

    if(this.courseId != null && this.schoolId != null){
      this.canCreate = true;

    }
    this.changeDetector.detectChanges();
  }

  detectChanges():void{
    this.changeDetector.detectChanges()
  }

  loadRecords(force:Boolean):void{
    this.loading = true;
    this.notRecords = false;

   if(this.courseId != undefined){
    this.studentGetByCourseUseCase.execute(this.courseId).subscribe((students)=>{
      this.students = students;
      this.loading = false;

      if(students.length == 0)
        this.notRecords = true;

    })
   }else if(this.schoolId != undefined){
    this.studentGetBySchoolUseCase.execute(this.schoolId).subscribe((students)=>{
      this.students = students;
      this.loading = false;

      if(students.length == 0)
        this.notRecords = true;
    })
   }else{
    this.studentGetAll.execute(force).subscribe((students)=>{
      this.students = students;
      this.loading = false;

      if(students.length == 0)
        this.notRecords = true;
    })
  }
  }

  deleteHandler(student: StudentModel):void{
     this.studentDeleteUseCase.execute(student).subscribe(()=>{
       this.loadRecords(true);
     });
  }


  detachCourseHandler(student: StudentModel):void{
    if(this.courseId == null)
      return;

    this.loading = true;
    this.studentDetachCourseUseCase.execute(new RelationAction(student, this.courseId)).subscribe(()=>{
      this.loadRecords(true);
      this.loading = false;
    });
 }

}
