import { Observable } from 'rxjs';
import { BaseRepository } from './base.repository';
import { SubscriptionModel } from '../models/subscription.model';


export abstract class SubscriptionRepository implements BaseRepository<SubscriptionModel> {
    abstract getById(id: number): Observable<any>;
    abstract getAll(force: Boolean): Observable<SubscriptionModel[]>;
    abstract create(model: SubscriptionModel): Observable<SubscriptionModel>;
    abstract update(model: SubscriptionModel): Observable<SubscriptionModel>;
    abstract getCurrent():SubscriptionModel | undefined;
    abstract delete(model: SubscriptionModel): Observable<void>;
    abstract getUserSubscription(): Observable<SubscriptionModel | undefined>;
}
