import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { StudentModel } from 'src/domain/models/student.model';
import { StudentRepository } from 'src/domain/repositories/student.repository';
import { NotificationUseCase } from '../common/notification.usecase';

@Injectable({
    providedIn: 'root',
})
export class StudentCreateUseCase implements UseCase<StudentModel, StudentModel> {

    constructor(private studentRepository: StudentRepository) {

    }

    execute(
        student: StudentModel,
    ): Observable<StudentModel> {
        return new Observable<StudentModel>((observable)=>{
           this.studentRepository.create(student).subscribe({
             next:(student)=>{
                observable.next(student);
             },
             error:(error)=>{
                observable.error(error);
             },
             complete:()=>{
                observable.unsubscribe();
             }
           })

        });
         
    }
}
