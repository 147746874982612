import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchGlobalResultModel } from '../models/search-result.model';



@Injectable({
  providedIn: 'root'
})
export abstract class GlobalSearchService {
  abstract searchGlobalResult:SearchGlobalResultModel;
  abstract changedResults:EventEmitter<SearchGlobalResultModel>;
  abstract updateResults:EventEmitter<void>;
  abstract search(textSearch:string):Observable<SearchGlobalResultModel>;
}
