import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { UserAuthenticationUseCase } from 'src/domain/usecases/user/user-authentication.usecase';
import { Location} from '@angular/common'
import { UserGetUserSessionUseCase } from 'src/domain/usecases/user/user-get-session.usecase';
import { NotificationUseCase } from 'src/domain/usecases/common/notification.usecase';

@Injectable({
  providedIn: 'root'
})

export class CanActivateAdminGuard implements CanActivate {

  constructor(private router: Router, 
    private userAuthenticationUseCase:UserAuthenticationUseCase,
    private userGetUserSessionUseCase:UserGetUserSessionUseCase,
    private location:Location,
    private notificationUseCase: NotificationUseCase){

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
       return this.userAuthenticationUseCase.execute().pipe(map(session => {
            if(session == undefined){
              this.router.navigate(['/login']);
              return false;
            }

            if(session?.roles.includes('ADMIN'))
                 return true;

            this.notificationUseCase.showError('No Autorizado', 'No tiene permiso para este sector');
            this.router.navigate(['/login']);

            return false;
       }));
    }
  
}
