import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TypeAlert } from 'src/domain/models/alert-message.model';
import { NotificationService } from 'src/domain/services/notification.service';

declare var window: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, OnDestroy   {

  @Input() showButtonCancel:Boolean = false;
  @Input() typeAlert: TypeAlert | undefined;
  formModal: any;
  title: string = '';
  message: string = '';
  index:number = 0;

  @Output() ClickOkEvent:EventEmitter<void> = new EventEmitter<void>();
  @Output() ClickCancelEvent:EventEmitter<void> = new EventEmitter<void>();

  constructor(private notificationService:NotificationService) {

   }


  ngOnInit(): void {
    setTimeout(()=>{
      let element =  document.getElementsByClassName('modalalert')[0];
      this.formModal = new window.bootstrap.Modal(element);
      this.formModal.show();
    }, 400);
  }

  ngOnDestroy() {
  }
 
   

    cancelHandler():void{
      this.formModal.hide();
      this.ClickCancelEvent.emit();
    }
    okHandler():void{
      this.formModal.hide();
      this.ClickOkEvent.emit();
    }
}
