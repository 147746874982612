import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { CalendarElementModel } from 'src/domain/models/calendar-element.model';
import { CalendarElementRepository } from 'src/domain/repositories/calendar-element.repository';


@Injectable({
    providedIn: 'root',
})
export class CalendarElementGetAllUseCase implements UseCase<Boolean, CalendarElementModel[]> {

    constructor(private calendarElementRepository: CalendarElementRepository) { }

    execute(
        force: Boolean,
    ): Observable<CalendarElementModel[]> {
        return this.calendarElementRepository.getAll(force);
    }
}
