import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner-mini',
  templateUrl: './spinner-mini.component.html',
  styleUrls: ['./spinner-mini.component.css']
})
export class SpinnerMiniComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
