import { EventEmitter, Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export abstract class NavService {
   
    abstract ToggledNavEvent:EventEmitter<void>;
    abstract HideToggledNavEvent:EventEmitter<void>;
    abstract ShowToggledNavEvent:EventEmitter<void>;
}