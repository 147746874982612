import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-nav',
  templateUrl: './card-nav.component.html',
  styleUrls: ['./card-nav.component.css']
})
export class CardNavComponent implements OnInit {

  @Input() title:string = '';
  @Input() id: BigInt | undefined;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  deleteHandler():void{
     this.onDelete.emit();
  }
  
}
