import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserSessionModel } from "src/domain/models/user-session-model";
import { LocalStorageService } from "src/domain/services/local-storage.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private localStorageService:LocalStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let session =  this.localStorageService.get<UserSessionModel>('SESSION')

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ` + session?.token
      }
    });

    return next.handle(request);
  }
}