import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { UserModel } from '../../models/user.model';
import { UserRepository } from '../../repositories/user.repository';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UserRegisterUseCase implements UseCase<UserModel, UserModel> {

    constructor(private userRepository: UserRepository) { }

    execute(
        user:UserModel,
    ): Observable<UserModel> {
        return this.userRepository.register(user);
    }
}
