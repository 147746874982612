import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { SubscriptionGetCurrentUseCase } from 'src/domain/usecases/subscription/subscription-get-current-usecase';
import { SubscriptionModel } from 'src/domain/models/subscription.model';


@Component({
  selector: 'subscription-detail',
  templateUrl: './subscription-detail.component.html',
  styleUrls: ['./subscription-detail.component.css']
})
export class SubscriptionDetailComponent implements OnInit{
 
    subscription:  SubscriptionModel |undefined;
    form: FormGroup;
    description: FormControl = new FormControl('');
    date_from: FormControl = new FormControl('');
    date_to: FormControl = new FormControl('');
    price: FormControl = new FormControl('');
    state: FormControl = new FormControl('');
   
  
    constructor(private formBuilder: FormBuilder,
                private contextUseCase: ContextUseCase,
                private location:Location,
                private subscriptionGetCurrentUseCase:SubscriptionGetCurrentUseCase) { 
  
                  this.form = formBuilder.group({
                    description: this.description,
                    date_from:  this.date_from,
                    date_to: this.date_to, 
                    price: this.price,
                    state:  this.state
                  });

                  this.form.controls['description'].disable();
                  this.form.controls['date_from'].disable();
                  this.form.controls['date_to'].disable();
                  this.form.controls['price'].disable();
                  this.form.controls['state'].disable();
                }
  
    ngOnInit(): void {
      this.contextUseCase.hideToggledNav();
      this.contextUseCase.showSpinner();
   
      this.loadSubscription();
    }
  
    loadSubscription() {
      this.subscriptionGetCurrentUseCase.execute().subscribe({
        next:(subscription)=>{
         this.subscription = subscription;

         this.form.controls['description'].setValue(this.subscription?.description); 
         this.form.controls['date_from'].setValue(this.subscription?.date_from); 
         this.form.controls['date_to'].setValue(this.subscription?.date_to); 
         this.form.controls['price'].setValue(this.subscription?.price); 
         this.form.controls['state'].setValue(this.subscription?.state); 

       
         this.contextUseCase.hideSpinner();
        }
      });
    }
  

  
  
    cancel(){
      this.location.back();
    }
 
   
   }
   