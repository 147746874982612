import { Component } from '@angular/core';
import { SubscriptorModel } from 'src/domain/models/subscriptor.model';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { SubscriptorGetAllUseCase } from 'src/domain/usecases/subscriptor/subscriptor-get-all.usecase';


@Component({
  selector: 'app-subscriber-list',
  templateUrl: './subscriber-list.component.html',
  styleUrls: ['./subscriber-list.component.css']
})
export class SubscriberListComponent {
  subscribers: SubscriptorModel[] = [];

     

     constructor(private subscriptorGetAllUseCase: SubscriptorGetAllUseCase,
                 private contextUseCase:ContextUseCase) { 

       
      }

      ngOnInit(): void {
        this.contextUseCase.hideToggledNav();
        this.contextUseCase.showSpinner();
        this.subscriptorGetAllUseCase.execute(true).subscribe((subscribers)=>{
            this.subscribers = subscribers;
            this.contextUseCase.hideSpinner();
        });

      }

      getUserState(subscriptor:SubscriptorModel):string{
           switch(subscriptor.state){
            case 0: 
             return 'Pendiente Confirmacion Email'
            case 1: 
             return 'Pendiente Autorizacion'
            case 2: 
             return 'Activo'
            case 3: 
             return 'Bloqueado'
            default: 
             return 'No Definido'
           }
      }


}
