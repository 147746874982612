import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventApi, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin  from '@fullcalendar/interaction';
import esLocate from '@fullcalendar/core/locales/es';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { CalendarElementGetAllUseCase } from 'src/domain/usecases/calendar-element/calendar-element-get-all.usecase';
import { NavService } from 'src/domain/services/nav.service';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  events: any[] = [];
  currentEvents: EventApi[] = [];

  constructor(private changeDetector: ChangeDetectorRef,
              private contextUseCase: ContextUseCase,
              private calendarElementGetAllUseCase:CalendarElementGetAllUseCase,
              private navService:NavService) {
  }


  ngOnInit(): void {
    this.navService.HideToggledNavEvent.emit();

    this.contextUseCase.showSpinner();
    this.calendarElementGetAllUseCase.execute(true).subscribe({
      next:(calendarElements) =>{
        this.events = calendarElements;
        this.contextUseCase.hideSpinner();
      },
      error:(error)=>{

      }
    })

    //document.querySelector('style').textContent += "@media screen and (max-width:767px) { .fc-toolbar.fc-header-toolbar {flex-direction:column;} .fc-toolbar-chunk { display: table-row; text-align:center; padding:5px 0; } }";
  }

  calendarOptions: CalendarOptions = {
    headerToolbar: {   
      left: "prev,next",
      center: "title",
      right: "dayGridMonth,timeGridWeek,timeGridDay"
    },
    height: '100vh',
    eventTimeFormat: { hour: '2-digit', minute: '2-digit', hour12: false },
    slotLabelFormat: { hour: 'numeric', minute: '2-digit', omitZeroMinute: false, hour12: false },
    droppable: false,
    eventClick: this.handleDateClick.bind(this),
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    locale: esLocate,
    themeSystem: 'bootstrap5',
  };


  handleDateClick(arg:any) {
    debugger
    //alert(arg.event.title);
    //alert(arg.event.url);
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const title = prompt('Please enter a new title for your event');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: Math.floor(Math.random() * 100).toString(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
    this.changeDetector.detectChanges();
  }




}



