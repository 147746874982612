import { Component } from '@angular/core';

@Component({
  selector: '[app-root]',
  templateUrl: '../app/app.component.html',
  styleUrls: ['../app/app.component.css']
})
export class AppComponent {
  title = 'Teacher Schedule';

  
  constructor(){

  }
}
