import { TEntity } from "./base.model";
import { ReferenceModel } from "./reference.model";
import { ScheduleModel } from "./schedule.model";
import { SchoolModel } from "./school.model";

export class CourseModel implements TEntity {
    
    
    
    static empty(): CourseModel {
       return  new CourseModel( 0,'', '', '', 0, new Date(), new Date(), '', '');
    }

    constructor(id: number,
                name: string,
                year: string,
                section:string,
                school_id:number,
                date_from: Date,
                date_to: Date,
                description:string,
                background_color:string){
                    this.id = id;
                    this.name = name;
                    this.year = year;
                    this.section = section;
                    this.school_id = school_id;
                    this.date_from = date_from;
                    this.date_to = date_to;
                    this.description = description;
                    this.school_reference = ReferenceModel.empty();
                    this.background_color = background_color;
                }

    id: number;
    name: string;
    year: string;
    section:string;
    school_id:number;
    date_from: Date;
    date_to: Date;
    description:string;
    schools!: SchoolModel[];
    schedules!: ScheduleModel[];
    school_reference!: ReferenceModel;
    background_color!: string;
 }