import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/domain/services/notification.service';

declare var bootstrap: any;

@Injectable({
    providedIn: 'root',
})
export class NotificationUseCase {


    public CallBackOkEvent = new EventEmitter<void>();

    constructor(private notificationService:NotificationService) {
      this.CallBackOkEvent = notificationService.CallBackOkEvent;
    }
     
    public showError(title:string, message:string):void{
      this.notificationService.showError(title, message);
    }

    public showInfo(title:string, message:string):void{
      this.notificationService.showInfo(title, message);
    }

    public showQuestion(title:string, message:string):Observable<Boolean>{
      return this.notificationService.showQuestion(title, message);
    }


}
