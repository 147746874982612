import { EventEmitter, Injectable } from '@angular/core';
import { NavService } from 'src/domain/services/nav.service';


@Injectable({
  providedIn: 'root'
})
export class NavImplementationService implements NavService {
  public HideToggledNavEvent = new EventEmitter<void>;
  public ShowToggledNavEvent = new EventEmitter<void>;
  public ToggledNavEvent = new EventEmitter<void>();
  }