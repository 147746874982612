<div class="col">
    <div class="text-center">
        <h1 class="h4 text-gray-900 mb-4">Editar Padre / Tutor</h1>
    </div>
    <form [formGroup]="form" (submit)="saveHandler()" class="user">
      <div class="form-floating mb-2">
        <input  id="full_name" autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Nombre"
        formControlName="full_name" [class.is-invalid]="!full_name.valid && full_name.touched">
        <div *ngIf="full_name.errors?.['required']" class="invalid-feedback">
          debe completar este campo
        </div>
        <label for="full_name" class="form-floating-required">Nombre</label>
      </div>
      <div class="form-floating mb-2">
        <input id="dni" autocomplete="off" type="text" class="form-control"
            placeholder="DNI" formControlName="dni" [class.is-invalid]="!dni.valid && dni.touched">
            <label for="dni">DNI</label>
          </div>
        <div class="form-floating mb-2">
          <input id="telephone_number" autocomplete="off" type="text" class="form-control"
              placeholder="Telefono" formControlName="telephone_number" [class.is-invalid]="!telephone_number.valid && telephone_number.touched">
              <label for="telephone_number">Telefono</label>
            </div>
            <div class="form-floating mb-2">
              <input id="email" autocomplete="off" type="email" class="form-control form-control-sm"
                  placeholder="Email" formControlName="email" [class.is-invalid]="!email.valid && email.touched">
                  <div *ngIf="email.errors?.['required']" class="invalid-feedback">
                    debe completar este campo
                  </div>
                  <div *ngIf="email.errors?.['email']" class="invalid-feedback">
                    el correo electronico es invalido
                  </div>
                  <label for="email">Email</label>
      </div>
      <div class="form-floating mb-2">
        <input id="relation" autocomplete="off" type="text" class="form-control form-control-sm"
            placeholder="Vinculo" formControlName="relation" [class.is-invalid]="!relation.valid && relation.touched">
            <label for="relation">Vinculo</label>
</div>
              <div class="form-floating mb-2">
                <textarea id="description" autocomplete="off" class="form-control" style="height: 100px"
                    placeholder="Descripcion" formControlName="description" [class.is-invalid]="!description.valid && description.touched"></textarea>
                    <label for="description">Descripcion</label>
                  </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
             <button type="submit" class="col btn btn-primary">
            Guardar
        </button>
        <button type="button" (click)="cancel()" class="col btn btn-secondary">
          Cancelar
      </button>
      </div>
        <hr>
    </form>
</div>

