import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalendarElementModel } from 'src/domain/models/calendar-element.model';
import { CalendarElementRepository } from 'src/domain/repositories/calendar-element.repository';
import { BaseImplementationRepository } from './base-implementation.repository';
import { Observable } from 'rxjs';
import { CourseModel } from 'src/domain/models/course.model';

@Injectable({
    providedIn: 'root',
})
export class CalendarElementImplementationRepository extends BaseImplementationRepository<CalendarElementModel> implements CalendarElementRepository {
         
    constructor(http: HttpClient) {
         super(http);
    }

    override http!:HttpClient;

    getEntityName(): string {
        return 'calendar';
    }

    updateCourseCalendar(course: CourseModel):Observable<void> {
        return this.http.put<void>('/api/calendar', course);
    }

}