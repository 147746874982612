import { TEntity } from "./base.model";
import { ReferenceModel } from "./reference.model";

export class ParentModel implements TEntity {
    id: number;
    full_name: string;
    email: string;
    telephone_number: string;
    description:string;
    dni:string;
    relation:string;
    student_id:number;
    student_reference!: ReferenceModel;

    constructor(id: number,
        full_name: string,
        email: string,
        telephone_number: string,
        description:string,
        dni:string,
        student_id:number,
        relation:string){
            this.id = id;
            this.full_name = full_name;
            this.email = email;
            this.telephone_number = telephone_number;
            this.description = description;
            this.dni = dni;
            this.relation = relation;
            this.student_id = student_id
        }


        static empty(): ParentModel {
            let parent =  new ParentModel(0,'', '', '', '', '', 0, '');

            parent.student_reference = new ReferenceModel(0,'');
            return parent;
         }

 }
