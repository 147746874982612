import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';

export class UserLogoutUseCase implements UseCase<void, void> {

    constructor() {

     }

    execute(

    ): Observable<void> {
       return new Observable((observable)=>{
         localStorage.removeItem('remember_me');
         localStorage.removeItem('SESSION');
          observable.next();
       });
    }
}
