import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReferenceModel } from 'src/domain/models/reference.model';
import { SchoolModel } from 'src/domain/models/school.model';


@Component({
  selector: 'app-school-list',
  templateUrl: './school-list.component.html',
  styleUrls: ['./school-list.component.css']
})
export class SchoolListComponent implements OnInit {

 @Input() useClassFull: Boolean = false;
 @Input() schools: SchoolModel[] = [];
 @Output() deleteEvent = new EventEmitter<SchoolModel>();
 
  constructor() { 

  }


  ngOnInit(): void {

  }

  trackByItems(index:number, school:any){
    return school.id;
  }



  deleteHandler(school:SchoolModel){
    this.deleteEvent.emit(school);
  }

}
