import { ComponentRef, EventEmitter, Injectable, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalModel } from 'src/domain/models/modal.model';
import { ModalService } from 'src/domain/services/modal.service';
import { MapComponent } from '../app/map/map.component';

@Injectable({
  providedIn: 'root'
})
export class ModalImplementationService implements ModalService {

  @ViewChild('modals', {read: ViewContainerRef}) modals!: ViewContainerRef;
  
  constructor() { }

  openMap<MapComponent>():ComponentRef<MapComponent>{
    return this.modals.createComponent(MapComponent, {index: this.modals.length}) as ComponentRef<MapComponent>;
  }

  
  public NewModalEvent = new EventEmitter<any>();

}