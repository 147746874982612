import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SearchGlobalResultModel } from "src/domain/models/search-result.model";
import { GlobalSearchRepository } from "src/domain/repositories/global-search.repository";

@Injectable({
    providedIn: 'root',
})
export class GlobalSearchImplementationRepository extends GlobalSearchRepository {

    constructor(private http:HttpClient)
    {
        super();
    }

    search(textSearch:string):Observable<SearchGlobalResultModel>{
        return this.http.get<SearchGlobalResultModel>('/api/search/' + textSearch);
    }

}