import { Component } from '@angular/core';

@Component({
  selector: 'app-subscriber-edit',
  templateUrl: './subscriber-edit.component.html',
  styleUrls: ['./subscriber-edit.component.css']
})
export class SubscriberEditComponent {

}
