<div class="bg-gradient-primary">

    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">

            <div class="col-xl-10 col-lg-12 col-md-9">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-6 d-none d-lg-block bg-register-image"></div>
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-2">Olvidaste tu clave?</h1>
                                        <p class="mb-4">Lo entendemos, suceden cosas. Sólo tienes que introducir tu dirección de correo electrónico a continuación
                                        ¡Y te enviaremos un enlace para restablecer tu contraseña!
                                        </p>
                                    </div>
                                    <form [formGroup]="form" class="user" (submit)="sendHandler()">
                                        <div class="form-group">
                                            <input type="text" class="form-control form-control-user" formControlName="email"
                                                placeholder="Email" [class.is-invalid]="!email.valid && email.touched">
                                                <div *ngIf="email.errors?.['required']" class="invalid-feedback">
                                                  debe completar este campo
                                                </div>
                                    </div>
                                        <button type="submit" class="btn btn-primary btn-user btn-block">
                                            Enviar
                                        </button>
                                    </form>
                                    <hr>
                                    <div class="text-center">
                                        <a class="small" href="" routerLink="/register">Registrate</a>
                                    </div>
                                    <div class="text-center">
                                        <a class="small" href=""routerLink="/login">Ya tenes una cuenta? Ingresa!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</div>
