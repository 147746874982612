import { TEntity } from "./base.model";

export class SchoolModel implements TEntity {
    
    
    static empty(): SchoolModel {
      return new SchoolModel(0, '', '', 0, 0,'', '', 1, '');
    }

    constructor(id: number,
        name: string,
        full_address: string,
        latitude: number,
        longitude:number,
        email: string,
        telephone_number: string,
        students_count: number,
        description: string){
            this.id = id; 
            this.name = name;
            this.full_address = full_address;
            this.latitude = latitude;
            this.longitude = longitude;
            this.email = email;
            this.telephone_number = telephone_number;
            this.students_count = students_count;
            this.description = description;
    }


    id!: number;
    name!: string;
    full_address!: string;
    latitude!: number;
    longitude!:number;
    email!: string;
    telephone_number!: string;
    students_count!: number;
    description!: string;
 }