import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LocalStorageService } from "src/domain/services/local-storage.service";

@Injectable({
    providedIn: 'root'
  })
  export class LocalStorageImplementationService implements LocalStorageService {
    save(key: string, data:any):void{
            localStorage.setItem(key, JSON.stringify(data));
    }

    get<TypeResult>(key:string):TypeResult | undefined{
        
        let data = localStorage.getItem(key);

        if(data == undefined || data == "undefined")
            return undefined;
  
        return JSON.parse(data) as TypeResult
 
    }

    clear(key:string):void{
        
       localStorage.removeItem(key);
 
 
    }
  }