import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { ToastTypes } from 'src/domain/models/toast-types';
import { ContextUseCase } from '../common/context.usecase';
import { NotificationUseCase } from '../common/notification.usecase';
import { GlobalSearchUseCase } from '../common/global-search.usecase';
import { ParentModel } from 'src/domain/models/parent.model';
import { ParentRepository } from 'src/domain/repositories/parent.repository';


@Injectable({
    providedIn: 'root',
})
export class ParentDeleteUseCase implements UseCase<ParentModel, void> {

   constructor(private parentRepository:ParentRepository,
        private notificationUseCase: NotificationUseCase,
        private contextUseCase:ContextUseCase,
        private globalSearchUseCase:GlobalSearchUseCase) { }

    execute(
        parentTutor: ParentModel,
    ): Observable<void> {
         return new Observable<void>((observable)=>{

        let suscription = this.notificationUseCase.showQuestion('Eliminar', '¿Desea eliminar el padre o tutor ' + parentTutor.full_name +'?').subscribe((response)=>{
  
            if(response == true){
                this.parentRepository.delete(parentTutor).subscribe({
                   next: ()=> {
                        this.contextUseCase.showSpinner();
                        this.globalSearchUseCase.updateResults.emit();
                        this.contextUseCase.addToast('Eliminado', 'padre o tutor eliminado con exito', ToastTypes.Success);
                        observable.next();
                    },
                   error: ()=>{
                        this.contextUseCase.hideSpinner();
                        observable.error()
                   },
                   complete:()=>{
                    this.contextUseCase.hideSpinner();
                     observable.complete();
                     observable.unsubscribe();
                     suscription.unsubscribe();
                   } 
                });
            }
        });

    })
    }
}
