import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { AddressModel } from 'src/domain/models/address.model';
import { ModalService } from 'src/domain/services/modal.service';

@Component({
  selector: 'app-autocomplete-address',
  templateUrl: './autocomplete-address.component.html',
  styleUrls: ['./autocomplete-address.component.css']
})
export class AutocompleteAddressComponent implements OnInit, OnDestroy {

  @ViewChild('addresstext') addresstext: any;


  @Output() addressSelect = new EventEmitter<AddressModel | undefined>();  
  @Input() label:string = '';
  @Output() changedStateEvent = new EventEmitter<Boolean>();
  @Input() validateEvent = new EventEmitter<void>();
  @Input() addressSet = new EventEmitter<AddressModel>();  
  @Input() provinceSet = new EventEmitter<string>();  
  currentAddress: AddressModel | undefined;
  suscribeLoading!: Subscription;
  isValid:Boolean = false;
  isChanged:Boolean = false;
  showMap:Boolean = false;
  addressText:string = '';

 private modalService = inject(ModalService);

  ngOnDestroy(): void {
  this.suscribeLoading?.unsubscribe();
}

  ngOnInit(): void {
    this.validateEvent.subscribe(()=>{
      this.changedState();
     });  
     
    this.addressSet.subscribe((value)=>{
       this.currentAddress = value;

       if(value != undefined){
          this.isValid = true;
          this.addressText = value.display_name;
       }
    });
}

changedState(){
  this.isValid = true;

  if(this.currentAddress == undefined){
    this.isValid = false;
    this.isChanged = true;
  }

  this.changedStateEvent.emit(this.isValid);
}

blurHandler(){
  setTimeout(()=>{
      if(this.isChanged && this.currentAddress == undefined)
         this.addressSelect.emit(undefined);
  }, 500);
}

openMapHandler(){
    this.showMap = true;
}

onCancelMapHandler(){
  this.showMap = false;
}

onSelectHandler(value:AddressModel | undefined){
  this.showMap = false;
  this.isChanged = true;
  this.isValid = true;
  this.currentAddress = value;
  
  if(value != null)
    this.addressText = value.display_name;
  
  this.addressSelect.emit(value);
}

ngAfterViewInit() {
  this.getPlaceAutocomplete();
}


private getPlaceAutocomplete() {
  const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
          componentRestrictions: { country: 'AR' },
          types: ['address']  // 'establishment' / 'address' / 'geocode'
      });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();

        let name = place.formatted_address
        let lat = place.geometry?.location?.lat();
        let lng = place.geometry?.location?.lng();

        if(lat != undefined && lng != undefined && name != undefined){
            this.isValid = true;
            this.currentAddress = new AddressModel(lat, lng, name, name, undefined, 0);
            this.addressSelect.emit(this.currentAddress);
        }
  });
}

 
}