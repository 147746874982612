import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseImplementationRepository } from './base-implementation.repository';
import { SubscriptionModel } from 'src/domain/models/subscription.model';
import { SubscriptionRepository } from 'src/domain/repositories/subscription.repository';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionImplementationRepository extends BaseImplementationRepository<SubscriptionModel> implements SubscriptionRepository {
         
    constructor(http: HttpClient) {
         super(http);
    }
    
    getUserSubscription(): Observable<SubscriptionModel> {
        return this.http.get<SubscriptionModel>('/api/subscription');
    }

    override http!:HttpClient;

    override getEntityName(): string {
        return 'subscription';
    }

    

}