    <div class="row">
      <div class="col-sm-12	col-md-12	col-lg-12 col-xl-3 col-xxl-3">
        <h4 class="text-center">INSTITUCIONES</h4>
        <h6 *ngIf="schools.length == 0" class="mt-4 mb-5 text-center">no hay instituciones</h6>
        <app-school-list [useClassFull]="true"  [schools]="schools" (deleteEvent)="deleteSchoolHandler($event)"></app-school-list>
      </div>
      <div class="col-sm-12	col-md-12	col-lg-12 col-xl-3 col-xxl-3">
        <h4 class="text-center">CURSOS</h4>
        <h6 *ngIf="courses.length == 0" class="mt-4 mb-5 text-center">no hay cursos</h6>
        <app-course-list [useClassFull]="true" [courses]="courses" (deleteEvent)="deleteCourseHandler($event)"></app-course-list>
      </div>
      <div class="col-sm-12	col-md-12	col-lg-12 col-xl-3 col-xxl-3">
        <h4 class="text-center">ESTUDIANTES</h4>
        <h6 *ngIf="students.length == 0" class="mt-4 mb-5 text-center">no hay estudiantes</h6>
       <app-student-list [useClassFull]="true" [students]="students" (deleteEvent)="deleteStudentHandler($event)"></app-student-list>
      </div>
      <div class="col-sm-12	col-md-12	col-lg-12 col-xl-3 col-xxl-3">
        <h4 class="text-center">PADRES / TUTORES</h4>
        <h6 *ngIf="parents.length == 0" class="mt-4 mb-5 text-center">no hay padres / tutores</h6>
       <app-parent-list [useClassFull]="true" [parents]="parents" (deleteEvent)="deleteParentHandler($event)"></app-parent-list>
      </div>
    </div>

