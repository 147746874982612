import { Observable } from 'rxjs';
import { BaseRepository } from './base.repository'
import { StudentModel } from '../models/student.model';


export abstract class StudentRepository implements BaseRepository<StudentModel>{
    abstract getById(id: number): Observable<StudentModel | undefined>;
    abstract getAll(force:Boolean): Observable<StudentModel[]>;
    abstract create(model:StudentModel): Observable<StudentModel>;
    abstract update(model:StudentModel): Observable<StudentModel>;
    abstract getCurrent():StudentModel | undefined;
    abstract delete(model:StudentModel): Observable<void>;
    abstract getStudentByCourse(courseId:number): Observable<StudentModel[]>;
    abstract getStudentBySchool(courseId:number): Observable<StudentModel[]>;
    abstract import(formData:FormData):Observable<StudentModel[]>
    abstract detachStudentCourse(studentId: number, courseId: number):Observable<void>;

}