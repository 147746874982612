import { Observable } from 'rxjs';
import { BaseRepository } from './base.repository'
import { ProvinceModel } from '../models/province.model';


export abstract class ProvinceRepository implements BaseRepository<ProvinceModel>{
    abstract getById(id: number): Observable<ProvinceModel>;
    abstract getAll(force:Boolean): Observable<ProvinceModel[]>;
    abstract create(model:ProvinceModel): Observable<ProvinceModel>;
    abstract update(model:ProvinceModel): Observable<ProvinceModel>;
    abstract getCurrent():ProvinceModel | undefined;
    abstract delete(model:ProvinceModel): Observable<void>;

}