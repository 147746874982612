import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-schedule-item',
  templateUrl: './schedule-item.component.html',
  styleUrls: ['./schedule-item.component.css']
})
export class ScheduleItemComponent implements OnInit {

  @Output() removeEvent = new EventEmitter<number>();
  @Output() changedStateEvent = new EventEmitter();
  @Input() validateEvent = new EventEmitter<Boolean>();
  @Input() index!:number;
  @Input() timeFrom!:string;
  @Input() timeTo!:string;
  @Input() day!:number;
  @Input() id!:number;
  @Input() readOnly:Boolean = false;

  dayValid:Boolean = true;
  timeFromValid:Boolean = true;
  timeToValid:Boolean = true;
  
  ngOnInit(): void {
     this.validateEvent.subscribe(()=>{
      this.changedHanler();

      return this.dayValid && this.timeFromValid && this.timeToValid;
     });
  }

  changedHanler(){

    this.dayValid = true;
    this.timeFromValid= true;
    this.timeToValid= true;

    if(this.day == undefined || this.day == 0)
       this.dayValid = false;

    if(this.timeFrom == undefined || this.timeFrom == '')
      this.timeFromValid = false;

   if(this.timeTo == undefined || this.timeTo == '')
    this.timeToValid = false;
   

   this.changedStateEvent.emit(this.dayValid && this.timeFromValid && this.timeToValid);
  }
  
  removeHandler(){
    this.removeEvent.emit(this.index);
  }
}
