import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StudentModel } from 'src/domain/models/student.model';
import { StudentGetByIdUseCase } from 'src/domain/usecases/student/student-get-by-id-usecase';
import { Location } from '@angular/common'
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { StudentDeleteUseCase } from 'src/domain/usecases/student/student-delete-usecase';


const enum ViewType {
  Parents = 0,
  Notes = 1,
  Schools = 2,
  Courses = 3
}


@Component({
  selector: 'app-student-detail',
  templateUrl: './student-detail.component.html',
  styleUrls: ['./student-detail.component.css']
})
export class StudentDetailComponent implements OnInit {

  id!: number;
  student: StudentModel | undefined;
  viewTap:ViewType = ViewType.Parents;
  viewTapParents:ViewType = ViewType.Parents;
  viewTapNotes:ViewType = ViewType.Notes;
  viewTapCourses:ViewType = ViewType.Courses;
  viewTapSchools:ViewType = ViewType.Schools;

  constructor(
    private activatedRoute:ActivatedRoute,
    private studentGetByIdUseCase: StudentGetByIdUseCase,
    private studentDeleteUseCase: StudentDeleteUseCase,
    private location: Location,
    private contextUseCase: ContextUseCase) { 

    }

    
  ngOnInit(): void {
    this.contextUseCase.hideToggledNav();
    this.contextUseCase.showSpinner();
    this.id = this.activatedRoute.snapshot.params['id'];
    
     this.studentGetByIdUseCase.execute(this.id).subscribe({
        next:(student)=>{
           this.student = student;
           this.viewTap = ViewType.Parents;
        },
        complete:()=> this.contextUseCase.hideSpinner()
      })
  }

  deleteHandler(){
    if(this.student == undefined)
      return;

    this.studentDeleteUseCase.execute(this.student).subscribe({
      complete:()=>{
        this.back();
      }
    });
  }

  back(){
    this.location.back();
  }

}