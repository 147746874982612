import { Component } from '@angular/core';

@Component({
  selector: 'app-container-toasts',
  templateUrl: './toasts-container.component.html',
  styleUrls: ['./toasts-container.component.css']
})
export class ToastsContainerComponent {

}
