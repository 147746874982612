import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { UserModel } from '../../models/user.model';
import { UserRepository } from '../../repositories/user.repository';
import { Injectable } from '@angular/core';
import { NotificationUseCase } from '../common/notification.usecase';

@Injectable({
    providedIn: 'root',
})
export class UserUpdateUseCase implements UseCase<UserModel, UserModel> {

    constructor(private userRepository: UserRepository,
                private notificationUseCase:NotificationUseCase) { }

    execute(
       user: UserModel,
    ): Observable<UserModel> {
       let suscription =  this.userRepository.updateUserProfile(user);

       suscription.subscribe({
        error:(error) =>{
            this.notificationUseCase.showError('Error', error.error.message);
        }
       })

       return suscription;
    }
}
