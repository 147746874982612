<div tabindex="-1" role="dialog" aria-labelledby="ModalLabel" class="modal fade modalalert show" aria-modal="true" style="display: block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content text-dark position-absolute" style="height: 400px; width: 100%;">
            <div class="modal-body p-1 overflow-auto">
                <app-map (addressPointEvent)="setAddressSelect($event)" [addressPoint]="currentAddress"></app-map>
            </div>
            <div class="modal-footer p-0">
                <button class="btn btn-sm btn-primary " type="button" data-dismiss="modal" (click)="okHandler()">Aceptar</button>
                <button class="btn btn-sm btn-secondary" type="button" data-dismiss="modal" (click)="cancelHandler()">Cancelar</button>
            </div>
         </div>
</div>
</div>



