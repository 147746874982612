import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ParentModel } from 'src/domain/models/parent.model';

@Component({
  selector: 'app-parent-list',
  templateUrl: './parent-list.component.html',
  styleUrls: ['./parent-list.component.css']
})
export class ParentListComponent {
  @Input() useClassFull: Boolean = false;
  @Input() parents: ParentModel[] = [];
  @Output() deleteEvent = new EventEmitter<ParentModel>();

  constructor() { 
    
  }

  ngOnInit(): void {
  }

  deleteHandler(parentTutor:ParentModel){
    this.deleteEvent.emit(parentTutor);
  }
  
}
