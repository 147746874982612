<div class="container-fluid">
    <div class="card shadow mb-4">
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="text-center small">Nombre</th>
                            <th class="text-center small">DNI</th>
                            <th class="text-center small">Email</th>
                            <th class="text-center small">Telefono</th>
                            <th class="text-center small">Descripcion</th>
                            <th class="text-center small">Vinculo</th>
                            <th class="text-center small">Alumno</th>
                            <th class="text-center small" style="width: 40px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let parent of parents">
                            <td class="small p-1">{{parent.full_name}}</td>
                            <td class="small p-1">{{parent.dni}}</td>
                            <td class="small p-1">{{parent.email}}</td>
                            <td class="small p-1">{{parent.telephone_number}}</td>
                            <td class="small p-1">{{parent.description}}</td>
                            <td class="small p-1">{{parent.relation}}</td>
                            <td class="small p-1">{{parent.student_reference.name}}</td>
                            <td routerLink="/parent/{{parent.id}}/detail" class="wlm-link text-center" style="width: 40px;" title="abrir registro"><i class="fas fa-eye"></i></td>
                        </tr>
                    </tbody>
                </table>
                <h6 *ngIf="parents.length == 0" class="mt-5 text-center">No hay registros</h6>
            </div>
        </div>
    </div>

</div>
