import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { Location } from '@angular/common';
import { SubscriptorGetByIdUseCase } from 'src/domain/usecases/subscriptor/subscriptor-get-by-id.usecase';
import { SubscriptorModel } from 'src/domain/models/subscriptor.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { RoleModel } from 'src/domain/models/role.model';
import { SubscriptionModel } from 'src/domain/models/subscription.model';
import { TeacherModel } from 'src/domain/models/teacher.model';

const enum ViewType {
  Roles = 1,
  Suscription = 0
}


@Component({
  selector: 'app-subscriber-detail',
  templateUrl: './subscriber-detail.component.html',
  styleUrls: ['./subscriber-detail.component.css']
})
export class SubscriberDetailComponent {
  subscriptorId!:number;
  subscriptor: SubscriptorModel | undefined;
  viewTap:ViewType = ViewType.Roles;
  form: FormGroup;
  email: FormControl = new FormControl('', Validators.required);
  password: FormControl = new FormControl('', Validators.required);
  name:FormControl = new FormControl('', Validators.required);
  photo:FormControl = new FormControl('', Validators.required);
  state:FormControl = new FormControl('', Validators.required);
  email_verified_at:FormControl = new FormControl('', Validators.required);
  created_at:FormControl = new FormControl('', Validators.required);
  updated_at:FormControl = new FormControl('', Validators.required);
  reset_password_token:FormControl = new FormControl('', Validators.required);
  email_confirmation_token:FormControl = new FormControl('', Validators.required);
  roles!: RoleModel[];
  subscription!:SubscriptionModel;
  teacher!: TeacherModel;


  constructor(
    private activatedRoute:ActivatedRoute,
    private location:Location,
    private formBuilder: FormBuilder,
    private contextUseCase: ContextUseCase,
    private subscriptorGetByIdUseCase:SubscriptorGetByIdUseCase) { 
      this.form = formBuilder.group({
        id:this.subscriptorId,
        email: this.email,
        name: this.name,
        state:this.state,
        email_verified_at: this.email_verified_at,
        created_at: this.created_at,
        updated_at: this.updated_at,
        reset_password_token:this.reset_password_token,
        email_confirmation_token:this.email_confirmation_token,
      });
    }
  



  ngOnDestroy(): void {
   
  }

    
  ngOnInit(): void {
      this.contextUseCase.hideToggledNav();
      this.contextUseCase.showSpinner();
      this.subscriptorId = this.activatedRoute.snapshot.params['id'];
debugger
      this.subscriptorGetByIdUseCase.execute(this.subscriptorId).subscribe((subscriptor)=>{
           this.subscriptor = subscriptor;

           this.form.controls['id'].setValue(this.subscriptor?.id); 
           this.form.controls['email'].setValue(this.subscriptor?.email); 
           this.form.controls['name'].setValue(this.subscriptor?.name); 
           this.form.controls['state'].setValue(this.subscriptor?.state); 
           this.form.controls['email_verified_at'].setValue(this.subscriptor?.email_verified_at); 
           this.form.controls['created_at'].setValue(this.subscriptor?.created_at); 


           this.contextUseCase.hideSpinner();
          });
  }

  back(){
    this.location.back();
  }

  setViewType(type:number){
     this.viewTap = type;
  }


  getSubscritorState(subscriptor:SubscriptorModel | undefined ):string{

    if(subscriptor == undefined)
      return '';

    switch(subscriptor.state){
     case 0: 
      return 'Pendiente Confirmacion Email'
     case 1: 
      return 'Pendiente Autorizacion'
     case 2: 
      return 'Activo'
     case 3: 
      return 'Bloqueado'
     default: 
      return 'No Definido'
    }
}


cancel(){
  this.location.back();
}

saveHandler(): void{
  if(this.form.invalid){
    
    this.form.markAllAsTouched();
    return;
  }
}

}