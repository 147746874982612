import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { SubscriptionModel } from 'src/domain/models/subscription.model';
import { SubscriptionRepository } from 'src/domain/repositories/subscription.repository';


@Injectable({
    providedIn: 'root',
})
export class SubscriptionGetCurrentUseCase implements UseCase<void, SubscriptionModel | undefined> {

    constructor(private subscriptionRepository: SubscriptionRepository) { }

    execute(
    ): Observable<SubscriptionModel | undefined> {
        return this.subscriptionRepository.getUserSubscription();
    }
}