<div class="position-fixed bottom-0 end-0 p-3" style="z-index: 1000">
	<ng-container>
        <div class="toast hide" id="toast"  role="alert" data-animation="true" data-autohide="true" data-delay="4000">
            <div class="toast-header  bg-success text-white">
              <i class="fas fa-2x fa-circle-info mr-3 text-white"></i>
              <strong class="me-auto">www</strong>
              <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body">
              wwwwwwwwww
            </div>
          </div>
          <div class="toast hide" id="toast"  role="alert" data-animation="true" data-autohide="true" data-delay="4000">
            <div class="toast-header  bg-success text-white">
              <i class="fas fa-2x fa-circle-info mr-3 text-white"></i>
              <strong class="me-auto">yyyyyyyy</strong>
              <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body">
             yyyyyy
            </div>
          </div>
    </ng-container>
</div>
