<div class="col">
<div class="text-center">
                                <h1 class="h4 text-gray-900 mb-4">Nueva Institución</h1>
                            </div>
                            <form [formGroup]="form" (submit)="saveHandler()" class="user">
                                <div class="form-floating mb-2">
                                        <input id="name" type="text" class="form-control"
                                            placeholder="Nombre"
                                            autocomplete="off"
                                            formControlName="name" [class.is-invalid]="!name.valid && name.touched">
                                            <div *ngIf="name.errors?.['required']" class="invalid-feedback">
                                              debe completar este campo
                                            </div>
                                            <div *ngIf="name.errors?.['pattern']" class="invalid-feedback">
                                              el campo tiene caracteres invalidos
                                            </div>
                                            <label for="name" class="form-floating-required">Nombre</label>
                                </div>
                                <div class="form-floating mb-2">
                                        <input id="email" type="text" class="form-control"
                                        autocomplete="off"
                                            placeholder="Email" formControlName="email" [class.is-invalid]="!email.valid && email.touched">
                                            <div *ngIf="email.errors?.['required']" class="invalid-feedback">
                                              debe completar este campo
                                            </div>
                                            <div *ngIf="email.errors?.['email']" class="invalid-feedback">
                                              el correo electronico es invalido
                                            </div>
                                            <label for="email">Email</label>
                                </div>
                                <div class="form-floating mb-2">
                                    <input id="telephone_number" type="tel" class="form-control"
                                        autocomplete="off"
                                        placeholder="Telefono" formControlName="telephone_number" [class.is-invalid]="!telephone_number.valid && telephone_number.touched">
                                        <div *ngIf="telephone_number.errors?.['required']" class="invalid-feedback">
                                          debe completar este campo
                                        </div>
                                        <label for="telephone_number">Telefono</label>
                                </div>
                                <div class="form-floating mb-2">
                                  <app-autocomplete-address label="Direccion" (addressSelect)="getAddressSelect($event)" [validateEvent]="validateAddress" (changedStateEvent)="addressChangeState($event)"></app-autocomplete-address>
                                    </div>
                                    <div class="form-floating mb-2">
                                      <textarea id="description" class="form-control" style="height: 100px"
                                                placeholder="Descripcion" formControlName="description" [class.is-invalid]="!description.valid && description.touched"></textarea>
                                      <label for="description">Descripcion</label>
                                    </div>
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                     <button type="submit" class="col btn btn-primary">
                                    Create
                                </button>
                                <button type="button" (click)="cancel()" class="col btn btn-secondary">
                                  Cancelar
                              </button>
                              </div>
                                <hr>
                            </form>
                        </div>
