import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { SchoolModel } from 'src/domain/models/school.model';
import { SchoolRepository } from 'src/domain/repositories/school.repository';
import { NotificationUseCase } from '../common/notification.usecase';
import { ContextUseCase } from '../common/context.usecase';

@Injectable({
    providedIn: 'root',
})
export class SchoolCreateUseCase implements UseCase<SchoolModel, SchoolModel> {

    constructor(private schoolRepository: SchoolRepository,
                private notificationUseCase:NotificationUseCase,
                private contextUseCase:ContextUseCase) { }

    execute(
        school: SchoolModel,
    ): Observable<SchoolModel> {
        return new Observable<SchoolModel>((observable)=>{
            this.contextUseCase.showSpinner();
          
            return this.schoolRepository.create(school).subscribe(
               {
                 next:(school) =>{
                    observable.next(school);
                 },
                 error: (error) =>{
                    this.notificationUseCase.showError("Error al crear", error.error.message);
                    observable.error(error);
                 },
                 complete:()=>observable.complete()
               });
            })    

        
    }
}

