<div class="m-1">
    <div class="card shadow mb-2">
        <div class="card-header py-3">
            <div class="row">
                <i class="fas fa-fw fa-child fa-3x text-success wlm-detail-title-icon"></i>
                <h4 class="wlm-detail-title-text text-success">ESTUDIANTE</h4>
            </div>
            <div class="row">
                <div class="col-xl-2 col-lg-2 col-12 text-center ">
                    <span class="account-user-avatar">
                      <img [src]="student?.photo" alt="user-image" style="max-height: 180px;" class="border mb-2 img-thumbnail"/>
                  </span>
                  </div>
            <div class="col-10">
            <p>Nombre:   <b>{{student?.full_name}}</b></p>
            <p>Telefono: <b>{{student?.telephone_number}}</b></p>
            <p>Email:    <b>{{student?.email}}</b></p>
            <p>DNI:      <b>{{student?.dni}}</b></p>
            <p>Fecha Nacimiento:      <b>{{student?.birth_date | date: 'dd/MM/yyyy' }}</b></p>
            <p>Año Ingreso:      <b>{{student?.year_of_entry}}</b></p>
            <p>Cuerda:      <b>{{student?.vocal_cord}}</b></p>
        </div>
        </div>
        <div class="row">
            <div class="btn-group btn-group-sm justify-content-md-end shadow-none" role="group" aria-label="">
                <button type="button" 
                        (click)="back()"  
                        title="Volver"
                        class="btn btn-outline-primary wlm-button-detail shadow-none">
                    <i class="fa fa-lg fa-arrow-left"></i><span class="d-none d-lg-block">Volver</span></button>
                <button type="button" 
                        routerLink="/student/{{id}}/edit" 
                        title="Editar estudiente"
                        class="btn btn-outline-primary wlm-button-detail shadow-none">
                    <i class="fas fa-lg fa-pen-to-square text-warning"></i><span class="d-none d-lg-block">Editar Estudiante</span></button>
                <button type="button" 
                    (click)="deleteHandler()" 
                    title="Eliminar Estudiante"  
                    class="btn btn-outline-primary shadow-none wlm-button-detail">
                    <i class="fa fa-lg fa-trash text-danger"></i>
                    <span class="d-none d-lg-block">Eliminar Estudiante</span>
                </button>
                <button type="button" 
                    [class.active]="viewTap === viewTapSchools" 
                    (click)="viewTap = viewTapSchools" 
                    title="Ver instituciones"
                    class="btn btn-outline-primary wlm-button-detail shadow-none">
                    <i class="fas fa-lg fa-solid fa-school wlm-text-green"></i><span class="d-none d-lg-block">Instituciones</span></button>
                <button type="button" 
                    [class.active]="viewTap === viewTapCourses" 
                    (click)="viewTap = viewTapCourses" 
                    title="Ver cursos"
                    class="btn btn-outline-primary wlm-button-detail shadow-none">
                    <i class="fas fa-lg fa-solid fa-graduation-cap wlm-text-green"></i><span class="d-none d-lg-block">Cursos</span></button>
                <button type="button" 
                    [class.active]="viewTap === viewTapParents" 
                    (click)="viewTap = viewTapParents"
                    title="Ver padres / tutores"  
                    class="btn btn-outline-primary wlm-button-detail shadow-none">
                    <i class="fas fa-lg fa-solid fa-users wlm-text-green"></i><span class="d-none d-lg-block">Padres / Tutores</span></button>
            </div>
        </div>
        </div>
        <div class="card-body p-0">
            <ng-container  *ngIf="viewTap === viewTapParents">
                <app-parent-container [studentId]="id"></app-parent-container>
            </ng-container>
            <ng-container  *ngIf="viewTap === viewTapSchools">
                <app-school-container [studentId]="id"></app-school-container>
            </ng-container>
            <ng-container  *ngIf="viewTap === viewTapCourses">
                <app-course-container [studentId]="id"></app-course-container>
            </ng-container>
        </div>
    </div>

</div>


