import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SchoolModel } from 'src/domain/models/school.model';
import { SchoolRepository } from 'src/domain/repositories/school.repository';

@Injectable({
    providedIn: 'root',
})
export class SchoolGetByStudentUseCase {

    constructor(private schoolRepository: SchoolRepository) { }

    execute(studentId:number, force:Boolean):Observable<SchoolModel[]>
    {
        return this.schoolRepository.getByStudent(studentId, force);
    }
}
