import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/domain/services/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {
  isShow: Boolean = false;

  constructor(private spinnerService:SpinnerService) { 
   
  }



  ngOnInit(): void {
      this.spinnerService.ChangeStateSpinnerEvent.subscribe((state)=>{
         this.isShow = state as Boolean;
      });
  }

}
