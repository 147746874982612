
       <!-- Sidebar -->
       <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" [class.toggled]="isToggled"  id="accordionSidebar">

        <!-- Sidebar - Brand -->
        <a class="sidebar-brand d-flex align-items-center justify-content-center" routerLink="/dashboard">
            <img src="presentation/assets/logo_mini.png" style="max-width: 40px;" alt="">
            <div class="sidebar-brand-text mx-3">Agenda Del Profesor</div>
        </a>

        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <!-- Nav Item - Dashboard -->
        <li class="nav-item">
            <a class="nav-link" href="" routerLink="/dashboard">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Panel</span></a>
        </li>
        <!-- Divider -->
        <hr class="sidebar-divider">

        <!-- Heading -->
        <div class="sidebar-heading">
           Registros
        </div>

        <!-- Nav Item - Pages Collapse Menu -->
        <li class="nav-item">
            <a class="nav-link collapsed" href="" routerLink="/calendar">
                <i class="fas fa-fw fa-calendar-check"></i>
                <span>Calendario</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link collapsed" href="" routerLink="/schools">
                <i class="fas fa-fw fa-school"></i>
                <span>Instituciones</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="" routerLink="/courses">
                <i class="fas fa-fw fa-graduation-cap"></i>
                <span>Cursos</span>
            </a>
        </li>
        <li class="nav-item">
            <a href="" routerLink="/students" class="nav-link">
                <i class="fas fa-fw fa-child"></i>
                <span>Alumnos</span>
            </a>
        </li>
        <li class="nav-item">
            <a href="" routerLink="/parents" class="nav-link">
                <i class="fas fa-fw fa-users"></i>
                <span>Padres / Tutores</span>
            </a>
        </li>
        <li class="nav-item" style="display: none;">
            <a href="" routerLink="/teachers" class="nav-link">
                <i class="fas fa-fw fa-chalkboard-teacher"></i>
                <span>Profesores</span>
            </a>
        </li>
        <li HasPermisionDirective [RoleRequired]="'ADMIN'" class="nav-item">
            <a href="" routerLink="/admin/subscriber" class="nav-link">
                <i class="fas fa-fw fa-chalkboard-teacher"></i>
                <span>Autorizacion</span>
            </a>
        </li>

        

        <!-- Nav Item - Utilities Collapse Menu -->
        <li class="nav-item" style="display: none;">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
                aria-expanded="true" aria-controls="collapseUtilities">
                <i class="fas fa-fw fa-wrench"></i>
                <span>Archivos</span>
            </a>
            <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities"
                data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Documentacion:</h6>
                    <a class="collapse-item" href="utilities-color.html">Mi Documentacion</a>
                    <a class="collapse-item" href="utilities-border.html">Trabajos Practicos</a>
                    <a class="collapse-item" href="utilities-animation.html">Animations</a>
                    <a class="collapse-item" href="utilities-other.html">Other</a>
                </div>
            </div>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider">

        <!-- Heading -->
        <div class="sidebar-heading" style="display: none;">
            Addons
        </div>

        <!-- Nav Item - Pages Collapse Menu -->
        <li class="nav-item" style="display: none;">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages"
                aria-expanded="true" aria-controls="collapsePages">
                <i class="fas fa-fw fa-folder"></i>
                <span>Pages</span>
            </a>
            <div id="collapsePages" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                <div class="bg-white py-2 collapse-inner rounded">
                    <h6 class="collapse-header">Login Screens:</h6>
                    <a class="collapse-item" href="login.html">Login</a>
                    <a class="collapse-item" href="register.html">Register</a>
                    <a class="collapse-item" href="forgot-password.html">Forgot Password</a>
                    <div class="collapse-divider"></div>
                    <h6 class="collapse-header">Other Pages:</h6>
                    <a class="collapse-item" href="404.html">404 Page</a>
                    <a class="collapse-item" href="blank.html">Blank Page</a>
                </div>
            </div>
        </li>

        <!-- Nav Item - Charts -->
        <li class="nav-item" style="display: none;">
            <a class="nav-link" href="charts.html">
                <i class="fas fa-fw fa-chart-area"></i>
                <span>Charts</span></a>
        </li>

        <!-- Nav Item - Tables -->
        <li class="nav-item" style="display: none;">
            <a class="nav-link" href="tables.html">
                <i class="fas fa-fw fa-table"></i>
                <span>Tables</span></a>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider d-none d-md-block">

        <!-- Sidebar Toggler (Sidebar) -->
        <div class="text-center d-none d-md-inline">
            <button class="rounded-circle border-0" id="sidebarToggle" (click)="toggledHander()" ></button>
        </div>


    </ul>
    <!-- End of Sidebar -->