  <div class="card-group">
<div class="col-xl-3 col-md-6 mb-4" [class.col-xl-12]="useClassFull"  *ngFor="let school of schools; trackBy:trackByItems">
    <div class="card border-left-primary shadow h-100 py-2">
        <div class="card-header p-2 d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary wlm-pointer" routerLink="/school/{{school.id}}/{{school.name}}/detail">{{school.name}}</h6>
        <div class="dropdown no-arrow">
            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item" href="" routerLink="/school/{{school.id}}/{{school.name}}/edit">Modificar</a>
                <a class="dropdown-item" href="" routerLink="/school/{{school.id}}/{{school.name}}/detail" >Detalles</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="deleteHandler(school)">Eliminar</a>
            </div>
        </div>
    </div>
        <div class="card-body wlm-pointer" routerLink="/school/{{school.id}}/{{school.name}}/detail" >
            <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        {{school.full_address}}</div>
                    <div class="h6 mb-0 font-weight-bold text-gray-800">{{school.email}}</div>
                    <div class="text-xs mt-1 text-success text-uppercase">
                        Activo</div>
                </div>
                <div class="col-auto">
                    <i class="fas fa-school fa-3x text-gray-300"></i>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
