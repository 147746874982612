import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { ParentModel } from 'src/domain/models/parent.model';
import { ParentRepository } from 'src/domain/repositories/parent.repository';

@Injectable({
    providedIn: 'root',
})
export class ParentGetAllUseCase implements UseCase<Boolean, ParentModel[]> {

    constructor(private parentRepository: ParentRepository) { }

    execute(
        force: Boolean,
    ): Observable<ParentModel[]> {
        return this.parentRepository.getAll(force);
    }
}
