import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export abstract class LocalStorageService {
  abstract save(key: string, data:any):void;
  abstract get<TypeResult>(key:string):TypeResult | undefined;
  abstract clear(key:string):void;
}
