import { Observable } from 'rxjs';
import { SubscriptorModel } from '../models/subscriptor.model';

export abstract class SubscriptorRepository {
    
    abstract getById(id: number): Observable<SubscriptorModel | undefined>;
    abstract getAll(force:Boolean): Observable<SubscriptorModel[]>;
    abstract create(model:SubscriptorModel): Observable<SubscriptorModel>;
    abstract update(model:SubscriptorModel): Observable<SubscriptorModel>;
    abstract getCurrent():SubscriptorModel | undefined;
    abstract delete(model:SubscriptorModel): Observable<void>;
}
