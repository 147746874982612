<div class="col border-top border-secondary mb-1 mt-5"></div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="">Dias de Clases</a>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
      </div>
      <div class="btn-group" role="group" aria-label="">
        <button type="button" class="btn btn-primary" (click)="addDayHandler()"> <i class="fab fa fa-plus"></i></button>
      </div>
    </nav>
    <h6 *ngIf="scheduleLength == 0" class="mt-3 mb-5 text-center">agregue dias de clases</h6>
    <ng-container #items>
    </ng-container>
