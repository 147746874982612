import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { StudentModel } from 'src/domain/models/student.model';
import { StudentRepository } from 'src/domain/repositories/student.repository';


@Injectable({
    providedIn: 'root',
})
export class StudentGetByIdUseCase implements UseCase<number, StudentModel | undefined> {

    constructor(private studentRepository: StudentRepository) { }

    execute(
        studentId: number,
    ): Observable<StudentModel | undefined> {
        return this.studentRepository.getById(studentId);
    }
}