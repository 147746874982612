import { Injectable, Query } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { ProvinceModel } from 'src/domain/models/province.model';
import { ProvinceRepository } from 'src/domain/repositories/province.repository';


@Injectable({
    providedIn: 'root',
})
export class ProvinceGetQueryUseCase implements UseCase<Boolean, ProvinceModel[]> {

    constructor(private provinceRepository: ProvinceRepository) { }

    execute(
        force: Boolean,
    ): Observable<ProvinceModel[]> {
        return this.provinceRepository.getAll(force);
    }
}
