<div class="col">
    <div class="text-center">
        <h1 class="h4 text-gray-900 mb-4">Editar Alumno</h1>
    </div>
    <form [formGroup]="form" (submit)="saveHandler()" class="user">
      <div class="row">
        <div class="col-xl-2 col-lg-2 col-12 text-center">
          <span class="account-user-avatar">
            <img [src]="photo" alt="user-image" style="max-height: 180px;" class="border mb-2 img-thumbnail wlm-pointer" (click)="selectImage.click()"/>
            <input id="photo" autocomplete="off" type="file" (change)="updatePhotoHandler($event)" style="display: none" #selectImage>
        </span>
        </div>
        <div class="col-12 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10">
        <div class="form-floating mb-2">
          <input id="full_name" autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Nombre"
          formControlName="full_name" [class.is-invalid]="!full_name.valid && full_name.touched">
          <div *ngIf="full_name.errors?.['required']" class="invalid-feedback">
            debe completar este campo
          </div>
          <label for="full_name" class="form-floating-required">Nombre</label>
        </div>
        <div class="form-floating mb-2">
          <input id="dni" autocomplete="off" type="number" class="form-control"
              placeholder="DNI" formControlName="dni" [class.is-invalid]="!dni.valid && dni.touched">
              <label for="dni">DNI</label>
            </div>
            <div class="form-floating mb-2">
              <input id="birth_date" autocomplete="off"  type="date" class="form-control"
                  placeholder="Edad" formControlName="birth_date" [class.is-invalid]="!birth_date.valid && birth_date.touched">
                  <label for="birth_date">Fecha Nacimiento</label>
            </div>
        </div>
        <div class="col">
          <div class="form-floating mb-2">
            <input id="age" autocomplete="off" type="number" class="form-control"
                placeholder="Edad" formControlName="age" [class.is-invalid]="!age.valid && age.touched">
                <label for="age">Edad</label>
              </div>
          <div class="form-floating mb-2">
            <input id="telephone_number" autocomplete="off" type="text" class="form-control"
                placeholder="Telefono" formControlName="telephone_number" [class.is-invalid]="!telephone_number.valid && telephone_number.touched">
                <label for="telephone_number">Telefono</label>
              </div>
              <div class="form-floating mb-2">
                <input id="email" autocomplete="off" type="email" class="form-control form-control-sm"
                    placeholder="Email" formControlName="email" [class.is-invalid]="!email.valid && email.touched">
                    <div *ngIf="email.errors?.['email']" class="invalid-feedback">
                      el correo electronico es invalido
                    </div>
                    <label for="email">Email</label>
        </div>
        <div class="form-floating mb-2">
          <input  id="year_of_entry" autocomplete="off" type="text" class="form-control"
              placeholder="Año de Ingreso" formControlName="year_of_entry" [class.is-invalid]="!year_of_entry.valid && year_of_entry.touched">
              <label for="year_of_entry">Año de Ingreso</label>
            </div>
            <div class="form-floating mb-2">
              <input  id="vocal_cord" autocomplete="off" type="text" class="form-control"
                  placeholder="Cuerda" formControlName="vocal_cord" [class.is-invalid]="!vocal_cord.valid && vocal_cord.touched">
                  <label for="vocal_cord">Cuerda</label>
                </div>
       
            <div class="form-floating mb-2">
            <input id="allergies" autocomplete="off"  type="text" class="form-control"
            placeholder="Enfermedad o Alergia" formControlName="allergies" [class.is-invalid]="!allergies.valid && allergies.touched">
            <label for="allergies">Enfermedad o Alergia</label>
          </div>
                <div class="form-floating mb-2">
                  <textarea id="description" autocomplete="off" class="form-control" style="height: 100px"
                      placeholder="Descripcion" formControlName="description" [class.is-invalid]="!description.valid && description.touched"></textarea>
                      <label for="description">Descripcion</label>
                    </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
             <button type="submit" class="col btn btn-primary">
            Guardar
        </button>
        <button type="button" (click)="cancel()" class="col btn btn-secondary">
          Cancelar
      </button>
      </div>
        </div>
      </div>
        <hr>
    </form>
</div>

