export type TypeAlert = 'Error' | 'Info' | 'Question';

export class AlertMessageModel {
    title: string = '';
    message: string = '';
    typeAlert: TypeAlert = 'Error';

    constructor(title: string ,
                message: string ,
                typeAlert: TypeAlert){

                    this.title = title;
                    this.message = message;
                    this.typeAlert = typeAlert;
    }
 }