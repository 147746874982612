import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchGlobalResultModel } from 'src/domain/models/search-result.model';
import { GlobalSearchRepository } from 'src/domain/repositories/global-search.repository';
import { GlobalSearchService } from 'src/domain/services/global-search.service';


@Injectable({
  providedIn: 'root'
})
export class GlobalSearchImplementationService implements GlobalSearchService {
   
    private textSearch!:string;

    public searchGlobalResult!:SearchGlobalResultModel;
    public changedResults = new EventEmitter<SearchGlobalResultModel>();
    public updateResults = new EventEmitter();

    constructor(private globalSearchRepository: GlobalSearchRepository) { 
        this.updateResults.subscribe(()=>{
            this.search(this.textSearch).subscribe({
             next:(result)=>{
               this.searchGlobalResult = result;
               this.changedResults.emit();
             },
             error:(error)=>{
                throw error;
             }
            });
       });


    }
  
    public search(textSearch:string):Observable<SearchGlobalResultModel>{
        return new Observable<SearchGlobalResultModel>((observer) => {
          this.textSearch = textSearch;
          this.globalSearchRepository.search(textSearch).subscribe({
            next: (result)=>{
                 this.searchGlobalResult = result;
                 this.changedResults.emit(result);
                 observer.next(result);
            },
            error: (error)=>{
                 this.changedResults.error(error);
                 observer.error(error);
            }
          });
         });
      }
}
