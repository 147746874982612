import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs';
import { AddressModel } from 'src/domain/models/address.model';
import { SchoolModel } from 'src/domain/models/school.model';
import { SchoolDeleteUseCase } from 'src/domain/usecases/school/school-delete.usecase';
import { SchoolGetByIdUseCase } from 'src/domain/usecases/school/school-get-by-id.usecase';
import { Location } from '@angular/common';
import { ReferenceModel } from 'src/domain/models/reference.model';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { NavService } from 'src/domain/services/nav.service';

const enum ViewType {
  Course = 1,
  Map = 0
}

@Component({
  selector: 'app-school-detail',
  templateUrl: './school-detail.component.html',
  styleUrls: ['./school-detail.component.css']
})
export class SchoolDetailComponent implements OnInit, OnDestroy {
  schoolSuscription: Subscription | undefined;
  schoolId!:number;
  schoolName!:string;
  school: SchoolModel = SchoolModel.empty();
  viewTap:ViewType = ViewType.Course;
  viewTapCourse:ViewType = ViewType.Course;
  viewTapMap:ViewType = ViewType.Map;
  mapPoint:AddressModel[] = []; 
  schoolReference!:ReferenceModel;


  constructor(
    private activatedRoute:ActivatedRoute,
    private schoolGetByIdUseCase: SchoolGetByIdUseCase,
    private schoolDeleteUseCase:SchoolDeleteUseCase,
    private location:Location,
    private contextUseCase: ContextUseCase) { 

    }
  ngOnDestroy(): void {
     this.schoolSuscription?.unsubscribe();
  }

    
  ngOnInit(): void {
      this.contextUseCase.hideToggledNav();
      this.contextUseCase.showSpinner();
      this.schoolId = this.activatedRoute.snapshot.params['id'];
      this.schoolName = this.activatedRoute.snapshot.params['name'];

      this.schoolReference = new ReferenceModel(this.schoolId, this.schoolName);

      this.schoolSuscription = this.schoolGetByIdUseCase.execute(this.schoolId).subscribe((school)=>{
           this.school = school;
           this.mapPoint.push({lat: school.latitude, lon:school.longitude, display_name: school.full_address, title: school.name, id: school.id, target:school});
           this.contextUseCase.hideSpinner();
          });
  }

  back(){
    this.location.back();
  }

  setViewType(type:number){
     this.viewTap = type;
  }

  deleteHandler(){
      this.schoolDeleteUseCase.execute(this.school).subscribe({
        complete:()=> this.back()
      });
  }
}

