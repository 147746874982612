import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ParentModel } from 'src/domain/models/parent.model';
import { ParentGetByIdUseCase } from 'src/domain/usecases/parent/parent-get-by-id-usecase';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { ParentUpdateUseCase } from 'src/domain/usecases/parent/parent-update.usecase';


@Component({
  selector: 'app-parent-edit',
  templateUrl: './parent-edit.component.html',
  styleUrls: ['./parent-edit.component.css']
})
export class ParentEditComponent {
  id!: number;
  parent: ParentModel | undefined;
  form: FormGroup;
  full_name: FormControl = new FormControl('', Validators.required);
  email: FormControl = new FormControl('', [Validators.email]);
  full_address: FormControl = new FormControl('');
  telephone_number: FormControl = new FormControl('');
  student_id: FormControl = new FormControl('',  Validators.required);
  description: FormControl = new FormControl('');
  dni: FormControl = new FormControl('');
  relation: FormControl = new FormControl('',  Validators.required);


  constructor(private formBuilder: FormBuilder,
              private activatedRoute:ActivatedRoute,
              private parentGetByIdUseCase:ParentGetByIdUseCase,
              private contextUseCase: ContextUseCase,
              private parentUpdateUseCase:ParentUpdateUseCase,
              private location:Location) { 

                this.form = formBuilder.group({
                  id: this.id,
                  full_name: this.full_name,
                  email: this.email,
                  telephone_number: this.telephone_number,
                  student_id: this.student_id,
                  description: this.description,
                  dni: this.dni,
                  relation: this.relation
                });
              }

  ngOnInit(): void {
    this.contextUseCase.showSpinner();
    this.contextUseCase.hideToggledNav();
    this.id = this.activatedRoute.snapshot.params['id'];
   
    this.loadStudent(this.id);
  }

  loadStudent(id: number) {
    this.parentGetByIdUseCase.execute(id).subscribe({
      next:(parent)=>{
       this.parent = parent;
       this.form.controls['id'].setValue(this.parent?.id); 
       this.form.controls['dni'].setValue(this.parent?.dni); 
       this.form.controls['full_name'].setValue(this.parent?.full_name); 
       this.form.controls['email'].setValue(this.parent?.email); 
       this.form.controls['telephone_number'].setValue(this.parent?.telephone_number); 
       this.form.controls['description'].setValue(this.parent?.description); 
       this.form.controls['student_id'].setValue(this.parent?.student_id); 
       this.form.controls['relation'].setValue(this.parent?.relation); 

       this.contextUseCase.hideSpinner();
      }
    });
  }



  cancel(){
    this.location.back();
  }

  saveHandler(): void{
    if(this.form.invalid){
      
      this.form.markAllAsTouched();
      return;
    }

    this.parentUpdateUseCase.execute(this.form.value).subscribe();

   }
 
 }
 