import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseImplementationRepository } from "./base-implementation.repository";
import { Observable } from "rxjs";
import { ParentRepository } from "src/domain/repositories/parent.repository";
import { ParentModel } from "src/domain/models/parent.model";

@Injectable({
    providedIn: 'root',
})
export class ParentImplementationRepository extends BaseImplementationRepository<ParentModel> implements ParentRepository {

    constructor(http:HttpClient)
    {
        super(http);
    }

    getParentsByStudent(studentId: number): Observable<ParentModel[]> {
        return  this.http.get<ParentModel[]>('/api/student/'+ studentId +'/parents');
    }
    
    override http!:HttpClient;
    
    override getEntityName(): string {
       return 'parent';
    }
   
    
}