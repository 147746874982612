    <div class="card shadow mb-4">
        <div class="card-body m-1 p-0">
            <div class="table-responsive">
                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="text-center small" >Nombre</th>
                            <th class="text-center small" >Año</th>
                            <th class="text-center small" >Seccion</th>
                            <th class="text-center small" style="width: 40px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let course of courses;">
                            <td class="small p-1">{{course.name}}</td>
                            <td class="small p-1">{{course.year}}</td>
                            <td class="small p-1">{{course.section}}</td>
                            <td routerLink="/course/{{course.id}}/detail" class="wlm-link text-center" style="width: 40px;" title="abrir registro"><i class="fas fa-eye"></i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

