import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserGetProfileUseCase } from 'src/domain/usecases/user/user-get-profile.usecase';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { UserUpdateUseCase } from 'src/domain/usecases/user/user-update.usecase';
import { ToastTypes } from 'src/domain/models/toast-types';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {

  form: FormGroup;
  name: FormControl = new FormControl('', Validators.required);
  email: FormControl = new FormControl('', [Validators.email, Validators.required]);
  photo:string = 'presentation/assets/imgs/undraw_profile.svg';

  constructor(private formBuilder: FormBuilder,
              private location:Location,
              private router: Router,
              private contextUseCase: ContextUseCase,
              private userGetProfileUseCase:UserGetProfileUseCase,
              private userUpdateUseCase:UserUpdateUseCase) { 

                this.form = formBuilder.group({
                  name: this.name,
                  email: this.email,
                  photo:this.photo
                });
              }

  ngOnInit(): void {
      this.contextUseCase.showSpinner();
      this.userGetProfileUseCase.execute().subscribe({
        next:(profile)=>{
          this.form.controls['email'].disable();
          this.form.controls['name'].setValue(profile.name); 
          this.form.controls['email'].setValue(profile.email); 
          this.form.controls['photo'].setValue(profile.photo); 
          this.photo = profile.photo;
          
          this.contextUseCase.hideSpinner();
       }
      })

  }

  cancel(){
    this.location.back();
  }

 
  updatePhotoHandler(event:any) {
    let photoFile = event.target.files[0];
    
    const reader = new FileReader();
    reader.readAsDataURL(photoFile);
    reader.onload = () => {
      this.photo = reader.result + '';
      this.form.controls['photo'].setValue(this.photo); 
    };
   
    
  }
  

  saveHandler(): void{

    if(this.form.invalid){
      this.form.markAllAsTouched();
      return;
    }

    this.contextUseCase.showSpinner();
    
    this.userUpdateUseCase.execute(this.form.value).subscribe({
      next:(user)=>{
        this.contextUseCase.addToast("Registro Creado", "Los datos se actualizaron con exito!!!", ToastTypes.Success);
        this.contextUseCase.hideSpinner();
        this.location.back();
      },
      error:(error)=>{

        this.contextUseCase.hideSpinner();
      }
    })
   
  }
 
 }
 