import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { StudentModel } from 'src/domain/models/student.model';
import { StudentRepository } from 'src/domain/repositories/student.repository';
import { ContextUseCase } from '../common/context.usecase';
import { ToastTypes } from 'src/domain/models/toast-types';
import { Location }from '@angular/common'


@Injectable({
    providedIn: 'root',
})
export class StudentUpdateUseCase implements UseCase<StudentModel, StudentModel> {

    constructor(private studentRepository: StudentRepository,
                private contextUseCase:ContextUseCase,
                private location:Location ){ }

    execute(
        student: StudentModel,
    ): Observable<StudentModel> {
            this.contextUseCase.showSpinner();
            let subscribe = this.studentRepository.update(student);
           
            subscribe.subscribe(
                {
                  next:(response) =>{
                   this.contextUseCase.hideSpinner();
                    this.contextUseCase.addToast("alumno guardado", "el alumno se guardo con exito!!!", ToastTypes.Success);
                    this.location.back();
                  },
                  error: (error) =>{
                   this.contextUseCase.hideSpinner();
                  }
                });

            return subscribe;
        }
}
