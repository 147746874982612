import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'src/domain/services/local-storage.service';
import { UserSessionModel } from 'src/domain/models/user-session-model';


@Injectable({
    providedIn: 'root',
})
export class UserGetUserSessionUseCase implements UseCase<void, UserSessionModel> {

    constructor(private localStorageService: LocalStorageService) { }

    execute(): Observable<UserSessionModel> {
       return new Observable<UserSessionModel>((observer)=>{
            let session = this.localStorageService.get<UserSessionModel>('SESSION') 
            observer.next(session);
       });
    }
}
