import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AddressModel } from 'src/domain/models/address.model';
import { SchoolModel } from 'src/domain/models/school.model';
import { NavService } from 'src/domain/services/nav.service';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { SchoolDeleteUseCase } from 'src/domain/usecases/school/school-delete.usecase';
import { SchoolGetAllUseCase } from 'src/domain/usecases/school/school-get-all.usecase';
import { SchoolGetByStudentUseCase } from 'src/domain/usecases/school/school-get-by-student.usecase';

const enum ViewType {
  Table = 0,
  Card = 1,
  Map = 2
}

@Component({
  selector: 'app-school-container',
  templateUrl: './school-container.component.html',
  styleUrls: ['./school-container.component.css']
})

export class SchoolContainerComponent implements OnInit, OnDestroy {

  @Input() studentId: number | undefined;
  notRecords:Boolean = false;
  loading:Boolean = true;
  schools: SchoolModel[] = [];
  viewAs:ViewType = ViewType.Card;
  viewAsCart:ViewType = ViewType.Card;
  viewAsTable:ViewType = ViewType.Table;
  viewAsMap:ViewType = ViewType.Map;
  schoolsPoints:AddressModel[] = [];
  suscription: Subscription | undefined;

  

  
  constructor(private schoolGetAllUseCase:SchoolGetAllUseCase,
              private schoolDeleteUseCase: SchoolDeleteUseCase,
              private schoolGetByStudentUseCase:SchoolGetByStudentUseCase,
              private contextUseCase:ContextUseCase) { }


  ngOnDestroy(): void {
     this.suscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.contextUseCase.hideToggledNav();
    this.loadSchoolsHandler(true);
    this.loadPoints();
  }
 
  loadPoints() {
    this.schoolsPoints = this.schools.map(school => {
      return {lat: school.latitude, lon:school.longitude, display_name: school.full_address, title: school.name, id: school.id, target:school}
    });
  }


  setViewType(viewType:ViewType){
    this.viewAs = viewType;
    this.loadPoints();
  }

  loadSchoolsHandler(force:Boolean):void{
      if(this.studentId != null)
        this.loadSchoolByStudent(force);
      else
        this.loadSchools(force);
  }


  loadSchoolByStudent(force:Boolean):void{
    if(this.studentId == undefined)
        return;

    this.loading = true;
    this.notRecords = false;

    this.suscription =  this.schoolGetByStudentUseCase.execute(this.studentId, force).subscribe((schools)=>{
      this.schools = schools;
      this.loading = false;
       if(schools.length == 0)
          this.notRecords = true;
    }) 
    
  } 

  loadSchools(force:Boolean):void{
    this.loading = true;
    this.notRecords = false;

    this.suscription =  this.schoolGetAllUseCase.execute(force).subscribe((schools)=>{
      this.schools = schools;
      this.loading = false;
       if(schools.length == 0)
          this.notRecords = true;
    }) 
  }


  deleteHandler(school:SchoolModel):void{
    this.schoolDeleteUseCase.execute(school).subscribe({
      next:()=> {
        this.loading = true
      },
      complete:()=>{
        this.loadSchools(true);
        this.loading = false;
     } 
    })
  }
}
