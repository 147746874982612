import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { UserRepository } from '../../repositories/user.repository';
import { Injectable } from '@angular/core';
import { NotificationUseCase } from '../common/notification.usecase';

@Injectable({
    providedIn: 'root',
})
export class UserEmailConfirmationUseCase implements UseCase<string, void> {

    constructor(private userRepository: UserRepository,
                private notificationUseCase:NotificationUseCase) { }

    execute(
       token: string,
    ): Observable<void> {
        return new Observable<void>((observer) => {
            this.userRepository.emailConfirmation(token).subscribe({
                next:()=>{
                    observer.next();
                },
                error:(response)=>{
                    this.notificationUseCase.showError("ERROR", response.error.message);
                    observer.error(response);
                },
                complete:()=>observer.unsubscribe()
            })
        });
    }
}
