<div class="card-group">
    <div class="col-xl-3 col-md-6 mb-4" [class.col-xl-12]="useClassFull"  *ngFor="let parent of parents">
        <div class="card wlm-border-left-orange shadow h-100 py-2">
          <div class="card-header p-2 d-flex flex-row align-items-center justify-content-between">
            <h6 class="m-0 font-weight-bold text-primary" routerLink="/parent/{{parent.id}}/detail" >{{parent.full_name}}</h6>
            <div class="dropdown no-arrow">
                <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                    aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" routerLink="/parent/{{parent.id}}/edit">Modificar</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" (click)="deleteHandler(parent)">Eliminar</a>
                </div>
            </div>
          </div>
            <div class="card-body" routerLink="/parent/{{parent.id}}/detail">
                <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            {{parent.full_name}}</div>
                        <div class="text-xs mt-1 text-uppercase">
                            {{parent.relation}}</div>
                        <div *ngIf="parent.student_reference != null" routerLink="/student/{{parent.student_reference.id}}/detail" class="text-xs mt-1 text-uppercase wlm-link">
                            <i class="fas fa-fw fa-child"></i>{{parent.student_reference.name}}
                        </div>
                    </div>
                    <div class="col-auto">
                        <i class="fas fa-users fa-3x text-gray-300"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    
