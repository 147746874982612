<div class="card-group">
<div class="m-2 col-xl-3 col-md-6 mb-4" [class.col-xl-12]="useClassFull"  *ngFor="let student of students">
    <div class="card border-left-success shadow h-100 py-2">
      <div class="card-header p-2 d-flex flex-row align-items-center justify-content-between wlm-pointer">
        <h6 class="m-0 font-weight-bold text-primary wlm-pointer" routerLink="/student/{{student.id}}/detail" >{{student.full_name}}</h6>
        <div class="dropdown no-arrow">
            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                aria-labelledby="dropdownMenuLink">
                <a class="dropdown-item" routerLink="/student/{{student.id}}/edit">Modificar</a>
                <a class="dropdown-item" href="" (click)="detachCourseHandler(student)">Quitar de este curso</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="deleteHandler(student)">Eliminar</a>
            </div>
        </div>
      </div>
        <div class="card-body wlm-pointer" routerLink="/student/{{student.id}}/detail">
            <div class="row no-gutters align-items-center">
                <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        {{student.full_name}}</div>
                    <div class="text-xs mt-1 text-success text-uppercase">
                        Activo</div>
                </div>
                <div class="col-auto">
                    <i class="fas fa-child fa-3x text-gray-300"></i>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

