import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { ScheduleModel } from 'src/domain/models/schedule.model';
import { ScheduleRepository } from 'src/domain/repositories/schedule.repository';


@Injectable({
    providedIn: 'root',
})
export class ScheduleGetByCourseUseCase implements UseCase<number, ScheduleModel[]> {

    constructor(private scheduleRepository: ScheduleRepository) { }

    execute(
        courseId: number,
    ): Observable<ScheduleModel[]> {
        return this.scheduleRepository.getScheduleByCourse(courseId);
    }
}
