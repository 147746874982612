import { AfterViewChecked, ChangeDetectorRef, Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AlertMessageModel } from 'src/domain/models/alert-message.model';
import { ModalComponent } from '../modal/modal.component';
import { NotificationService } from 'src/domain/services/notification.service';
import { ModalService } from 'src/domain/services/modal.service';
import { ModalModel } from 'src/domain/models/modal.model';

@Component({
  selector: 'app-container-modals',
  templateUrl: './container-modals.component.html',
  styleUrls: ['./container-modals.component.css']
})
export class ContainerModalsComponent implements OnInit, AfterViewChecked {


  @ViewChild('modals', {read: ViewContainerRef}) modals!: ViewContainerRef;
  
  display:string = 'none';

  constructor(private changeDetector: ChangeDetectorRef,
              private notificationService: NotificationService){

    this.notificationService.NewModalEvent.subscribe((alert)=> this.addModal(alert));
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  detectChanges():void{
    this.changeDetector.detectChanges()
  }
  

  addModal(alert:AlertMessageModel){
   
    let index = this.modals.length;

    const comp = this.modals.createComponent(ModalComponent, {index: index}) as ComponentRef<ModalComponent>;
    comp.instance.index = index;
    comp.instance.title = alert.title;
    comp.instance.message = alert.message;
    comp.instance.typeAlert = alert.typeAlert;
    
    if(alert.typeAlert == 'Question')
      comp.instance.showButtonCancel = true;

    comp.instance.ClickCancelEvent.subscribe(()=>{
      this.checkModels(comp.instance.index);
      this.notificationService.CallBackCancelEvent.emit();
    })
    comp.instance.ClickOkEvent.subscribe(()=>{
      this.checkModels(comp.instance.index);
      this.notificationService.CallBackOkEvent.emit();
    })
    
    if(this.display != 'block')
      setTimeout(()=>this.display = 'block', 200);

    this.changeDetector.detectChanges()
  }

  checkModels(index:number){

    this.modals?.remove(index);

    if(this.modals?.length == 0)
      this.display = 'none'

  }
}

