import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { CourseModel } from 'src/domain/models/course.model';
import { CourseDeleteUseCase } from 'src/domain/usecases/course/course-delete.usercase';
import { CourseGetAllUseCase } from 'src/domain/usecases/course/course-get-all.usercase';
import { CourseGetCouresBySchoolUseCase } from 'src/domain/usecases/course/course-get-coures-by-school.usecase';
import { ReferenceModel } from 'src/domain/models/reference.model';
import { CourseGetCouresByStudentUseCase } from 'src/domain/usecases/course/course-get-coures-by-student.usecase';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';

@Component({
  selector: 'app-course-container',
  templateUrl: './course-container.component.html',
  styleUrls: ['./course-container.component.css']
})
export class CourseContainerComponent implements OnInit {


  @Input() schoolReference:ReferenceModel | undefined;
  @Input() courseId:number | undefined;
  @Input() studentId:number = 0;
  
  canCreate: Boolean = false;
  viewAsTable:Boolean = false;
  loading:Boolean = true;
  courses: CourseModel[] = [];

  constructor(private courseGetAllUseCase:CourseGetAllUseCase,
              private courseDeleteUserCase: CourseDeleteUseCase,
              private contextUseCase:ContextUseCase,
              private courseGetCouresBySchoolUseCase: CourseGetCouresBySchoolUseCase,
              private courseGetCouresByStudentUseCase: CourseGetCouresByStudentUseCase) { }

  ngOnInit(): void {
    this.contextUseCase.hideToggledNav();
    this.loadCoursesHandler(true);

    if(this.schoolReference != undefined)
    this.canCreate = true;
  }

  loadCoursesHandler(forse:Boolean){

    if(this.schoolReference == undefined && this.studentId == 0)
    this.loadCourses(forse);
  else if(this.studentId != 0 && this.schoolReference == undefined)
   this.loadCoursesByStudent(forse);
  else
    this.loadCoursesBySchool(forse);
  }

  deleteHandler(course:CourseModel){
         this.courseDeleteUserCase.execute(course).subscribe({
           complete:()=>{
             this.loadCoursesHandler(true);
           }
         });
  }

  loadCoursesByStudent(force:Boolean):void{
    if(this.studentId != null){
    this.courseGetCouresByStudentUseCase.execute(this.studentId).subscribe({
      next:(courses)=>{
        this.courses = courses;
        this.loading = false;
      },
      error:(error)=>this.loading = false
    })
  }
  }

  loadCoursesBySchool(force:Boolean):void{
    if(this.schoolReference != null){
    this.courseGetCouresBySchoolUseCase.execute(this.schoolReference.id).subscribe({
      next:(courses)=>{
        this.courses = courses;
        this.loading = false;
      },
      error:(error)=>this.loading = false
    })
  }
  }

  loadCourses(force:Boolean):void{
    this.loading = true;

      this.courseGetAllUseCase.execute(force).subscribe({
        next:(courses)=>{
          this.courses = courses;
          this.loading = false;
        },
        error:()=>this.loading = false
      })
  }


}
