    <div class="card-header p-2 d-flex flex-row align-items-center justify-content-between">
    <h6 class="m-0 font-weight-bold text-primary">{{title}}</h6>
    <div class="dropdown no-arrow">
        <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
            aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item">Inactivar</a>
            <a class="dropdown-item">Activar</a>
            <a class="dropdown-item" href="#">Modificar</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="">Ver Alumnos</a>
            <a class="dropdown-item" href="#">Ver Cursos</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="deleteHandler()">Eliminar</a>
        </div>
    </div>
