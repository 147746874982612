import { Component, OnInit } from '@angular/core';
import { BarController, BarElement, CategoryScale, Chart, Decimation, Filler, Legend, Title, Tooltip, registerables } from 'chart.js/auto';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  
  char1!:Chart

  constructor() { 
   // Chart.register(BarElement, BarController, CategoryScale, Decimation, Filler, Legend, Title, Tooltip);

  }

  ngOnInit(): void {
/*
    this.char1 = new Chart('canvas', {
      type: 'bar',
      data: {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [{
          label: '# of Votes',
          data: [12, 19, 3, 5, 2, 3],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
    */
  }

}
