import { Injectable } from '@angular/core';
import { BaseImplementationRepository } from './base-implementation.repository';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProvinceRepository } from 'src/domain/repositories/province.repository';
import { ProvinceModel } from 'src/domain/models/province.model';


@Injectable({
    providedIn: 'root',
})
export class ProvinceImplementationRepository extends BaseImplementationRepository<ProvinceModel> implements ProvinceRepository {
    

    constructor(http:HttpClient)
    {
        super(http);
    }
    
    
    override getEntityName(): string {
       return 'province';
    }
    
    override http!:HttpClient;

    override getAll(force:Boolean):Observable<ProvinceModel[]>{
        return  this.http.get<ProvinceModel[]>('/api/provinces');
    }
}
