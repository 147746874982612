<app-map-modal *ngIf="showMap" [currentAddress]="currentAddress" (callBackOkEvent)="onSelectHandler($event)" (callBackCancelEvent)="onCancelMapHandler()"></app-map-modal>
<div class="input-group mb-3">
    <div class="form-floating flex-grow-1">
        <input id="addresstext" #addresstext class="form-control" type="text" [(ngModel)]="addressText" (blur)="blurHandler()"  [class.is-invalid]="!isValid && isChanged">
        <label for="addresstext" class="form-floating-required">{{label}}</label>
    </div>
    <button type="button" (click)="openMapHandler()" class="input-group-text" [class.is-invalid]="!isValid && isChanged">
        <i class="fas fa-map-location"></i>
    </button>
</div>
<div *ngIf="!isValid && isChanged" style="color: red;">
    debe completar este campo
</div>

