import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CourseModel } from 'src/domain/models/course.model';
import { ScheduleModel } from 'src/domain/models/schedule.model';
import { ReferenceModel } from 'src/domain/models/reference.model';
import { ToastTypes } from 'src/domain/models/toast-types';
import { CourseGetByIdUseCase } from 'src/domain/usecases/course/course-get-by-id.usercase';
import { CourseUpdateUseCase } from 'src/domain/usecases/course/course-update.usercase';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { CalendarElementUpdateUseCase } from 'src/domain/usecases/calendar-element/calendar-update.usecase';
import { Location } from '@angular/common'

@Component({
  selector: 'app-course-edit',
  templateUrl: './course-edit.component.html',
  styleUrls: ['./course-edit.component.css']
})
export class CourseEditComponent implements OnInit, OnDestroy  
 {

  id!: number;
  course!: CourseModel;
  form: FormGroup;
  name: FormControl = new FormControl('', Validators.required);
  date_from: FormControl = new FormControl('');
  date_to: FormControl = new FormControl('');
  description: FormControl = new FormControl('');
  section: FormControl = new FormControl('');
  schoolId: FormControl = new FormControl('');
  background_color:FormControl = new FormControl('', Validators.required);
  queryCourseHandler!:Subscription;
  updateCourseHandler!:Subscription;
  schedules:ScheduleModel[] = [];
  setSchedules = new EventEmitter<ScheduleModel[]>();
  validateDaySchedulesEvent = new EventEmitter<void>();
  schedulesValid:Boolean = false;
  validateSchool:EventEmitter<void> = new EventEmitter<void>();
  isSchoolValid:Boolean = false;
  schoolSet:EventEmitter<ReferenceModel> = new EventEmitter<ReferenceModel>();

  constructor(private activatedRoute:ActivatedRoute,
              private formBuilder: FormBuilder,
              private context:ContextUseCase,
              private location: Location,
              private changeDetector: ChangeDetectorRef,
              private courseGetByIdUseCase: CourseGetByIdUseCase,
              private courseUpdateUseCase:CourseUpdateUseCase,
              private calendarElementUpdateUseCase:CalendarElementUpdateUseCase,
              private contextUseCase: ContextUseCase) { 

                this.form = formBuilder.group({
                  id: this.id,
                  name: this.name,
                  section: this.section,
                  school_id: this.schoolId,
                  description: this.description,
                  date_from: this.date_from,
                  date_to: this.date_to,
                  background_color: this.background_color
                });
              }

  ngOnDestroy(): void {
    this.queryCourseHandler?.unsubscribe();
    this.updateCourseHandler?.unsubscribe();
  }

  ngOnInit(): void {
    this.contextUseCase.hideToggledNav();
    this.context.showSpinner();
    this.id = this.activatedRoute.snapshot.params['courseid'];

    this.queryCourseHandler = this.courseGetByIdUseCase.execute(this.id).subscribe({     
      next:(course)=>{
         this.course = course;
         this.form.controls['id'].setValue(this.id); 
         this.form.controls['name'].setValue(this.course?.name); 
         this.form.controls['section'].setValue(this.course?.section);
         this.form.controls['school_id'].setValue(this.course?.school_id);
         this.form.controls['description'].setValue(this.course?.description);
         this.form.controls['date_from'].setValue(this.course?.date_from);
         this.form.controls['date_to'].setValue(this.course?.date_to);
         this.form.controls['background_color'].setValue(this.course?.background_color);
         

         setTimeout(()=>{
            this.schoolSet.emit(new ReferenceModel(this.course.school_reference.id, this.course.school_reference.name));
            this.schedules = course.schedules;
            this.setSchedules.emit(this.schedules);

            if(this.schedules == undefined)
              this.schedules = [];

            this.changeDetector.detectChanges();
            this.context.hideSpinner();
         },500);
      }
    })
  }

  scheduleChanged(schedules:ScheduleModel[]){
     this.schedules = schedules;
  }


  schedulesValidChange(value:Boolean){
        this.schedulesValid = value;
  }

  cancel() {
    this.location.back();
  }


  getSchoolSelect(schoolReference:ReferenceModel | undefined){

    if(schoolReference == undefined)
      this.form.controls['school_id'].setValue('');
    else 
      this.form.controls['school_id'].setValue(schoolReference.id); 
  }

  schoolChangeState(state:Boolean){
    this.isSchoolValid = state;
  }  

  saveHandler(): void{

    if(this.form.invalid){
      this.form.markAllAsTouched();
      return;
    }

    this.validateSchool.emit();

    if( this.isSchoolValid == false)
             return;


    this.validateDaySchedulesEvent.emit();

    if(this.schedulesValid == false)
             return;

    this.context.showSpinner();

    let course = this.form.value;
    course.schedules = this.schedules;

    this.updateCourseHandler = this.courseUpdateUseCase.execute(course).subscribe(
      {
        next:(response) =>{
          this.calendarElementUpdateUseCase.execute(course).subscribe(()=>{
            this.context.hideSpinner();
            this.context.addToast("registro guardado", "el curso se actualizo con exito!!!", ToastTypes.Success);
            this.location.back();
          });
        },
        error: (error) =>{
          this.context.hideSpinner();
        }
      });
    }



 }
 