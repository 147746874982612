<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="">Alumnos</a>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
      </ul>
    </div>
    <div class="btn-group" role="group" aria-label="">
      <button type="button" *ngIf="this.canCreate" title="crear nuevo estudiante" routerLink="/school/{{schoolId}}/course/{{courseId}}/student/create" class="btn btn-primary"> <i class="fab fa fa-plus"></i></button>
      <button type="button" class="btn btn-primary shadow-none" (click)="viewAsTable = !viewAsTable" ><i class="fa fa-table"></i></button>
      <button type="button" class="btn btn-primary shadow-none" (click)="viewAsTable = !viewAsTable" ><i class="fa fa-address-card"></i></button>
      <button type="button" *ngIf="this.canImport" data-bs-toggle="tooltip" data-bs-placement="top" title="Importar alumnos" routerLink="/student/import" class="btn btn-primary shadow-none" ><i class="fas fa-file-import"></i></button>
      <button type="button" class="btn btn-primary shadow-none" (click)="loadRecords(true)" ><i class="fas fa-rotate"></i></button>
    </div>
  </nav>
  <app-spinner-mini *ngIf="loading"></app-spinner-mini>
  <h6 *ngIf="notRecords" class="mt-5 mb-5 text-center">No hay registros</h6>
<ng-container *ngIf="!viewAsTable">
    <app-student-list [students]="students" (deleteEvent)="deleteHandler($event)" (detachCourseEvent)="detachCourseHandler($event)"></app-student-list>
</ng-container>
<ng-container *ngIf="viewAsTable">
    <app-student-table [students]="students" (deleteEvent)="deleteHandler($event)"></app-student-table>
</ng-container>
