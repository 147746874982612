import { tileLayer } from "leaflet";
import { TEntity } from "./base.model"

export class AddressModel implements TEntity {

    constructor(lat:number, lon:number,
        display_name:string, title:string,
        target:any, id:number){
   
    this.lat = lat;
    this.lon = lon;
    this.display_name = display_name;
    this.title = title;
    this.target = target;
    this.id = id;

    }



    lat:number
    lon:number
    display_name:string
    title:string
    target:any
    id:number
 }