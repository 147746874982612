import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { CourseModel } from 'src/domain/models/course.model';
import { CourseRepository } from 'src/domain/repositories/course.repository';

@Injectable({
    providedIn: 'root',
})
export class CourseGetByIdUseCase implements UseCase<number, CourseModel> {

    constructor(private courseRepository: CourseRepository) { }

    execute(
        courseId: number,
    ): Observable<CourseModel> {
        return this.courseRepository.getById(courseId);
    }
}
