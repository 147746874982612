import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserGetProfileUseCase } from 'src/domain/usecases/user/user-get-profile.usecase';
import { UserLogoutUseCase } from 'src/domain/usecases/user/user-logout.usecase';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { GlobalSearchUseCase } from 'src/domain/usecases/common/global-search.usecase';
import { NavService } from 'src/domain/services/nav.service';
import { NotificationUseCase } from 'src/domain/usecases/common/notification.usecase';

@Component({
  selector: '[app-nav-top]',
  templateUrl: './nav-top.component.html',
  styleUrls: ['./nav-top.component.css'],
  providers:[UserLogoutUseCase,
             UserGetProfileUseCase,
             GlobalSearchUseCase
  ]
})
export class NavTopComponent implements OnInit {

  name:string = '';
  textSearch!:string;
  showSearchDropdown:Boolean = false;
  userPhoto:string = 'presentation/assets/imgs/undraw_profile.svg';

  constructor(private router: Router, 
              private contextUseCase:ContextUseCase,
              private userGetProfileUseCase:UserGetProfileUseCase,
              private userLogoutUseCase: UserLogoutUseCase,
              private globalSearchUseCase: GlobalSearchUseCase,
              private navService:NavService,
              private notificationUseCase: NotificationUseCase) {
               
               }

  ngOnInit(): void {
    this.userGetProfileUseCase.execute().subscribe((profile)=>{
        this.name = profile.name;
        this.userPhoto = profile.photo;
    });
  }

  toggledHander(){
    this.navService.ToggledNavEvent.emit();
  }

  logoutHandler(){
    this.userLogoutUseCase.execute().subscribe(()=>{
        this.router.navigate(['/login']);
    })
   
  }

  searchHandler(){
     
    if(this.textSearch == null || this.textSearch.length == 0)
      return;

     this.contextUseCase.showSpinner();

     this.globalSearchUseCase.search(this.textSearch).subscribe({
       next:()=>{
          this.showSearchDropdown = false;
          this.textSearch = '';
          this.router.navigate(['/search']);
       },
       error:(error)=>{
        this.contextUseCase.hideSpinner();
        this.notificationUseCase.showError('Error', error.message);
       }
     });
    
  }

}
