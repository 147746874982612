<div class="container-fluid">
    <div class="card shadow mb-2">
        <div class="card-header py-3">
            <div class="row">
                <i class="fas fa-users fa-3x wlm-detail-title-icon wlm-text-orange" ></i>
                <h4 class="wlm-detail-title-text wlm-text-orange">PADRE / TUTOR</h4>
            </div>
            <p>Nombre:      <b>{{parent.full_name}}</b></p>
            <p>Telefono:    <b>{{parent.telephone_number}}</b></p>
            <p>Email:       <b>{{parent.email}}</b></p>
            <p>DNI:         <b>{{parent.dni}}</b></p>
            <p>Vinculo:     <b>{{parent.relation}}</b></p>
            <p>Descripcion: <b>{{parent.description}}</b></p>
            <p>Estudiente:  <b routerLink="/student/{{parent.student_reference.id}}/detail" class="wlm-link"><i class="fas fa-fw fa-child"></i>{{parent.student_reference.name}}</b></p>
        <div class="row">
            <div class="btn-group btn-group-sm justify-content-md-end shadow-none" role="group" aria-label="">
                <button type="button" 
                    (click)="back()" 
                    class="btn btn-outline-primary  wlm-button-detail"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    title="Volver">
                    <i class="fa fa-lg fa-arrow-left mr-2 pt-1"></i>
                    <span class="d-none d-sm-block">Volver</span>
                </button>
                <button type="button" 
                    routerLink="/parent/{{parentId}}/edit" 
                    class="btn btn-outline-primary shadow-none wlm-button-detail"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    title="Editar Padre / Tutor">
                    <i class="fas fa-lg fa-pen-to-square mr-2 pt-1 text-warning"></i>
                    <span class="d-none d-sm-block">Editar Padre / Tutor</span>
                </button>
                <button type="button" 
                    (click)="deleteHandler()" 
                    class="btn btn-outline-primary shadow-none  wlm-button-detail"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="top" 
                    title="Eliminar Padre / Tutor">
                    <i class="fa fa-lg fa-regular fa-trash mr-2 pt-1 text-danger"></i>
                    <span class="d-none d-sm-block">Eliminar Padre / Tutor</span>
                </button>
            </div>
        </div>
        <div class="card-body p-0">
        </div>
    </div>

</div>
