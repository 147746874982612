import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { UserModel } from '../../models/user.model';
import { UserRepository } from '../../repositories/user.repository';
import { Injectable } from '@angular/core';
import { NotificationUseCase } from '../common/notification.usecase';

@Injectable({
    providedIn: 'root',
})
export class UserRecoverPasswordSendUseCase implements UseCase<UserModel, void> {

    constructor(private userRepository: UserRepository,
                private notificationUseCase:NotificationUseCase) { }

    execute(
       user: UserModel,
    ): Observable<void> {
      return this.userRepository.resetPasswordSend(user);
    }
}
