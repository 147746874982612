import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { NotificationUseCase } from 'src/domain/usecases/common/notification.usecase';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  

  constructor(private notificationUseCase:NotificationUseCase,
              private contextUseCase: ContextUseCase){

  }

  handleError(exception:any) {

    if (exception instanceof HttpErrorResponse) {
      // Server Error
      this.contextUseCase.hideSpinner();
      if(exception.error.message)
        this.notificationUseCase.showError('Error', exception.error.message);
      else if(exception.error.error.message)
        this.notificationUseCase.showError('Error', exception.error.error.message);
      else
        this.notificationUseCase.showError('Error', exception.error);

      try{console.error(exception.error.error);}catch(err){}
      
  } else {
      // Client Error
      this.contextUseCase.hideSpinner();
      this.notificationUseCase.showError('Error', exception.message);
      try{console.error(exception);}catch(err){}
  }
  
  

  }
}