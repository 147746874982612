import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateGuard } from './can-activate.guard';
import { PageNotFoundComponent } from './common/page.not.found/page.not.found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './user/login/login.component';
import { SchoolCreateComponent } from './school/school-create/school-create.component';
import { SchoolEditComponent } from './school/school-edit/school-edit.component';
import { StudentCreateComponent } from './student/student-create/student-create.component';
import { RegisterComponent } from './user/register/register.component';
import { CourseCreateComponent } from './course/course-create/course-create.component';
import { SchoolDetailComponent } from './school/school-detail/school-detail.component';
import { CourseDetailComponent } from './course/course-detail/course-detail.component';
import { CourseEditComponent } from './course/course-edit/course-edit.component';
import { StudentContainerComponent } from './student/student-container/student-container.component';
import { CourseContainerComponent } from './course/course-container/course-container.component';
import { SchoolContainerComponent } from './school/school-container/school-container.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { StudentDetailComponent } from './student/student-detail/student-detail.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ScheduleContainerComponent } from './schedule/schedule-container/schedule-container.component';
import { ScheduleEditComponent } from './schedule/schedule-edit/schedule-edit.component';
import { ScheduleDetailComponent } from './schedule/schedule-detail/schedule-detail.component';
import { StudentEditComponent } from './student/student-edit/student-edit.component';
import { MapComponent } from './map/map.component';
import { AutocompleteAddressComponent } from './common/autocomplete-address/autocomplete-address.component';
import { StudentImportComponent } from './student/student-import/student-import.component';
import { ParentContainerComponent } from './parent/parent-container/parent-container.component';
import { ParentDetailComponent } from './parent/parent-detail/parent-detail.component';
import { ParentEditComponent } from './parent/parent-edit/parent-edit.component';
import { ParentCreateComponent } from './parent/parent-create/parent-create.component';
import { ProfileComponent } from './user/profile/profile.component';
import { PasswordChangeComponent } from './user/password-change/password-change.component';
import { SubscriptionDetailComponent } from './subscription/subscription-detail/subscription-detail.component';
import { RecoverPasswordComponent } from './user/recover-password/recover-password.component';
import { EmailConfirmationComponent } from './user/email-confirmation/email-confirmation.component';
import { RecoverPasswordSendComponent } from './user/recover-password-send/recover-password-send.component';
import { CanActivateAdminGuard } from './can-activate-admin.guard';
import { SubscriberListComponent } from './subscriber/subscriber-list/subscriber-list.component';
import { SubscriberDetailComponent } from './subscriber/subscriber-detail/subscriber-detail.component';



const routes: Routes = [
  {path:'login', component: LoginComponent},
  {path:'register', component: RegisterComponent},
  {path:'recover/:token', component: RecoverPasswordComponent},
  {path:'recover', component: RecoverPasswordSendComponent},
  {path:'confirmation/:token', component: EmailConfirmationComponent},
  {path:'admin', component: HomeComponent, canActivate: [CanActivateAdminGuard],
  children: [
    {path:'subscriber', component: SubscriberListComponent},
    {path:'subscriber/:id/detail', component:SubscriberDetailComponent},
    {path:'subscriber/:id/edit', component:SubscriberDetailComponent},
  ]},
  {path:'', component: HomeComponent, canActivate: [CanActivateGuard],
   children: [
    {path:'', component: SchoolContainerComponent},
    {path:'schools', component: SchoolContainerComponent},
    {path:'dashboard', component: DashboardComponent},

    {path:'subscription', component: SubscriptionDetailComponent},

    {path:'search', component: SearchResultComponent},

    {path:'profile', component: ProfileComponent},
    {path:'passwordchange',  component:PasswordChangeComponent},

    {path:'school/:schoolid/:schoolname/student/:studentId/course/create', component: CourseCreateComponent},
    {path:'school/:schoolid/course/:courseid/detail', component: CourseDetailComponent},
    {path:'school/create', component: SchoolCreateComponent},
    {path:'school/:id/:name/detail', component: SchoolDetailComponent},
    {path:'school/:schoolid/course/:courseid/student/create', component: StudentCreateComponent},
    {path:'school/:id/:name/edit', component: SchoolEditComponent},
    {path:'courses', component: CourseContainerComponent},
    {path:'course/:schoolid/student/create', component: StudentCreateComponent},
    {path:'school/course/student/create', component: StudentCreateComponent},
    {path:'school/course/create', component: CourseCreateComponent},
    {path:'course/:courseid/detail', component: CourseDetailComponent},
    {path:'course/:schoolid/course/:courseid/student/create', component: StudentCreateComponent},
    {path:'course/:courseid/edit', component: CourseEditComponent},

    {path:'schuedule/course/:id', component: ScheduleContainerComponent},
    {path:'schedule/:id/detail', component: ScheduleDetailComponent},
    {path:'schedule/:id/edit', component: ScheduleEditComponent},   

    {path:'students', component: StudentContainerComponent},
    {path:'student/school/:id', component: StudentContainerComponent},
    {path:'student/:id/detail', component: StudentDetailComponent},
    {path:'student/:id/edit', component: StudentEditComponent},
    {path:'student/import', component:StudentImportComponent},

    {path:'parents', component: ParentContainerComponent},
    {path:'parent/:id/detail', component: ParentDetailComponent},
    {path:'parent/:id/edit', component: ParentEditComponent},
    {path:'student/:studentid/parent/create', component: ParentCreateComponent},

    {path:'calendar', component: CalendarComponent},
   
    {path:'map', component: MapComponent},

    {path:'address', component: AutocompleteAddressComponent},

    {path:'**', component:PageNotFoundComponent}]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
