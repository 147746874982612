<div class="card shadow mb-4">
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th class="text-center small">Id</th>
                        <th class="text-center small">Nombre</th>
                        <th class="text-center small">Email</th>
                        <th class="text-center small">Telefono</th>
                        <th class="text-center small">Estado</th>
                        <th class="text-center small" style="width: 40px;" ></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let subscriber of subscribers;">
                        <td class="small p-1 text-center">{{subscriber.id}}</td>
                        <td class="small p-1">{{subscriber.name}}</td>
                        <td class="small p-1">{{subscriber.email}}</td>
                        <td class="small p-1">{{subscriber.teacher.telephone_number}}</td>
                        <td class="small p-1">{{getUserState(subscriber)}}</td>
                        <td routerLink="/admin/subscriber/{{subscriber.id}}/detail" class="wlm-link text-center" style="width: 40px;" title="abrir registro"><i class="fas fa-eye"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

