import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { UserModel } from '../../models/user.model';
import { UserRepository } from '../../repositories/user.repository';
import { Injectable } from '@angular/core';
import { UserSessionModel } from 'src/domain/models/user-session-model';
import { LocalStorageService } from 'src/domain/services/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class UserLoginUseCase implements UseCase<UserModel, UserSessionModel> {

    constructor(private userRepository: UserRepository,
                private localStoredService: LocalStorageService) { }

    execute(
       user: UserModel,
    ): Observable<UserSessionModel> {
        return new Observable<UserSessionModel>((observer) => {
         this.userRepository.login(user).subscribe({
            next:(session)=>{
                this.localStoredService.save('SESSION', session)
                observer.next(session);
            },
            error:(error)=>{
                observer.error(error);
            }
         })

        });
    }
}
