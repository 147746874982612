import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ParentModel } from 'src/domain/models/parent.model';

@Component({
  selector: 'app-parent-table',
  templateUrl: './parent-table.component.html',
  styleUrls: ['./parent-table.component.css']
})
export class ParentTableComponent {
  @Input() parents: ParentModel[] = [];

  @Output() deleteEvent = new EventEmitter<ParentModel>();


  constructor() { 

    }

  ngOnInit(): void {
   
  }

  deleteHandler(parentTutor:ParentModel){
    this.deleteEvent.emit(parentTutor);
  }
}
