<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="">Cursos</a>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
      </ul>
    </div>
    <div class="btn-group" role="group" aria-label="">
      <button type="button"  *ngIf="this.canCreate" routerLink="/school/{{schoolReference?.id}}/{{schoolReference?.name}}/student/{{studentId}}/course/create" class="btn btn-primary"> <i class="fab fa fa-plus"></i></button>
      <button type="button" class="btn btn-primary shadow-none" (click)="viewAsTable = !viewAsTable" ><i class="fa fa-table"></i></button>
      <button type="button" class="btn btn-primary shadow-none" (click)="viewAsTable = !viewAsTable" ><i class="fa fa-address-card"></i></button>
      <button type="button" class="btn btn-primary shadow-none" (click)="loadCoursesHandler(true)" ><i class="fas fa-rotate"></i></button>
    </div>
  </nav>
  <app-spinner-mini *ngIf="loading"></app-spinner-mini>
  <h6 *ngIf="courses.length == 0" class="mt-5 mb-5 text-center">No hay registros</h6>
<ng-container *ngIf="!viewAsTable">
    <app-course-list [courses]="courses" (deleteEvent)="deleteHandler($event)"></app-course-list>
</ng-container>
<ng-container *ngIf="viewAsTable">
    <app-course-table [courses]="courses" (deleteEvent)="deleteHandler($event)"></app-course-table>
</ng-container>
