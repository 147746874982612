<div class="col">
    <div class="text-center">
        <h1 class="h4 text-gray-900 mb-4">Perfil de Usuario</h1>
    </div>
    <form [formGroup]="form" (submit)="saveHandler()" class="user">
      <div class="row text-center">
        <span class="account-user-avatar">
          <img [src]="photo" alt="user-image" style="width: 140px; max-width: 140px; height: 140px; max-height: 140px;" class="border mb-2 img-thumbnail wlm-pointer rounded-circle" (click)="selectImage.click()"/>
          <input id="photo" type="file" (change)="updatePhotoHandler($event)" style="display: none" #selectImage>
      </span>
      </div>
        <div class="form-floating mb-2">
          <input id="name" autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Nombre"
          formControlName="name" [class.is-invalid]="!name.valid && name.touched">
          <div *ngIf="name.errors?.['required']" class="invalid-feedback">
            debe completar este campo
          </div>
          <label for="name" class="form-floating-required">Nombre</label>
        </div>
              <div class="form-floating mb-2">
                <input id="email" autocomplete="off" type="email" [disabled]="true" class="form-control form-control-sm"
                    placeholder="Email" formControlName="email">
                    <div *ngIf="email.errors?.['required']" class="invalid-feedback">
                      debe completar este campo
                    </div>
                    <div *ngIf="email.errors?.['email']" class="invalid-feedback">
                      el correo electronico es invalido
                    </div>
                    <label for="email" class="form-floating-required">Email</label>
              </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
             <button type="submit" class="col btn btn-primary">
            Guardar
        </button>
        <button type="button" (click)="cancel()" class="col btn btn-secondary">
          Cancelar
      </button>
      </div>
        <hr>
    </form>
</div>


