import { EventEmitter, Injectable } from '@angular/core';
import { ToastElement } from 'src/domain/models/toast-element';
import { ToastTypes } from 'src/domain/models/toast-types';
import { ToastService } from 'src/domain/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class ToastImplementationService implements ToastService {
    
  
  
    addToastWithLink(title: string, message: string, type: ToastTypes, routerUrl: string, routerName:string): void {
      let element = new ToastElement();
      element.title = title;
      element.message = message;
      element.type = type;
      element.routerLink = routerUrl;
      element.routerName = routerName;
      this.ShowToastEvent.emit(element);
    }

    addToast(title: string, message:string, type:ToastTypes) {
      let element = new ToastElement();
      element.title = title;
      element.message = message;
      element.type = type;

      this.ShowToastEvent.emit(element);
    }


    public ShowToastEvent = new EventEmitter<ToastElement>();


  }