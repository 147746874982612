import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StudentModel } from 'src/domain/models/student.model';

@Component({
  selector: 'app-student-table',
  templateUrl: './student-table.component.html',
  styleUrls: ['./student-table.component.css']
})
export class StudentTableComponent implements OnInit {

  @Input() students: StudentModel[] = [];

  @Output() deleteEvent = new EventEmitter<StudentModel>();


  constructor() { 

    }

  ngOnInit(): void {
   
  }

  deleteHandler(student:StudentModel){
    this.deleteEvent.emit(student);
  }

}
