<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="">Instituciones</a>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
      </ul>
    </div>
    <div class="btn-group" role="group" aria-label="">
      <button type="button" routerLink="/school/create" class="btn btn-primary"><i class="fab fa fa-plus"></i></button>
      <button type="button" class="btn btn-primary shadow-none" (click)="setViewType(2)" ><i class="fas fa-map-location"></i></button>
      <button type="button" class="btn btn-primary shadow-none" (click)="setViewType(0)" ><i class="fa fa-table"></i></button>
      <button type="button" class="btn btn-primary shadow-none" (click)="setViewType(1)" ><i class="fa fa-address-card"></i></button>     
      
      <button type="button" class="btn btn-primary" (click)="loadSchoolsHandler(true)" ><i class="fas fa-rotate"></i></button>
    </div>
  </nav>
 <app-spinner-mini *ngIf="loading"></app-spinner-mini>
 <h6 *ngIf="notRecords" class="mt-5 mb-5 text-center">No hay registros</h6>
 <ng-container *ngIf="viewAs === viewAsCart">
    <app-school-list [schools]="schools" (deleteEvent)="deleteHandler($event)"></app-school-list>
</ng-container>
<ng-container *ngIf="viewAs === viewAsTable">
    <app-school-table [schools]="schools" (deleteEvent)="deleteHandler($event)"></app-school-table>
</ng-container>
<ng-container *ngIf="viewAs === viewAsMap">
  <div class="mb-5">
    <app-map [enabled]="false" [addressPoints]="schoolsPoints"></app-map>
  </div>  
</ng-container>
