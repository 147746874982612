import { Component, EventEmitter, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ScheduleItemComponent } from 'src/presentation/app/schedule/schedule-item/schedule-item.component';
import { ReferenceModel } from 'src/domain/models/reference.model';
import { CourseCreateUseCase } from 'src/domain/usecases/course/course-create.usercase';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { ScheduleModel } from 'src/domain/models/schedule.model';
import { NotificationUseCase } from 'src/domain/usecases/common/notification.usecase';
import { Location} from '@angular/common'

@Component({
  selector: 'app-course-create',
  templateUrl: './course-create.component.html',
  styleUrls: ['./course-create.component.css']
})
export class CourseCreateComponent implements OnInit {

  queryCourseHandler!:Subscription;
  daysInstances:ScheduleItemComponent[] = []
  dayLength:number = 0;
  itemsValid:Boolean = true;
  form: FormGroup;
  name: FormControl = new FormControl('', Validators.required);
  date_from: FormControl = new FormControl('', Validators.required);
  date_to: FormControl = new FormControl('', Validators.required);
  description: FormControl = new FormControl('');
  section: FormControl = new FormControl('', Validators.required);
  background_color:FormControl = new FormControl('', Validators.required);
  schoolReference!: ReferenceModel;
  school_id: number = 0;
  school_name: string = '';
  schoolId: FormControl = new FormControl('');
  validateSchool:EventEmitter<void> = new EventEmitter<void>();
  isSchoolValid:Boolean = false;
  schoolSet:EventEmitter<ReferenceModel> = new EventEmitter<ReferenceModel>();
  schedules:ScheduleModel[] = [];
  setSchedules = new EventEmitter<ScheduleModel[]>();
  validateDaySchedulesEvent = new EventEmitter<void>();
  schedulesValid:Boolean = false;

  constructor(private router:Router, 
              private formBuilder: FormBuilder,
              private contextUseCase:ContextUseCase,
              private activatedRoute:ActivatedRoute,
              private location:Location,
              private courseCreateUseCase: CourseCreateUseCase,
              private notificationUseCase:NotificationUseCase) { 

                this.form = formBuilder.group({
                  name: this.name,
                  section: this.section,
                  school_id: this.schoolId,
                  description: this.description,
                  date_from: this.date_from,
                  date_to: this.date_to,
                  background_color: this.background_color
                });

              }

  ngOnInit(): void {
    this.contextUseCase.hideToggledNav();
    this.school_id = this.activatedRoute.snapshot.params['schoolid'];
    this.school_name = this.activatedRoute.snapshot.params['schoolname'];
    this.schoolId.setValue(this.school_id);

    var randomColor = Math.floor(Math.random()*16777215).toString(16);

    this.background_color.setValue('#' + randomColor);

    this.schoolReference = new ReferenceModel(this.school_id, this.school_name);
    setTimeout(()=> this.schoolSet.emit(this.schoolReference),500);  
     
  }


  getSchoolSelect(schoolReference:ReferenceModel | undefined){

    if(schoolReference == undefined)
      this.form.controls['school_id'].setValue(null);
    else 
      this.form.controls['school_id'].setValue(schoolReference.id); 
  }

  schoolChangeState(state:Boolean){
    this.isSchoolValid = state;
  }  


  cancel() {
    this.location.back();
  }


  saveHandler(): void{

    if(this.form.invalid){
      this.form.markAllAsTouched();
      return;
    }

    this.validateSchool.emit();
    this.validateDaySchedulesEvent.emit();

    if(this.isSchoolValid == false)
        return;

    if(this.schedules.length == 0)
    {
      this.notificationUseCase.showError('Dias de Clases', 'Debe agregar al menos un dia de clase.');
      return;
    }

    if(this.schedulesValid == false)
        return;

    let course = this.form.value;
    course.schedules = this.schedules;

    this.courseCreateUseCase.execute(course).subscribe();

 
   }
 
   schoolSelectHandler(id:any){
    this.schoolId.setValue(id);
   }

  scheduleChanged(schedules:ScheduleModel[]){
    this.schedules = schedules;
  }


  schedulesValidChange(value:Boolean){
    this.schedulesValid = value;
  }


 }
 