import { Injectable } from '@angular/core';
import { BaseImplementationRepository } from './base-implementation.repository';
import { CourseModel } from 'src/domain/models/course.model';
import { CourseRepository } from 'src/domain/repositories/course.repository';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class CourseImplementationRepository extends BaseImplementationRepository<CourseModel> implements CourseRepository {
    

    constructor(http:HttpClient)
    {
        super(http);
    }    
    
    override getEntityName(): string {
       return 'course';
    }
    
    override http!:HttpClient;

    getCouresBySchool(schoolId:number):Observable<CourseModel[]>{
        return  this.http.get<CourseModel[]>('/api/school/'+ schoolId +'/courses');
    }

    getCouresByStudent(studentId: number): Observable<CourseModel[]> {
        return  this.http.get<CourseModel[]>('/api/student/'+ studentId +'/courses');
    }
}
