import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { ParentModel } from 'src/domain/models/parent.model';
import { ContextUseCase } from '../common/context.usecase';
import { ToastTypes } from 'src/domain/models/toast-types';
import { Location }from '@angular/common'
import { ParentRepository } from 'src/domain/repositories/parent.repository';


@Injectable({
    providedIn: 'root',
})
export class ParentUpdateUseCase implements UseCase<ParentModel, ParentModel> {

    constructor(private parentRepository: ParentRepository,
                private contextUseCase:ContextUseCase,
                private location:Location ){ }

    execute(
        student: ParentModel,
    ): Observable<ParentModel> {
            this.contextUseCase.showSpinner();
            let subscribe = this.parentRepository.update(student);
            
            subscribe.subscribe(
                {
                  next:(response) =>{
                   this.contextUseCase.hideSpinner();
                    this.contextUseCase.addToast("padre / tutor guardado", "el padre / tutor se guardo con exito!!!", ToastTypes.Success);
                    this.location.back();
                  },
                  error: (error) =>{
                   this.contextUseCase.hideSpinner();
                  }
                });

            return subscribe;
        }
}
