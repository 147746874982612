<div class="col">
  <div class="text-center">
      <h1 class="h4 text-gray-900 mb-4">Datos de Suscripcion</h1>
  </div>
<form [formGroup]="form"  class="user">
  <div class="form-floating mb-2">
    <input id="description" autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Plan" formControlName="description">
        <label for="description" class="form-floating-required">Plan</label>
</div>
    <div class="row g-2 mb-1">
      <div class="col-md">
        <div class="form-floating mb-2">
          <input id="date_from" autocomplete="off"  type="date" class="form-control form-control-sm" placeholder="Fecha Vigencia Desde" formControlName="date_from">
          <label for="date_from" class="form-floating-required">Fecha Vigencia Desde</label>
        </div>
      </div>
      <div class="col-md">
        <div class="form-floating mb-2">
          <input id="date_to" autocomplete="off" type="date"  class="form-control form-control-sm" formControlName="date_to">
          <label for="date_to" class="form-floating-required">Fecha Vigencia Hasta</label>
        </div>
      </div>
    </div>
    <div class="form-floating mb-2">
      <input id="price" autocomplete="off" type="number"  class="form-control form-control-sm" formControlName="price" placeholder="Importe" >
      <label for="price" class="form-floating-required">Importe</label>
    </div>
    <div class="form-floating mb-2">
      <select id="state" autocomplete="off" class="form-control form-control-sm"  formControlName="state" placeholder="Estado">
        <option value="0">Pendiente Pago</option>
        <option value="1">Activa</option>
        <option value="2">Vencida</option>
        <option value="3">Cancelada</option>
      </select>
      <label for="state">Estado</label>
    </div>  
    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button type="button" (click)="cancel()" class="col btn btn-secondary">
      Cerrar
  </button>
  </div>
    <hr>
</form>
</div>

