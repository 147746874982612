import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastTypes } from 'src/domain/models/toast-types';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { Location } from '@angular/common';
import { ParentCreateUseCase } from 'src/domain/usecases/parent/parent-create.usecase';

@Component({
  selector: 'app-parent-create',
  templateUrl: './parent-create.component.html',
  styleUrls: ['./parent-create.component.css']
})
export class ParentCreateComponent {
    form: FormGroup;
    full_name: FormControl = new FormControl('', Validators.required);
    email: FormControl = new FormControl('', [Validators.email]);
    full_address: FormControl = new FormControl('');
    telephone_number: FormControl = new FormControl('');
    student_id: FormControl = new FormControl('',  Validators.required);
    description: FormControl = new FormControl('');
    dni: FormControl = new FormControl('');
    relation: FormControl = new FormControl('',  Validators.required);

  
  
    constructor(private formBuilder: FormBuilder,
                private activatedRoute:ActivatedRoute,
                private location:Location,
                private router: Router,
                private parentCreateUseCase:ParentCreateUseCase,
                private contextUseCase:ContextUseCase) { 
  
                  this.form = formBuilder.group({
                    full_name: this.full_name,
                    email: this.email,
                    telephone_number: this.telephone_number,
                    student_id: this.student_id,
                    description: this.description,
                    dni: this.dni,
                    relation: this.relation
                  });
                }
  
    ngOnInit(): void {
      this.contextUseCase.hideToggledNav();
      this.activatedRoute.paramMap.subscribe(params => {
        this.student_id.setValue(params.get('studentid'));
      });
    }
  
    cancel(){
      this.location.back();
    }
  
    
    saveHandler(): void{
  
      if(this.form.invalid){
        this.form.markAllAsTouched();
        return;
      }
  
      this.contextUseCase.showSpinner();
      this.parentCreateUseCase.execute(this.form.value).subscribe({
        next:(parent)=>{
          this.contextUseCase.addToast("padre / tutor Creado", "el padre / tutor se creo con exito!!!", ToastTypes.Success);
          this.contextUseCase.hideSpinner();
          this.router.navigate(['/student/' + parent.student_id  +'/detail']);
        },
        error:()=>{
          this.contextUseCase.hideSpinner();
        }
      });
    }
   
   }
   