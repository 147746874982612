import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { ParentModel } from 'src/domain/models/parent.model';
import { ParentRepository } from 'src/domain/repositories/parent.repository';


@Injectable({
    providedIn: 'root',
})
export class ParentCreateUseCase implements UseCase<ParentModel, ParentModel> {

    constructor(private parentRepository: ParentRepository) {

    }

    execute(
        parents: ParentModel,
    ): Observable<ParentModel> {
           return  this.parentRepository.create(parents)
         
    }
}
