<div class="container-fluid">
    <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" [attr.aria-expanded]="collapseOne" aria-controls="flush-collapseOne">
                <h6 class="wlm-detail-title-text text-success">
                    <i class="fa-solid fa-1 fa-2x mr-3"></i>Subir archivo
                </h6>
                <i *ngIf="phaseOneCompleted" class="fa fa-circle-check fa-2x mr-3 text-success"></i>
            </button>
          </h2>
          <div id="flush-collapseOne"  [class.show]="collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
                <p>Por favor, seleccione el archivo excel con los alumnos que quiere importar.</p>
                <input type="file" class="form-control" (change)="onChangeFileHandler($event)">
                <button type="button" [disabled]="processingFile" class="col btn btn-primary my-5" (click)="nextOneHandler()">
                  <div *ngIf="processingFile" class="ml-2 spinner-border spinner-border-sm text-primary" role="status"></div>
                    Siguiente
                </button>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingTwo">
            <button class="accordion-button collapsed shadow-none" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" [attr.aria-expanded]="collapseTwo" aria-controls="flush-collapseTwo">
                <h6 class="wlm-detail-title-text text-success">
                    <i class="fa-solid fa-2 fa-2x mr-3"></i>Estudiantes recuperados
                </h6>
                <i *ngIf="phaseTwoCompleted" class="fa fa-circle-check fa-2x mr-3 text-success"></i>
            </button>
          </h2>
          <div id="flush-collapseTwo" [class.show]="collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
                <app-student-table [students]="students"></app-student-table>
                <button type="button" class="col btn btn-primary my-5" (click)="nextTwoHandler()">
                    Siguiente
                </button>
            </div>
          </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" [attr.aria-expanded]="collapseThree" aria-controls="flush-collapseThree">
                  <h6 class="wlm-detail-title-text text-success">
                      <i class="fa-solid fa-3 fa-2x mr-3"></i>Escuela y Curso
                  </h6>
                  <i *ngIf="phaseThreeCompleted" class="fa fa-circle-check fa-2x mr-3 text-success"></i>
              </button>
            </h2>
            <div id="flush-collapseThree" [class.show]="collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                <div class="form-floating mb-2">
                  <app-autocomplete label="Escuela" entityName="school" ></app-autocomplete>
                </div>
                <div class="form-floating mb-2">
                  <app-autocomplete label="Curso" entityName="course"></app-autocomplete>
                </div>
                <button type="button" class="col btn btn-primary my-5" (click)="nextThreeHandler()">
                    Siguiente
                </button>
              </div>
            </div>
          </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingFour">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" [attr.aria-expanded]="collapseFour" aria-controls="flush-collapseThree">
                <h6 class="wlm-detail-title-text text-success">
                    <i class="fa-solid fa-4 fa-2x mr-3"></i>Confirmar creacion
                </h6>
                <i *ngIf="phaseFourCompleted" class="fa fa-circle-check fa-2x mr-3 text-success"></i>
            </button>
          </h2>
          <div id="flush-collapseFour" [class.show]="collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
              <p>Para finalizar, presione importar alumnos para crear los alumnos del punto anterior.</p>
              <button type="button" class="col btn btn-primary my-5" (click)="nextThreeHandler()">
                Importar alumnos
            </button>
            <div class="progress">
              <div class="progress-bar" role="progressbar" [ngStyle]="{'width': progressBarWidth}" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            </div>
          </div>
        </div>
      </div>
</div>