import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastTypes } from 'src/domain/models/toast-types';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { StudentCreateUseCase } from 'src/domain/usecases/student/student-create.usecase';
import { Location } from '@angular/common';
import { NotificationUseCase } from 'src/domain/usecases/common/notification.usecase';

@Component({
  selector: 'app-student-create',
  templateUrl: './student-create.component.html',
  styleUrls: ['./student-create.component.css']
})
export class StudentCreateComponent implements OnInit {

  form: FormGroup;
  full_name: FormControl = new FormControl('', Validators.required);
  email: FormControl = new FormControl('', [Validators.email]);
  full_address: FormControl = new FormControl('');
  telephone_number: FormControl = new FormControl('');
  school_id: FormControl = new FormControl('');
  course_id: FormControl = new FormControl('');
  description: FormControl = new FormControl('');
  dni: FormControl = new FormControl('');
  age: FormControl = new FormControl('');
  allergies: FormControl = new FormControl('');
  photo: string = 'presentation/assets/avatar-user.png';
  birth_date: FormControl = new FormControl('');
  year_of_entry:FormControl = new FormControl('');
  vocal_cord:FormControl = new FormControl('');

  constructor(private formBuilder: FormBuilder,
              private activatedRoute:ActivatedRoute,
              private location:Location,
              private studentCreateUseCase:StudentCreateUseCase,
              private contextUseCase:ContextUseCase,
              private notificationUseCase:NotificationUseCase) { 

                this.form = formBuilder.group({
                  full_name: this.full_name,
                  email: this.email,
                  telephone_number: this.telephone_number,
                  school_id: this.school_id,
                  course_id: this.course_id,
                  description: this.description,
                  dni: this.dni,
                  age: this.age,
                  allergies: this.allergies,
                  photo: this.photo,
                  birth_date:this.birth_date,
                  year_of_entry:this.year_of_entry,
                  vocal_cord:this.vocal_cord
                });
              }

  ngOnInit(): void {
    this.contextUseCase.hideToggledNav();
    this.activatedRoute.paramMap.subscribe(params => {
      this.school_id.setValue(params.get('schoolid'));
      this.course_id.setValue(params.get('courseid'));
    });
  }

  cancel(){
    this.location.back();
  }

  updatePhotoHandler(event:any) {
    let photoFile = event.target.files[0];
    
    const reader = new FileReader();
    reader.readAsDataURL(photoFile);
    reader.onload = () => {
      this.photo = reader.result + '';
      this.form.controls['photo'].setValue(this.photo); 
    };
   
    
  }
  

  saveHandler(): void{

    if(this.form.invalid){
      this.form.markAllAsTouched();
      return;
    }

    this.contextUseCase.showSpinner();
    this.studentCreateUseCase.execute(this.form.value).subscribe({
      next:(student)=>{
        this.contextUseCase.addToastWithLink("alumno Creado", "el alumno se creo con exito!!!", ToastTypes.Success, '/student/'+ student.id+'/detail', 'Abrir estudiante');
        this.contextUseCase.hideSpinner();
        this.location.back();
      },
      error:(errr)=>{
        this.notificationUseCase.showError('Error', errr);
        this.contextUseCase.hideSpinner();
      }
    });
  }
 
 }
 