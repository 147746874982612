<div class="container-fluid">

    <!-- 404 Error Text -->
    <div class="text-center">
        <div class="error mx-auto" data-text="404">404</div>
        <p class="lead text-gray-800 mb-5">Pagina no encontrada</p>
        <p class="text-gray-500 mb-0">Parece que encontraste un error en la matriz....</p>
        <a href="" (click)="back()">&larr; Volver</a>
    </div>

</div>
