import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { NotificationUseCase } from 'src/domain/usecases/common/notification.usecase';
import { UserRecoverPasswordSendUseCase } from 'src/domain/usecases/user/user-recover-password-send.usecase';

@Component({
  selector: 'app-recover-password-send',
  templateUrl: './recover-password-send.component.html',
  styleUrls: ['./recover-password-send.component.css']
})
export class RecoverPasswordSendComponent {
  form: FormGroup;
  email: FormControl = new FormControl('', [Validators.required, Validators.email]);


  constructor(private router:Router, 
              private formBuilder: FormBuilder,
              private contextUseCase:ContextUseCase,
              private userRecoverPasswordSendUseCase:UserRecoverPasswordSendUseCase,
              private notificationUseCase:NotificationUseCase) {
                this.form = formBuilder.group({
                  email: this.email,
                });

   }


   ngOnInit(): void { 

   }
 
   sendHandler(): void{
    if(this.form.invalid){
      this.form.markAllAsTouched();
      return;
    }
   
    this.contextUseCase.showSpinner();

    this.userRecoverPasswordSendUseCase.execute(this.form.value).subscribe({
      next: ()=>{
        this.contextUseCase.hideSpinner();
        this.notificationUseCase.showInfo('Restablecimiento Clave', 'Se le envio un email con un enlace para generar una nueva clave.');
        this.notificationUseCase.CallBackOkEvent.subscribe(()=>{
          this.router.navigate(["/login"]);
        });
      },
      error:(result)=>{
        this.notificationUseCase.showError('Error', result.error.message);
        this.contextUseCase.hideSpinner();
      }
    });
   }
 
 }
 