import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { NotificationUseCase } from 'src/domain/usecases/common/notification.usecase';
import { UserEmailConfirmationUseCase } from 'src/domain/usecases/user/user-email-confirmation.usecase';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.css']
})
export class EmailConfirmationComponent implements OnInit, OnDestroy {
  
  isConfirmed:Boolean = false;
  suscription: any;

  constructor(private userEmailConfirmationUseCase:UserEmailConfirmationUseCase,
              private activatedRoute:ActivatedRoute,
              private notificationUseCase:NotificationUseCase,
              private contextUseCase:ContextUseCase,
              private router:Router){

  }

  ngOnDestroy(): void {
 
  }
  
  ngOnInit(): void {
    this.contextUseCase.showSpinner();

     let token = this.activatedRoute.snapshot.params['token'];

     this.suscription = this.userEmailConfirmationUseCase.execute(token).subscribe({
          next:()=> {
             this.isConfirmed = true;
             this.contextUseCase.hideSpinner();
             this.suscription.unsubscribe();
          },
          error:(error)=>{
            this.suscription.unsubscribe();
            this.contextUseCase.hideSpinner();
            this.router.navigate(["/login"]);
          }
     });
  }

}
