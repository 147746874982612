import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { CourseModel } from 'src/domain/models/course.model';
import { CourseRepository } from 'src/domain/repositories/course.repository';


@Injectable({
    providedIn: 'root',
})
export class CourseUpdateUseCase implements UseCase<CourseModel, CourseModel> {

    constructor(private courseRepository: CourseRepository) { }

    execute(
        course: CourseModel,
    ): Observable<CourseModel> {
        return this.courseRepository.update(course);
    }
}
