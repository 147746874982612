
<div class="bg-gradient-primary">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-10 col-lg-12 col-md-9">
      <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
              <!-- Nested Row within Card Body -->
              <div class="row">
                  <div class="col-lg-5 d-none d-lg-block bg-register-image"></div>
                  <div class="col-lg-7">
                      <div class="p-5">
                          <div class="text-center">
                              <h1 class="h4 text-gray-900 mb-4">Create tu cuenta!</h1>
                          </div>
                          <form [formGroup]="form" (submit)="saveHandler()" class="user">
                              <div class="form-group">
                                      <input type="text" class="form-control form-control-user"
                                          placeholder="Nombre Completo"
                                          formControlName="full_name" [class.is-invalid]="!full_name.valid && full_name.touched">
                                          <div *ngIf="full_name.errors?.['required']" class="invalid-feedback">
                                            debe completar este campo
                                          </div>
                              </div>
                              <div class="form-group">
                                      <input type="text" class="form-control form-control-user"
                                          placeholder="Email" formControlName="email" [class.is-invalid]="!email.valid && email.touched">
                                          <div *ngIf="email.errors?.['required']" class="invalid-feedback">
                                            debe completar este campo
                                          </div>
                              </div>
                              <div class="form-group">
                                  <input type="tel" class="form-control form-control-user"
                                      placeholder="Telefono" formControlName="telephone_number" [class.is-invalid]="!telephone_number.valid && telephone_number.touched">
                                      <div *ngIf="telephone_number.errors?.['required']" class="invalid-feedback">
                                        debe completar este campo
                                      </div>
                              </div>
                              <div class="form-group">
                                      <input name="new-password" autocomplete="new-password"  type="password" class="form-control form-control-user"
                                          placeholder="Nueva Clave" formControlName="password" [class.is-invalid]="!password.valid && password.touched">
                                          <div *ngIf="password.errors?.['required']" class="invalid-feedback">
                                            debe completar este campo
                                          </div>
                                  </div>
                                  <div class="form-group">
                                    <input name="new-password" autocomplete="new-password" type="password" class="form-control form-control-user"
                                        placeholder="Confirmacion Clave" formControlName="password_confirmation" [class.is-invalid]="!password_confirmation.valid && password_confirmation.touched">
                                        <div *ngIf="password_confirmation.errors?.['required']" class="invalid-feedback">
                                          debe completar este campo
                                        </div>
                                        <div *ngIf="password_confirmation.errors?.['matchValidator']" class="invalid-feedback">
                                          la clave y la confirmación no coinciden
                                        </div>
                                </div>
                              <button type="submit" class="btn btn-primary btn-user btn-block">
                                  Registrarse
                              </button>
                              <hr>
                          </form>
                          <hr>
                          <div class="text-center">
                              <a class="small" href="">Olvidaste la clave?</a>
                          </div>
                          <div class="text-center">
                              <a class="small" href="" routerLink="/login" >Ya tenes una cuenta? Ingresa!</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  </div>
</div>
  </div>
</div>