import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { CourseModel } from 'src/domain/models/course.model';
import { CalendarElementRepository } from 'src/domain/repositories/calendar-element.repository';


@Injectable({
    providedIn: 'root',
})
export class CalendarElementUpdateUseCase implements UseCase<CourseModel, void> {

    constructor(private calendarElementRepository: CalendarElementRepository) { }

    execute(
        course: CourseModel,
    ): Observable<void> {
        return this.calendarElementRepository.updateCourseCalendar(course);
    }
}
