import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { AddressModel } from 'src/domain/models/address.model';
import { MapAddressGateway } from 'src/domain/gateways/map-address.gateway';

@Injectable({
  providedIn: 'root'
})
export class MapAddressImplementationGateway extends  MapAddressGateway{

  private searchSuscription!: Subscription;

  constructor(private http:HttpClient) { 
      super();
  }
  
  getAddress(lat: any, lng: any): Observable<any> {
    return new Observable<string>((observer) => {
      this.searchSuscription = this.http.get<any>('/reverse?format=jsonv2&lat=' + lat +'&lon=' + lng).subscribe({
     next:(response)=>{
         observer.next(response);
     },
     error:(error)=>{
        observer.error(error);
     }
  });

  });
 }
  
  public search(textSearch:string, province:string):Observable<AddressModel[]>{
    this.searchSuscription?.unsubscribe();

    return new Observable<AddressModel[]>((observer) => {
          this.searchSuscription = this.http.get<AddressModel[]>('/searchautocomplete' + textSearch + ' ' + province +' Argentina').subscribe({
         next:(response)=>{
             observer.next(response);
         },
         error:(error)=>{
            observer.error(error);
         }
      });

    });
   }

}
