import { Observable } from 'rxjs';
import { UserModel } from '../models/user.model';
import { UserSessionModel } from '../models/user-session-model';
import { PasswordChangeModel } from '../models/password-change.model';

export abstract class UserRepository {
    abstract login(user: UserModel): Observable<UserSessionModel>;
    abstract register(user: UserModel): Observable<UserModel>;
    abstract getUserProfile(): Observable<UserModel>;
    abstract passwordChange(passwordChangeModel:PasswordChangeModel):Observable<void>;
    abstract updateUserProfile(user: UserModel): Observable<UserModel>;
    abstract emailConfirmation(token: string): Observable<void>;
    abstract resetPasswordSend(user: UserModel): Observable<void>;
    abstract resetPassword(user: UserModel): Observable<void>;
    abstract getAll(force:Boolean): Observable<UserModel[]>;
    abstract update(user: UserModel): Observable<void>;
}
