import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserRepository } from 'src/domain/repositories/user.repository';
import { UserModel } from 'src/domain/models/user.model';
import { Injectable } from '@angular/core';
import { UserSessionModel } from 'src/domain/models/user-session-model';
import { PasswordChangeModel } from 'src/domain/models/password-change.model';

@Injectable({
    providedIn: 'root',
})
export class UserImplementationRepository extends UserRepository {
    
    constructor(private http: HttpClient) {
        super();
    }

    emailConfirmation(token: string): Observable<void> {
        return this.http.put<void>('/api/confirmation/' + token, '');
    }
    
    login(user: UserModel): Observable<UserSessionModel> {
        return this.http.post<UserSessionModel>('/api/login', user);
    }

    getUserProfile(): Observable<UserModel>{
        return this.http.get<UserModel>('api/userProfile');
    }

    register(user: UserModel): Observable<UserModel> {
        return this.http.post<UserModel>('/api/register', user);
    }

    passwordChange(passwordChangeModel: PasswordChangeModel): Observable<void> {
        return this.http.put<void>('/api/passwordchange', passwordChangeModel);
    }

    updateUserProfile(user: UserModel): Observable<UserModel>{
        return this.http.post<UserModel>('api/user', user);
    }

    resetPasswordSend(user: UserModel): Observable<void>{
        return this.http.post<void>('api/resetPasswordSend', user);
    }

    resetPassword(user: UserModel): Observable<void>{
        return this.http.post<void>('api/resetPassword', user);
    }

    getAll(force: Boolean): Observable<UserModel[]> {
        return this.http.get<UserModel[]>('api/user');
    }
    update(user: UserModel): Observable<void> {
        throw new Error('Method not implemented.');
    }

}
