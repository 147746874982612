import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { ReferenceModel } from "src/domain/models/reference.model";
import { LookupRepository } from "src/domain/repositories/lookup.repository";


@Injectable({
    providedIn: 'root',
})
export class LookupImplementationRepository extends LookupRepository  {

    private searchSuscription!: Subscription;
    
    constructor(private http:HttpClient)
    {
        super();
    }
    
    search(entityName:string, textSearch:string):Observable<ReferenceModel[]>{
        this.searchSuscription?.unsubscribe();
  
        return new Observable<ReferenceModel[]>((observer) => {
        
          this.searchSuscription = this.http.get<ReferenceModel[]>('/api/' + entityName +'/lookup/' + textSearch).subscribe({
             next:(response)=>{
                 observer.next(response);
             },
             error:(error)=>{
                observer.error(error);
             }
          });
    
        });


    }
    
    
}