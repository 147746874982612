import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StudentModel } from 'src/domain/models/student.model';

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.css']
})
export class StudentListComponent implements OnInit {

  @Input() useClassFull: Boolean = false;
  @Input() students: StudentModel[] = [];
  @Output() deleteEvent = new EventEmitter<StudentModel>();
  @Output() detachCourseEvent = new EventEmitter<StudentModel>();

  constructor() { 
    
  }

  ngOnInit(): void {

  }

  deleteHandler(student:StudentModel){
    this.deleteEvent.emit(student);
  }
  
  detachCourseHandler(student:StudentModel){
    this.detachCourseEvent.emit(student);
  }

}
