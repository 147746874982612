import { SchoolModel } from '../models/school.model'
import { Observable } from 'rxjs';
import { BaseRepository } from '../repositories/base.repository'


export abstract class SchoolRepository implements BaseRepository<SchoolModel>{
    abstract getById(id: number): Observable<SchoolModel>;
    abstract getAll(force:Boolean): Observable<SchoolModel[]>;
    abstract create(model:SchoolModel): Observable<SchoolModel>;
    abstract update(model:SchoolModel): Observable<SchoolModel>;
    abstract getCurrent():SchoolModel | undefined;
    abstract delete(model:SchoolModel):Observable<void>;
    abstract getByStudent(studentId:number, force:Boolean): Observable<SchoolModel[]>;
}