import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastTypes } from 'src/domain/models/toast-types';
import { Location } from '@angular/common';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { UserPasswordChangeUseCase } from 'src/domain/usecases/user/user-password-change.usecase';
import { NotificationUseCase } from 'src/domain/usecases/common/notification.usecase';
import { matchValidator } from '../../common/forms/form.match.validation';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css']
})
export class PasswordChangeComponent {
    form: FormGroup;
    confirmationPassword: FormControl = new FormControl('', Validators.required);
    currentPassword: FormControl = new FormControl('', [Validators.required]);
    newPassword: FormControl = new FormControl('', [Validators.required]);
    

    constructor(private formBuilder: FormBuilder,
                private location:Location,
                private router: Router,
                private contextUseCase: ContextUseCase,
                private notificationUseCase: NotificationUseCase,
                private userPasswordChangeUseCase:UserPasswordChangeUseCase) { 
  
                  this.form = formBuilder.group({
                    currentPassword: this.currentPassword,
                    newPassword: this.newPassword,
                    confirmationPassword: this.confirmationPassword
                  }, 
                  {
                    validator: matchValidator("newPassword", "confirmationPassword")
                  });
                }
  
    ngOnInit(): void {
    }
  
    cancel(){
      this.location.back();
    }
  
   
    
  
    saveHandler(): void{
  
      if(this.form.invalid){
        this.form.markAllAsTouched();
        return;
      }
     
      this.contextUseCase.showSpinner();

      this.userPasswordChangeUseCase.execute(this.form.value).subscribe({
        next: ()=>{
          this.contextUseCase.hideSpinner();
          this.contextUseCase.addToast("Cambio de Clave", "Se Cambio la clave con exito!!!", ToastTypes.Success);
          this.location.back();
        },
        error:(result)=>{
          debugger
          this.notificationUseCase.showError('Cambio Clave', result.error.error);
          this.contextUseCase.hideSpinner();
        }
      });
     
    }
   
   }
   