<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="">Padres / Tutores</a>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
  </div>
  <div class="btn-group" role="group" aria-label="">
    <button type="button"  *ngIf="this.canCreate"  routerLink="/student/{{studentId}}/parent/create" class="btn btn-primary"> <i class="fab fa fa-plus"></i></button>
    <button type="button" class="btn btn-primary shadow-none" (click)="viewAsTable = !viewAsTable" ><i class="fa fa-table"></i></button>
    <button type="button" class="btn btn-primary shadow-none" (click)="viewAsTable = !viewAsTable" ><i class="fa fa-address-card"></i></button>
    <button type="button" class="btn btn-primary shadow-none" (click)="loadRecords(true)" ><i class="fas fa-rotate"></i></button>
  </div>
</nav>
<app-spinner-mini *ngIf="loading"></app-spinner-mini>
<h6 *ngIf="notRecords" class="mt-5 mb-5 text-center">No hay registros</h6>
<ng-container *ngIf="!viewAsTable">
  <app-parent-list [parents]="parents" (deleteEvent)="deleteHandler($event)"></app-parent-list>
</ng-container>
<ng-container *ngIf="viewAsTable">
  <app-parent-table [parents]="parents" (deleteEvent)="deleteHandler($event)"></app-parent-table>
</ng-container>



