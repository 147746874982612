import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchGlobalResultModel } from 'src/domain/models/search-result.model';
import { GlobalSearchService } from 'src/domain/services/global-search.service';

declare var bootstrap: any;

@Injectable({
    providedIn: 'root',
})
export class GlobalSearchUseCase {

    public changedResults = new EventEmitter<SearchGlobalResultModel>();
    public updateResults = new EventEmitter();


    constructor(private globalSearchService:GlobalSearchService) {
            this.globalSearchService.changedResults = this.changedResults;
            this.globalSearchService.updateResults = this.updateResults;
    }
     
    search(textSearch:string):Observable<SearchGlobalResultModel>{
        return new Observable<SearchGlobalResultModel>((observer)=>{
            this.globalSearchService.search(textSearch).subscribe({
                next:(result)=>{
                   this.globalSearchService.searchGlobalResult = result;
                   this.updateResults.emit(result);
                   observer.next(result);
                },
                error: (error)=> observer.error(error)
            })
        }) 
    }


}
