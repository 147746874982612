<div class="m-1">
    <div class="card shadow mb-2">
        <div class="card-header py-3">
            <div class="row">
                <i class="fas fa-handshake fa-3x wlm-detail-title-icon text-primary"></i>
                <h4 class="wlm-detail-title-text text-primary">Suscriptor</h4>
            </div>
            <div class="row">
            <p>Id:     <b>{{subscriptor?.id}}</b></p>
            <p>Nombre Usuario: <b>{{subscriptor?.name}}</b></p>
            <p>Fecha Creacion:   <b>{{subscriptor?.created_at | date: 'dd/MM/yyyy hh:mm a'}}</b></p>
            <p>Email:      <b>{{subscriptor?.email}}</b></p>
            <p>Estado:<b>{{getSubscritorState(subscriptor)}}</b></p>
            </div>
            <div class="row">
            <div class="btn-group btn-group-sm justify-content-md-end shadow-none" role="group" aria-label="">
                <button type="button" 
                    (click)="back()" 
                    title="Volver"
                    class="btn btn-outline-primary wlm-button-detail shadow-none">
                    <i class="fa fa-lg fa-arrow-left"></i>
                    <span class="d-none d-lg-block">Volver</span>
                </button>
                <button type="button" 
                    title="Editar escuela"
                    routerLink="/subscriber/{{subscriptor?.id}}/edit" 
                    class="btn btn-outline-primary shadow-none">
                    <i class="fas fa-lg fa-pen-to-square text-warning"></i>
                    <span class="d-none d-lg-block">Editar Suscriptor</span>
                </button>
                <button type="button" 
                    title="Eliminar escuela"
                    class="btn btn-outline-primary shadow-none">
                    <i class="fa fa-lg fa-regular fa-trash  text-danger"></i>
                    <span class="d-none d-lg-block">Eliminar Suscriptor</span>
                </button>
                <button type="button" 
                    (click)="setViewType(0)" 
                    title="Mostrar Mapa"
                    class="btn btn-outline-primary shadow-none">
                    <i class="fas fa-lg fa-map-location wlm-text-green"></i>
                    <span class="d-none d-lg-block">Ver Roles</span>
                </button>
            </div>
        </div>
    </div>
        <div class="card-body p-0">

        </div>
    </div>
  
  </div>