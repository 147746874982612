import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseImplementationRepository } from './base-implementation.repository';
import { SubscriptorRepository } from 'src/domain/repositories/subscriptor.repository';
import { SubscriptorModel } from 'src/domain/models/subscriptor.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SubscriptorImplementationRepository extends BaseImplementationRepository<SubscriptorModel> implements  SubscriptorRepository {
         
    constructor(http: HttpClient) {
         super(http);
    }
    
    override http!:HttpClient;

    override getEntityName(): string {
        return 'subscriptor';
    }

}