<div class="col">
    <div class="text-center">
        <h1 class="h4 text-gray-900 mb-4">Cambiar Clave de Ingreso</h1>
    </div>
    <form [formGroup]="form" (submit)="saveHandler()" class="user">
        <div class="form-floating mb-2">
          <input  id="currentPassword" autocomplete="off" type="password" class="form-control form-control-sm" placeholder="Clave Actual"
          formControlName="currentPassword" [class.is-invalid]="!currentPassword.valid && currentPassword.touched">
          <div *ngIf="currentPassword.errors?.['required']" class="invalid-feedback">
            debe completar este campo
          </div>
          <label for="currentPassword" class="form-floating-required">Clave Actual</label>
        </div>
        <div class="form-floating mb-2">
            <input  id="newPassword" autocomplete="off" type="password" class="form-control form-control-sm" placeholder="Nueva Clave"
            formControlName="newPassword" [class.is-invalid]="!newPassword.valid && newPassword.touched">
            <div *ngIf="newPassword.errors?.['required']" class="invalid-feedback">
              debe completar este campo
            </div>
            <label for="newPassword" class="form-floating-required">Nueva Clave</label>
          </div>
          <div class="form-floating mb-2">
            <input id="confirmationPassword" autocomplete="off" type="password" class="form-control form-control-sm" placeholder="Confirmacion Clave"
            formControlName="confirmationPassword" [class.is-invalid]="!confirmationPassword.valid && confirmationPassword.touched">
            <div *ngIf="confirmationPassword.errors?.['required']" class="invalid-feedback">
              debe completar este campo
            </div>
            <div *ngIf="confirmationPassword.errors?.['matchValidator']" class="invalid-feedback">
              la clave y la confirmación no coinciden
          </div>
            <label for="confirmationPassword" class="form-floating-required">Confirmacion Clave</label>
          </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
             <button type="submit" class="col btn btn-primary">
            Guardar
        </button>
        <button type="button" (click)="cancel()" class="col btn btn-secondary">
          Cancelar
      </button>
      </div>
        <hr>
    </form>
</div>



