import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { SchoolModel } from 'src/domain/models/school.model';
import { SchoolRepository } from 'src/domain/repositories/school.repository';

@Injectable({
    providedIn: 'root',
})
export class SchoolGetByIdUseCase implements UseCase<number, SchoolModel> {

    constructor(private schoolRepository: SchoolRepository) { }

    execute(
        schoolId: number,
    ): Observable<SchoolModel> {
        return this.schoolRepository.getById(schoolId);
    }
}
