import { Component, OnDestroy, OnInit} from '@angular/core';
import { ToastService } from 'src/domain/services/toast.service';


declare var window: any;  

@Component({
  selector: 'app-toasts-alert',
  templateUrl: './toasts-alert.component.html',
  styleUrls: ['./toasts-alert.component.css']
})
export class ToastAlertComponent implements OnInit, OnDestroy {

    show:Boolean = true;
	toast: any;
	title:string = '';
	message:string = '';
	routerLink: string = '';
	routerName:string = '';

	constructor(public toastService: ToastService) {

	}
	ngOnDestroy(): void {
		 
	}
	ngOnInit(): void {
		this.toast = new window.bootstrap.Toast(
			document.getElementById('toast')
		  );

		  this.toastService.ShowToastEvent.subscribe((element)=>{
			this.title = element.title;
			this.message = element.message;
			this.routerLink = element.routerLink;
			this.routerName = element.routerName;

			this.toast.show();

		  });
	}
 
}