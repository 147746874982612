import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { Injectable } from '@angular/core';
import { UserSessionModel } from 'src/domain/models/user-session-model';
import { UserGetUserSessionUseCase } from './user-get-session.usecase';


@Injectable({
    providedIn: 'root',
})
export class UserAuthenticationUseCase implements UseCase<void, UserSessionModel | undefined> {

    constructor(private userGetUserSessionUseCase:UserGetUserSessionUseCase) { }

    execute(): Observable<UserSessionModel | undefined> {
        return new Observable<UserSessionModel | undefined>((observer)=>{
            this.userGetUserSessionUseCase.execute().subscribe({
                next:(session)=>{
                    if(session == null){
                         observer.next(undefined);
                    }else{
                        let expire = new Date(session.expire);
                        let now = new Date();              

                        if(session != null && expire >= now)
                            observer.next(session);
                        else
                            observer.next(undefined);

                        observer.complete();
                    }
                }})
        })
    }
}
