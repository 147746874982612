import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseImplementationRepository } from "./base-implementation.repository";
import { SchoolModel } from "src/domain/models/school.model";
import { SchoolRepository } from "src/domain/repositories/school.repository";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class SchoolImplementationRepository extends BaseImplementationRepository<SchoolModel> implements SchoolRepository {

    constructor(http:HttpClient)
    {
        super(http);
    }

    override http!:HttpClient;

    public getByStudent(studentId: number, force:Boolean): Observable<SchoolModel[]> {
        return new Observable<SchoolModel[]>((observer) => {
        
            if(force)
                this.currentEntities = undefined;

            if(this.currentEntities != undefined){
                observer.next(this.currentEntities);
            }else{

                this.http.get<SchoolModel[]>('/api/student/'+ studentId +'/schools').subscribe({
                    next:(currentEntities)=>{
                      this.currentEntities = currentEntities;
                      observer.next(currentEntities); 
                    },
                    error:(target)=>{
                      observer.error(target.error); 
                    },
                    complete:()=>{
                      observer.complete();
                      observer.unsubscribe();
                    }});
            }
        })
    }
    
    override getEntityName(): string {
       return 'school';
    }
    
    
}