import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { StudentModel } from 'src/domain/models/student.model';
import { StudentRepository } from 'src/domain/repositories/student.repository';


@Injectable({
    providedIn: 'root',
})
export class StudentImportUseCase implements UseCase<FormData, StudentModel[]> {

    constructor(private studentRepository: StudentRepository){ }

    execute(
        form: FormData,
    ): Observable<StudentModel[]> {
           return this.studentRepository.import(form);
    }
}
