import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MapAddressGateway } from 'src/domain/gateways/map-address.gateway';
import { MapAddressImplementationGateway } from './map-address-implementation-gateway';



@NgModule({
    providers: [
        { provide: MapAddressGateway, useClass: MapAddressImplementationGateway },
    ],
    imports: [
        CommonModule,
        HttpClientModule
    ],
})

export class GatewayModule { }