<div class="input-group mb-3">
    <div class="form-floating flex-grow-1">
        <input id="{{label}}" autocomplete="off" type="text" onfocus="this.select()" [(ngModel)]="referenceSearch" [disabled]="readOnly.toString()" (blur)="blurHandler()" [class.is-invalid]="!isValid && isChanged"  (ngModelChange)="searchHandler()" class="form-control"  placeholder="{{label}}" >
        <label for="{{label}}" class="form-floating-required">{{label}}</label>
    </div>
    <button type="button" class="input-group-text" [disabled]="readOnly.toString()">
        <i *ngIf="!searching" class="fas fa-magnifying-glass"></i>
        <span *ngIf="searching" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
</div>
<div *ngIf="!isValid && isChanged" style="color: red;">
    debe completar este campo
</div>
<div class="position-absolute bg-white wlm-list">
    <ul class="list-group shadow-sm">
        <li *ngIf="searching" class="list-group-item text-center">
            buscando...
            <div class="ml-2 spinner-border spinner-border-sm text-primary" role="status"></div>
        </li>
        <li *ngIf="notResult" class="list-group-item text-center">
                <span>no se encontro direccion</span>
        </li>
    </ul>
    
    <ul class="list-group shadow-sm">
        <li (click)="onSelectHandler(reference)" class="list-group-item" *ngFor="let reference of referenceResult | async">
            {{reference.name}}
        </li>
    </ul>
</div>
