import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { AddressModel } from 'src/domain/models/address.model';
import { SchoolModel } from 'src/domain/models/school.model';
import { ToastTypes } from 'src/domain/models/toast-types';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { SchoolGetByIdUseCase } from 'src/domain/usecases/school/school-get-by-id.usecase';
import { SchoolUpdateUseCase } from 'src/domain/usecases/school/school-update.usecase';

@Component({
  selector: 'app-school-edit',
  templateUrl: './school-edit.component.html',
  styleUrls: ['./school-edit.component.css']
})
export class SchoolEditComponent implements OnInit, OnDestroy {

  id!: number;
  school: SchoolModel | undefined;
  form: FormGroup;
  name: FormControl = new FormControl('', [Validators.required, Validators.pattern(/^[ +°$_!¡|a-zA-Z0-9]+$/)]);
  email: FormControl = new FormControl('');
  full_address: FormControl = new FormControl('');
  latitude: FormControl = new FormControl('0');
  longitude:FormControl = new FormControl('0');
  telephone_number: FormControl = new FormControl('');
  description: FormControl = new FormControl('');
  schoolId: FormControl = new FormControl('', Validators.required);
  validateAddress:EventEmitter<void> = new EventEmitter<void>();
  isAddressValid:Boolean = false;
  addressSet:EventEmitter<AddressModel> = new EventEmitter<AddressModel>();

  constructor(private router: Router,
              private activatedRoute:ActivatedRoute,
              private location:Location,
              private formBuilder: FormBuilder,
              private contextUseCase:ContextUseCase,
              private schoolGetByIdUseCase: SchoolGetByIdUseCase,
              private schoolUpdateUseCase:SchoolUpdateUseCase) { 

                this.form = formBuilder.group({
                  id: this.schoolId,
                  name: this.name,
                  email: this.email,
                  full_address: this.full_address,
                  latitude: this.latitude,
                  longitude: this.longitude,
                  telephone_number: this.telephone_number,
                  description: this.description
                });


  }

public omit_special_char(e: any):Boolean {
        let k;
        if (/^[a-zA-Z0-9\s]*$/.test(e.key)) {
            return true;
        } else {
            e.preventDefault();
            return false;
        }
}


  ngOnDestroy(): void {
      this.addressSet.unsubscribe();
      this.validateAddress.unsubscribe();
  }

  ngOnInit(): void {
    this.contextUseCase.showSpinner();
    this.contextUseCase.hideToggledNav();
    this.id = this.activatedRoute.snapshot.params['id'];
   
    setTimeout(()=>{this.loadSchool(this.id);}, 500);
  }

  cancel() {
    this.location.back();
  }

  getAddressSelect(addressResult:AddressModel| undefined){

    if(addressResult == undefined)
      addressResult = {display_name:'', lat:0,lon:0, title:'', id:0, target:''};

    this.form.controls['full_address'].setValue(addressResult.display_name); 
    this.form.controls['latitude'].setValue(addressResult.lat); 
    this.form.controls['longitude'].setValue(addressResult.lon); 
  }

  addressChangeState(state:Boolean){
      this.isAddressValid = state;
  }


  saveHandler(): void{

    if(this.form.invalid){
      this.form.markAllAsTouched();
      return;
    }

    this.validateAddress.emit();

    if( this.isAddressValid == false)
             return;

     this.contextUseCase.showSpinner();
   
     this.schoolUpdateUseCase.execute(this.form.value).subscribe(
     {
       next:() =>{
        this.contextUseCase.hideSpinner();
         this.contextUseCase.addToast("Registro Guardado", "la escuela se actualizo con exito!!!", ToastTypes.Success);
         this.location.back();
       },
       error: () =>{
        this.contextUseCase.hideSpinner();
       }
     });
   }
 
  loadSchool(id: number) {
    this.schoolGetByIdUseCase.execute(id).subscribe({
      next:(school)=>{
       this.school = school;
       this.form.controls['id'].setValue(this.school?.id); 
       this.form.controls['name'].setValue(this.school?.name); 
       this.form.controls['email'].setValue(this.school?.email); 
       this.form.controls['full_address'].setValue(this.school?.full_address); 
       this.form.controls['telephone_number'].setValue(this.school?.telephone_number); 
       this.form.controls['description'].setValue(this.school?.description); 
       this.form.controls['latitude'].setValue(this.school.latitude); 
       this.form.controls['longitude'].setValue(this.school.longitude); 

       this.addressSet.emit({display_name:school.full_address, lat:school.latitude, lon:school.longitude, title: school.name, id: school.id, target:school });

       this.contextUseCase.hideSpinner();
      }
    });
  }
}