import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { StudentModel } from 'src/domain/models/student.model';
import { StudentRepository } from 'src/domain/repositories/student.repository';


@Injectable({
    providedIn: 'root',
})
export class StudentGetAllUseCase implements UseCase<Boolean, StudentModel[]> {

    constructor(private studentRepository: StudentRepository) { }

    execute(
        force: Boolean,
    ): Observable<StudentModel[]> {
        return this.studentRepository.getAll(force);
    }
}
