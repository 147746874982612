    <div class="modal-content text-dark position-absolute">
        <div class="modal-header">
            <i *ngIf="typeAlert == 'Error'" class="fa fa-3x fa-circle-exclamation text-danger"></i>
            <i *ngIf="typeAlert == 'Info'" class="fa fa-3x fa-circle-check text-success"></i>
            <i *ngIf="typeAlert == 'Question'" class="fa fa-3x fa-circle-question text-warning"></i>
            <h5 class="modal-title ml-1 pt-2">{{title}}</h5>
            <button class="close" type="button" data-dismiss="modal" aria-label="Cerrar" (click)="cancelHandler()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">{{message}}</div>
        <div class="modal-footer">
            <button class="btn btn-primary" type="button" data-dismiss="modal" (click)="okHandler()">Aceptar</button>
            <button *ngIf="showButtonCancel" class="btn btn-secondary" type="button" data-dismiss="modal" (click)="cancelHandler()">Cancelar</button>
        </div>
    </div>
