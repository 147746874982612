import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { UserAuthenticationUseCase } from 'src/domain/usecases/user/user-authentication.usecase';
import { Location} from '@angular/common'
import { UserGetUserSessionUseCase } from 'src/domain/usecases/user/user-get-session.usecase';
import { LocalStorageService } from 'src/domain/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})

export class CanActivateGuard implements CanActivate {

  constructor(private router: Router, 
    private userAuthenticationUseCase:UserAuthenticationUseCase,
    private location:Location,
    private localStorageService:LocalStorageService){

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
       return this.userAuthenticationUseCase.execute().pipe(map(session => {
            if(session == undefined){
                  this.localStorageService.clear('remember_me');
                  this.router.navigate(['/login']);
            }

   
            return true;
       }));
    }
  
}
