import { EventEmitter, Injectable } from '@angular/core';
import { ToastElement } from 'src/domain/models/toast-element';
import { ToastTypes } from 'src/domain/models/toast-types';

@Injectable({
  providedIn: 'root'
})
export abstract class ToastService {
    abstract addToast(title: string, message:string, type:ToastTypes):void
    abstract addToastWithLink(title: string, message:string, type:ToastTypes, routerUrl:string, routerName:string):void
    abstract ShowToastEvent:EventEmitter<ToastElement>;
}