import { AfterViewChecked, ChangeDetectorRef, Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ScheduleItemComponent } from '../schedule-item/schedule-item.component';
import { ScheduleModel } from 'src/domain/models/schedule.model';

@Component({
  selector: 'app-schedule-edit',
  templateUrl: './schedule-edit.component.html',
  styleUrls: ['./schedule-edit.component.css']
})
export class ScheduleEditComponent implements OnInit, OnDestroy, AfterViewChecked {
   


  scheduleLength:number = 0;
  @ViewChild('items', {read: ViewContainerRef}) items!: ViewContainerRef;
  @Input() setSchedules = new EventEmitter<ScheduleModel[]>();
  @Output() schedulesChange = new EventEmitter<ScheduleModel[]>();
  @Input() validateSchedulesEvent = new EventEmitter();
  @Output() isValidSchedulesEvent = new EventEmitter<Boolean>();
  schedules:ScheduleModel[] = [];

  daysInstances:ScheduleItemComponent[] = [];

  constructor(private changeDetector: ChangeDetectorRef){

  }
  ngOnDestroy(): void {
    this.validateSchedulesEvent?.unsubscribe();
    this.setSchedules?.unsubscribe();
  }
  
  ngOnInit(): void {
    this.validateSchedulesEvent.subscribe(()=>{
      this.daysInstances.forEach(element => {
        element.validateEvent.emit();
      });
    });

    this.setSchedules.subscribe((schedules)=>{
      this.items.clear();
      this.scheduleLength = schedules.length;
      schedules.forEach((schedule, index) => {
        this.addDayComponent(index, schedule.id, schedule.day, schedule.time_from, schedule.time_to);
      });
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
  }

  addDayHandler(){
    this.addDayComponent(this.items.length, 0, 0,'', '');
  }


  addDayComponent(index: number, id:number, day:number, timeFrom:string, timeTo:string){

    let comp = this.items.createComponent(ScheduleItemComponent, {index: index}) as ComponentRef<ScheduleItemComponent>;
 
    comp.instance.index = index;
    comp.instance.id = id;
    comp.instance.day = day;
    comp.instance.timeFrom = timeFrom;
    comp.instance.timeTo =  timeTo;
    comp.instance.removeEvent.subscribe((index)=>{this.removeDayHandler(index);});
    comp.instance.changedStateEvent.subscribe(()=>{this.changedState()});

    this.schedules.push({id:id, day:day, time_from: timeFrom, time_to:timeTo });
    this.daysInstances.push(comp.instance);
    this.scheduleLength =  this.daysInstances.length;  
   }

   changedState(){
      this.isValidSchedulesEvent.emit(false);
      let valid:Boolean = false;
      this.daysInstances.forEach(instance => {
        valid = instance.dayValid && instance.timeFromValid && instance.timeToValid;
      });

      if(valid){
       this.schedules = [];

       this.daysInstances.forEach(instance => {
        this.schedules.push({id: instance.id, day:instance.day, time_from: instance.timeFrom, time_to:instance.timeTo });
      });
       
       this.schedulesChange.emit(this.schedules);

       this.isValidSchedulesEvent.emit(true);
      }
   }

   removeDayHandler(index:number){
    this.daysInstances.splice(index, 1);
    this.items.remove(index);
    
    this.daysInstances.forEach((instance, index) => {
            instance.index = index;
    });

    this.scheduleLength = this.items.length;  
  }


}
