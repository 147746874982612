import { EventEmitter, Injectable} from '@angular/core';
import { AlertMessageModel } from '../models/alert-message.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export abstract class NotificationService {
   abstract showError(title:string, message:string):void
   abstract showInfo(title:string, message:string):void
   abstract showQuestion(title:string, message:string):Observable<Boolean>
   abstract CallBackOkEvent:EventEmitter<void>;
   abstract CallBackCancelEvent:EventEmitter<void>;
   abstract NewModalEvent:EventEmitter<AlertMessageModel>;

}
