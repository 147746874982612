import { EventEmitter, Injectable } from '@angular/core';
import { SpinnerService } from 'src/domain/services/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class SpinnerImplementationService implements SpinnerService {

  constructor() { }

  public showSpinner():void
  {
    this.ChangeStateSpinnerEvent.emit(true);
  }
  public hideSpinner():void
  {
    this.ChangeStateSpinnerEvent.emit(false);
  }

  
  public ChangeStateSpinnerEvent = new EventEmitter<Boolean>();


}
