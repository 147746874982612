import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserLoginUseCase } from 'src/domain/usecases/user/user-login.usecase';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { Subscription } from 'rxjs';
import { NotificationUseCase } from 'src/domain/usecases/common/notification.usecase';
import { LocalStorageService } from 'src/domain/services/local-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers:[UserLoginUseCase,
             ContextUseCase]
})

export class LoginComponent implements OnInit, OnDestroy {

  form: FormGroup;
  password: FormControl = new FormControl('', Validators.required);
  email: FormControl = new FormControl('', Validators.required);
  remember_me:FormControl = new FormControl(false);
  
  private formBuilder = inject(FormBuilder);
  private userLoginUseCase = inject(UserLoginUseCase);
  private contextUseCase = inject(ContextUseCase);
  private router = inject(Router);
  private notificationUseCase = inject(NotificationUseCase);
  private localStoredService = inject(LocalStorageService);
  
  private suscription!: Subscription;

  constructor() { 
              this.form = this.formBuilder.group({
                                          email: this.email,
                                          password: this.password,
                                          remember_me: this.remember_me
                                        });
  }
  ngOnDestroy(): void {
    this.suscription?.unsubscribe();
  }


  ngOnInit(): void {

    let remember_me = this.localStoredService.get('remember_me');
    this.form.controls['remember_me'].setValue(remember_me??false);

    if(remember_me != undefined && remember_me == true)
      this.router.navigate(["/schools"]);
    
  } 

 

  loginHandler(){

    if(this.form.invalid)
      return;
    
    this.contextUseCase.showSpinner();

    this.suscription = this.userLoginUseCase.execute(this.form.value).subscribe({
      next:(session)=>{
        this.localStoredService.save('remember_me',  this.remember_me.value);
        this.contextUseCase.hideSpinner();
        this.router.navigate(["/schools"]);
      },
      error:(response)=>{
        this.notificationUseCase.showError("Inicio", response.error.message);
        this.contextUseCase.hideSpinner();
      }
    })
  }
}
