import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CourseModel } from 'src/domain/models/course.model';

@Component({
  selector: 'app-course-table',
  templateUrl: './course-table.component.html',
  styleUrls: ['./course-table.component.css']
})
export class CourseTableComponent implements OnInit {

  
  @Input() courses: CourseModel[] = [];
  @Output() deleteEvent = new EventEmitter<CourseModel>();
  
  constructor() { }

  ngOnInit(): void {
  }


  deleteHandler(course:CourseModel){
    this.deleteEvent.emit(course);
  }

}
