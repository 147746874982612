<div class="m-1">
    <div class="card shadow mb-2">
        <div class="card-header py-3">
            <div class="row">
                <i class="fas fa-graduation-cap fa-3x text-warning wlm-detail-title-icon"></i>
                <h4 class="wlm-detail-title-text text-warning">CURSO</h4>
            </div>
            <p>Orientacion:  <b>{{course.name}}</b></p>
            <p>Seccion: <b>{{course.section}}</b></p>
            <p>Desde: <b>{{course.date_from | date :'dd/MM/yyyy'}}</b> Hasta: <b>{{course.date_to | date :'dd/MM/yyyy'}}</b></p>
            <p>Escuela: <b routerLink="/school/{{course.school_reference.id}}/{{course.school_reference.name}}/detail" class="wlm-link"><i class="fas fa-fw fa-school mr-1"></i>{{course.school_reference.name}}</b></p>
        <div class="row">
            <div class="btn-group btn-group-sm justify-content-md-end shadow-none" role="group" aria-label="">
                <button type="button" 
                        (click)="back()" 
                        title="Volver"  
                        class="btn btn-outline-primary wlm-button-detail">
                        <i class="fa fa-lg fa-arrow-left"></i>
                        <span class="d-none d-lg-block">Volver</span>
                </button>
                <button type="button" 
                        routerLink="/course/{{courseId}}/edit" 
                        title="Editar Curso"  
                        class="btn btn-outline-primary shadow-none wlm-button-detail">
                        <i class="fas fa-lg fa-pen-to-square text-warning"></i>
                        <span class="d-none d-lg-block">Editar Curso</span>
                </button>
                <button type="button" 
                        (click)="deleteHandler()" 
                        title="Eliminar Curso"  
                        class="btn btn-outline-primary shadow-none wlm-button-detail">
                        <i class="fa fa-lg fa-trash text-danger"></i>
                        <span class="d-none d-lg-block">Eliminar Curso</span>
                </button>
                <button type="button"
                        (click)="setViewType(1)"
                        title="Dias De Clases"  
                        class="btn btn-outline-primary shadow-none wlm-button-detail">
                        <i class="fas fa-lg fa-calendar-check  wlm-text-green"></i>
                        <span class="d-none d-lg-block">Dias De Clases</span>
                </button>
                <button type="button" 
                        (click)="setViewType(0)" 
                        title="Ver Alumnos"  
                        class="btn btn-outline-primary shadow-none wlm-button-detail">
                        <i class="fas fa-lg fa-child wlm-text-green"></i>
                        <span class="d-none d-lg-block">Ver Alumnos</span>
                </button>
            </div>
            </div>
        </div>
        <div class="card-body p-0">
            <ng-container *ngIf="viewTap === viewTapStudent">
                <app-student-container [schoolId]="course.school_reference.id" [courseId]="courseId"></app-student-container>
            </ng-container>
            <ng-container *ngIf="viewTap === viewTapSchedule">
                <app-schedule-detail [schedules]="schedules"></app-schedule-detail>
            </ng-container>
           
        </div>
    </div>

</div>
