import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseImplementationRepository } from "./base-implementation.repository";
import { StudentModel } from "src/domain/models/student.model";
import { StudentRepository } from "src/domain/repositories/student.repository";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class StudentImplementationRepository extends BaseImplementationRepository<StudentModel> implements StudentRepository {

    constructor(http:HttpClient)
    {
        super(http);
    }
    
    detachStudentCourse(studentId: number, courseId: number): Observable<void> {
        return this.http.get<void>('/api/student/' + studentId +'/course/'+ courseId);
    }
    
    override http!:HttpClient;
    
    override getEntityName(): string {
       return 'student';
    }
    
    getStudentByCourse(courseId:number): Observable<StudentModel[]>{
        return this.http.get<StudentModel[]>('/api/course/'+ courseId +'/students');
    }

    getStudentBySchool(schoolId:number): Observable<StudentModel[]>{
        return this.http.get<StudentModel[]>('/api/school/'+ schoolId +'/students');
    }
    
    import(formData:FormData):Observable<StudentModel[]>{
        return new Observable((observer) => {
            this.http.post<any>('/api/importstudent', formData).subscribe(
               {next:(students)=>{
                 observer.next(students);
               },
               error:(target)=>{
                  observer.error(target.error);
               }}
              );
          });
    }
}