import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { StudentModel } from 'src/domain/models/student.model';
import { ToastTypes } from 'src/domain/models/toast-types';
import { StudentRepository } from 'src/domain/repositories/student.repository';
import { ContextUseCase } from '../common/context.usecase';
import { NotificationUseCase } from '../common/notification.usecase';
import { ParentRepository } from 'src/domain/repositories/parent.repository';

@Injectable({
    providedIn: 'root',
})
export class StudentDeleteUseCase implements UseCase<StudentModel, void> {

    constructor(private studentRepository: StudentRepository,
                private parentRepository: ParentRepository,
                private notificationUseCase: NotificationUseCase,
                private contextUseCase: ContextUseCase) { }

    execute(
      student: StudentModel
    ): Observable<void> {
        return new Observable<void>((observable)=>{
            this.notificationUseCase.showQuestion('Eliminar Alumno', '¿Desea eliminar el alumno ' + student.full_name + '?').subscribe({
            next:((response)=>{
              this.contextUseCase.showSpinner();
              this.studentRepository.delete(student).subscribe({
                next:()=> {
                  this.contextUseCase.hideSpinner();
                  this.contextUseCase.addToast('Eliminado','estudiante ' + student.full_name  +' eliminado con exito.', ToastTypes.Success);
                  this.parentRepository.getAll(true).subscribe();
                  observable.next()
                },
                error:(errr)=>{
                  this.contextUseCase.hideSpinner();
                  this.notificationUseCase.showError('Eliminar', errr.error);
                   observable.error(errr);
                },
                complete:()=>{
                  observable.complete();
                  observable.unsubscribe();
                }
              });
             
            }),
            error:((error)=>{
              this.contextUseCase.hideSpinner();
              this.notificationUseCase.showError('Error al Eliminar', error);
            })
          });
        })
    }
}
