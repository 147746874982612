<div class="row m-2 p-2 border rounded">
    <div class="col-3">
     <div class="form-floating">
        <select id="day" autocomplete="off" class="form-select" [(ngModel)]="day" (change)="changedHanler()" [disabled]="readOnly.toString()"  aria-label="Floating label select example" [class.is-invalid]="!dayValid">
            <option selected></option>
            <option value="1">Lunes</option>
            <option value="2">Martes</option>
            <option value="3">Miercoles</option>
            <option value="4">Jueves</option>
            <option value="5">Viernes</option>
            <option value="6">Sabado</option>
        </select>
        <div *ngIf="!dayValid" class="invalid-feedback">
          debe completar este campo
        </div>
        <label for="day" class="form-floating-required">Dia</label>
    </div>
    </div>
    <div class="col-4">
      <div class="form-floating">
        <input id="timeFrom" type="time" class="form-control form-control-sm"  (change)="changedHanler()" [disabled]="readOnly.toString()" [(ngModel)]="timeFrom" [class.is-invalid]="!timeFromValid">
        <div *ngIf="!timeFromValid" class="invalid-feedback">
          debe completar este campo
        </div>
        <label class="form-floating-required" for="timeFrom">Hora Entrada</label>
    </div>
    </div>
    <div class="col-4">
      <div class="form-floating">
        <input id="timeTo" type="time" class="form-control form-control-sm" (change)="changedHanler()" [disabled]="readOnly.toString()" [(ngModel)]="timeTo"  [class.is-invalid]="!timeToValid">
        <div *ngIf="!timeToValid" class="invalid-feedback">
          debe completar este campo
        </div>
        <label class="form-floating-required" for="timeTo">Hora Salida</label>
    </div>
    </div>
      <div class="col-1 mt-3">
        <button [hidden]="readOnly" type="button" (click)="removeHandler()" class="btn shadow-none m-0 p-0">
                <i class="fa fa-lg fa-regular fa-trash mr-2 pt-1 text-danger"></i>
        </button>
    </div>
  </div>
