import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavService } from 'src/domain/services/nav.service';

@Component({
  selector: '[app-nav]',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, OnDestroy {

  isCollapseUtilities: Boolean = false;
  isToggled:Boolean = false;


  constructor(private navService:NavService) { }

  ngOnInit(): void {
    this.navService.ToggledNavEvent.subscribe(()=>{
      this.isToggled = !this.isToggled;
    })

    this.navService.ShowToggledNavEvent.subscribe(()=>{
      this.isToggled = false;
    })

    this.navService.HideToggledNavEvent.subscribe(()=>{
      this.isToggled = true;
    })
  }

  ngOnDestroy():void{

  }

  collapseUtilitiesHandler(){
    this.isCollapseUtilities = !this.isCollapseUtilities;
  }

  toggledHander(){
    this.navService.ToggledNavEvent.emit();
  }

}
