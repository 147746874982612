import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseModel } from 'src/domain/models/course.model';
import { ScheduleModel } from 'src/domain/models/schedule.model';
import { CourseGetByIdUseCase } from 'src/domain/usecases/course/course-get-by-id.usercase';
import { CourseDeleteUseCase } from 'src/domain/usecases/course/course-delete.usercase';
import { Location } from '@angular/common'
import { Subscription } from 'rxjs';
import { NavService } from 'src/domain/services/nav.service';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';

const enum ViewType {
  Student = 0,
  Schedule = 1
}

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css'],
  providers: [        
    CourseGetByIdUseCase,
    CourseDeleteUseCase]
})




export class CourseDetailComponent implements OnInit, OnDestroy {
  courseId!: number;
  course: CourseModel = CourseModel.empty();
  viewTap:ViewType = ViewType.Student;
  viewTapStudent:ViewType = ViewType.Student;
  viewTapSchedule:ViewType = ViewType.Schedule;
  schedules:ScheduleModel[] = [];
  suscription!:Subscription;

  constructor(private router: Router,
    private activatedRoute:ActivatedRoute,
    private location:Location,
    private courseGetByIdUseCase:CourseGetByIdUseCase,
    private courseDeleteUseCase:CourseDeleteUseCase,
    private contextUseCase:ContextUseCase) { 

    }
  ngOnDestroy(): void {
    this.suscription.unsubscribe();
  }

  ngOnInit(): void {
    this.contextUseCase.hideToggledNav();
    this.loadCourse();
  }

  loadCourse() {
    this.courseId = this.activatedRoute.snapshot.params['courseid'];

    this.suscription = this.courseGetByIdUseCase.execute(this.courseId).subscribe({
        next:(course)=>{
           this.course = course;
           this.schedules = this.course.schedules;
        }
      })
  }


  back(){
    this.location.back();
  }

  setViewType(viewType:ViewType){
    this.viewTap = viewType;
  }


  deleteHandler(){
    this.courseDeleteUseCase.execute(this.course).subscribe({
      complete:()=>{
        this.back();
      }
    });
  }

}
