import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AddressModel } from 'src/domain/models/address.model';
import { ToastTypes } from 'src/domain/models/toast-types';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { SchoolCreateUseCase } from 'src/domain/usecases/school/school-create.usecase';
import { Location } from '@angular/common'
import { ProvinceGetQueryUseCase } from 'src/domain/usecases/province/province-get-query.usecase';
import { NavService } from 'src/domain/services/nav.service';

@Component({
  selector: 'app-school-create',
  templateUrl: './school-create.component.html',
  styleUrls: ['./school-create.component.css']
})
export class SchoolCreateComponent implements OnInit {

  form: FormGroup;
  name: FormControl = new FormControl('', [Validators.required, Validators.pattern(/^[ +°$_!¡|a-zA-Z0-9]+$/)]);
  email: FormControl = new FormControl('', [Validators.email]);
  full_address: FormControl = new FormControl('');
  latitude: FormControl = new FormControl('0');
  longitude:FormControl = new FormControl('0');
  telephone_number: FormControl = new FormControl('');
  validateAddress:EventEmitter<void> = new EventEmitter<void>();
  isAddressValid:Boolean = false;
  description: FormControl = new FormControl('');

  constructor(private schoolCreateUseCase: SchoolCreateUseCase,
              private router:Router,
              private location:Location,
              private formBuilder: FormBuilder,
              private contextUseCase: ContextUseCase,
              private provinceGetQueryUseCase:ProvinceGetQueryUseCase) { 

                this.form = formBuilder.group({
                  name: this.name,
                  email: this.email,
                  full_address: this.full_address,
                  latitude: this.latitude,
                  longitude: this.longitude,
                  telephone_number: this.telephone_number,
                  description: this.description
                });



              }

  ngOnInit(): void {
    this.contextUseCase.hideToggledNav();
  }


  cancel(){
     this.location.back();
  }


  getAddressSelect(addressResult:AddressModel | undefined){

    if(addressResult == undefined)
      addressResult = {display_name:'', lat:0,lon:0, title:'', id:0, target:''};

    this.form.controls['full_address'].setValue(addressResult.display_name); 
    this.form.controls['latitude'].setValue(addressResult.lat); 
    this.form.controls['longitude'].setValue(addressResult.lon); 
  }

  addressChangeState(state:Boolean){
    this.isAddressValid = state;
  }  
  
  saveHandler(): void{

    if(this.form.invalid){
      this.form.markAllAsTouched();
      return;
    }

    this.validateAddress.emit();

    if( this.isAddressValid == false)
             return;

    debugger
    this.contextUseCase.showSpinner();
    this.schoolCreateUseCase.execute(this.form.value).subscribe(
     {
       next:() =>{
        this.contextUseCase.hideSpinner();
         this.contextUseCase.addToast("Registro Creado", "La escuela se creo con exito!!!", ToastTypes.Success);
         this.location.back();
       },
       error: () =>{
        this.contextUseCase.hideSpinner();
       }
     });
   }
 
 }
 