import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BaseRepository } from "src/domain/repositories/base.repository";
import { Injectable } from "@angular/core";
import { TEntity } from "src/domain/models/base.model";

@Injectable({
    providedIn: 'root',
})
export abstract class BaseImplementationRepository<TModel> implements BaseRepository<TModel> {
     
    public currentEntity:TModel | undefined;
    public currentEntities:TModel[] | undefined;
    
    constructor(public http: HttpClient) {
    }

    abstract getEntityName():string;

    getCurrent():TModel | undefined {
        return this.currentEntity;
    }

    create(model: TModel): Observable<TModel> {
        return new Observable((observer) => {
            this.currentEntities = undefined;

            this.http.post<TModel>('/api/' + this.getEntityName(), model).subscribe({
                next:(response)=>{
                 
                 observer.next(response);
               },
               error:(target)=>{
                  observer.error(target);
               },
               complete:()=>{
                observer.complete();
                observer.unsubscribe();
               }}
              );
          });
    }
    
    update(model: TModel): Observable<TModel> {
        return new Observable((observer) => {
            this.currentEntity = undefined;
            this.currentEntities = undefined;

            this.http.put<TModel>('/api/' + this.getEntityName(), model).subscribe({
                next:(response)=>{
                    observer.next(response); 
                },
                error:(target)=>{
                    observer.error(target); 
                },
                complete:()=>{
                    observer.complete();
                    observer.unsubscribe();
                }});
        });
    }
      
    getById(id: number): Observable<TModel> {
        return new Observable<TModel>((observer) => {
            let entity = this.currentEntity as TEntity;

            if(entity?.id == id){
                observer.next(this.currentEntity);
                observer.unsubscribe();
                return;
            }

            this.http.get<TModel>('/api/' + this.getEntityName() + '/' + id).subscribe({
                next: (model)=>{
                    this.currentEntity = model;
                    observer.next(this.currentEntity)
                },
                error:(error)=>{
                    observer.error(error);
                },
                complete:()=>{
                    observer.complete();
                    observer.unsubscribe();
                }
            });
        });
    }
    
    
    getAll(force:boolean): Observable<TModel[]> {
        return new Observable<TModel[]>((observer) => {
            if(force)
                this.currentEntities = undefined;

            if(this.currentEntities != undefined){
              observer.next(this.currentEntities);
            }else{
               
              this.http.get<TModel[]>('/api/' + this.getEntityName()).subscribe({
                next:(currentEntities)=>{
                  this.currentEntities = currentEntities;
                  observer.next(currentEntities); 
                },
                error:(target)=>{
                  observer.error(target.error); 
                },
                complete:()=>{
                  observer.complete();
                  observer.unsubscribe();
                }}
             );
            }
          });
    }


    delete(model: TModel): Observable<void> {
        return new Observable<void>((observer) => {

            let entity = model as TEntity; 
            this.currentEntities = undefined;
            this.currentEntity = undefined;

            this.http.delete('/api/' + this.getEntityName() + '/' + entity.id).subscribe({
                next:()=>{
                    observer.next();
                },
                error:(target)=>{
                    observer.error(target);
                },
                complete:()=>{
                    observer.complete();
                    observer.unsubscribe();
                }
            });    
          
        });
    }

}
