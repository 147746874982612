import { Injectable } from '@angular/core';

import { ToastTypes } from 'src/domain/models/toast-types';
import { NavService } from 'src/domain/services/nav.service';
import { SpinnerService } from 'src/domain/services/spinner.service';
import { ToastService } from 'src/domain/services/toast.service';

declare var bootstrap: any;

@Injectable({
    providedIn: 'root',
})
export class ContextUseCase {

    constructor(private spinnerService:SpinnerService,
        private toastService:ToastService,
        private navService:NavService) { }
        
    public showSpinner(){
        this.spinnerService.showSpinner();
    }
  
    public hideSpinner(){
        this.spinnerService.hideSpinner();
    }

    public addToast(title:string, message:string, type: ToastTypes){
        this.toastService.addToast(title, message, type);
    }

    public addToastWithLink(title:string, message:string, type: ToastTypes, routerUrl:string, routerName:string){
        this.toastService.addToastWithLink(title, message, type, routerUrl, routerName);
    }


    public hideToggledNav(){
        this.navService.HideToggledNavEvent.emit();
    }

    public showToggledNav(){
        this.navService.ShowToggledNavEvent.emit();
    }

}
