import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { StudentRepository } from 'src/domain/repositories/student.repository';
import { ContextUseCase } from '../common/context.usecase';
import { ToastTypes } from 'src/domain/models/toast-types';
import { Location }from '@angular/common'
import { RelationAction } from 'src/domain/models/relationAction.model';
import { StudentModel } from 'src/domain/models/student.model';


@Injectable({
    providedIn: 'root',
})
export class StudentDetachCourseUseCase implements UseCase<RelationAction, void> {

    constructor(private studentRepository: StudentRepository,
                private contextUseCase:ContextUseCase,
                private location:Location ){ }

    execute(
        relationAction: RelationAction,
    ): Observable<void> {
            this.contextUseCase.showSpinner();
            let student = relationAction.target as StudentModel;
            let subscribe = this.studentRepository.detachStudentCourse(student.id, relationAction.relationId)
            
            subscribe.subscribe(
                {
                  next:(response) =>{
                   this.contextUseCase.hideSpinner();
                    this.contextUseCase.addToast("se quito el alumno", "el alumno se quito del curso con exito!!!", ToastTypes.Success);
                    this.location.back();
                  },
                  error: (error) =>{
                   this.contextUseCase.hideSpinner();
                  }
                });

            return subscribe;
        }
}
