import { EventEmitter, Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { AlertMessageModel } from 'src/domain/models/alert-message.model';
import { NotificationService } from 'src/domain/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationImplementationService implements NotificationService {

  constructor() { }

  public showError(title:string, message:string):void{
     this.NewModalEvent.emit(new AlertMessageModel(title, message, 'Error'));
  }
       
  public showInfo(title:string, message:string):void{
     this.NewModalEvent.emit(new AlertMessageModel(title, message, 'Info'));
  }
       
  public showQuestion(title:string, message:string):Observable<Boolean>{
     return new Observable<Boolean>((observer) => {
        
        this.NewModalEvent.emit(new AlertMessageModel(title, message, 'Question'));
              
        this.CallBackCancelEvent.subscribe(()=>{
           observer.next(false);
        });
       
        this.CallBackOkEvent.subscribe(()=>{
           observer.next(true);
        });
     });
             
  }
       
  public CallBackOkEvent = new EventEmitter<void>();
  public CallBackCancelEvent = new EventEmitter<void>();
  public NewModalEvent = new EventEmitter<any>();

}