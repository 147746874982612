import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { NotificationUseCase } from 'src/domain/usecases/common/notification.usecase';
import { matchValidator } from '../../common/forms/form.match.validation';
import { UserRecoverPasswordUseCase } from 'src/domain/usecases/user/user-recover-password.usecase';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent {

  form: FormGroup;
  token: FormControl = new FormControl('', Validators.required);
  confirmation_password: FormControl = new FormControl('', Validators.required);
  new_password: FormControl = new FormControl('', Validators.required);


  constructor(private router:Router, 
              private formBuilder: FormBuilder,
              private contextUseCase:ContextUseCase,
              private userRecoverPasswordUseCase:UserRecoverPasswordUseCase,
              private notificationUseCase:NotificationUseCase,
              private activatedRoute:ActivatedRoute) {

    this.form = formBuilder.group({
      token: this.token,
      confirmation_password: this.confirmation_password,
      new_password: this.new_password
    },
    {
      validator: matchValidator("new_password", "confirmation_password")
    });
   }


   ngOnInit(): void { 
    this.token.setValue(this.activatedRoute.snapshot.params['token']);
   }
 
   saveHandler(): void{

    if(this.form.invalid){
      this.form.markAllAsTouched();
      return;
    }

    this.contextUseCase.showSpinner();
     this.userRecoverPasswordUseCase.execute(this.form.value).subscribe(
     {
       next:() =>{
        this.contextUseCase.hideSpinner();
         this.notificationUseCase.showInfo("Restablecer Clave", "Su clave fue restablecida con exito!!!");
         this.router.navigate(['/login']);
       },
       error:()=>{
        this.contextUseCase.hideSpinner();
       }
     });
   }
 
 }
 