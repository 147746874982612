<div class="col">
    <div class="text-center">
        <h1 class="h4 text-gray-900 mb-4">Nuevo Curso</h1>
    </div>
    <form [formGroup]="form" (submit)="saveHandler()" class="user">
      <div class="form-floating mb-2">
        <input id="section" autocomplete="off" type="text" class="form-control form-control-sm"
            placeholder="section" formControlName="section" [class.is-invalid]="!section.valid && section.touched">
            <div *ngIf="section.errors?.['required']" class="invalid-feedback">
              debe completar este campo
            </div>
            <label for="section" class="form-floating-required">Seccion</label>
</div>
      <div class="form-floating mb-2">
        <input id="name" autocomplete="off" type="text" class="form-control form-control-sm" placeholder="Nombre"
        formControlName="name" [class.is-invalid]="!name.valid && name.touched">
        <div *ngIf="name.errors?.['required']" class="invalid-feedback">
          debe completar este campo
        </div>
        <label for="name" class="form-floating-required">Orientacion</label>
      </div>
        <div class="row g-2 mb-1">
          <div class="col-md">
            <div class="form-floating mb-2">
              <input id="date_from" autocomplete="off"  type="date" class="form-control form-control-sm" formControlName="date_from" [class.is-invalid]="!date_from.valid && date_from.touched">
              <div *ngIf="date_from.errors?.['required']" class="invalid-feedback">
                debe completar este campo
              </div>
              <label for="date_from" class="form-floating-required">Clases Desde</label>
            </div>
          </div>
          <div class="col-md">
            <div class="form-floating mb-2">
              <input id="date_to" autocomplete="off"  type="date" class="form-control form-control-sm" formControlName="date_to" [class.is-invalid]="!date_to.valid && date_to.touched">
              <div *ngIf="date_to.errors?.['required']" class="invalid-feedback">
                debe completar este campo
              </div>
              <label for="date_to" class="form-floating-required">Clases Hasta</label>
            </div>
          </div>
        </div>
        <div class="form-floating mb-2">
          <app-autocomplete label="Escuela" entityName="school" (referenceSelect)="getSchoolSelect($event)" [validateEvent]="validateSchool" [referenceSet]="schoolSet" (changedStateEvent)="schoolChangeState($event)"></app-autocomplete>
        </div>
        <div class="form-floating mb-2">
          <input id="background_color" autocomplete="off" type="color" class="form-control form-control-sm"  formControlName="background_color" [class.is-invalid]="!background_color.valid && background_color.touched">
          <div *ngIf="background_color.errors?.['required']" class="invalid-feedback">
            debe completar este campo
          </div>
          <label for="background_color">Color Calendario</label>
        </div>
        <div class="form-floating mb-2">
          <textarea id="description" autocomplete="off" class="form-control"
                    placeholder="Descripcion" formControlName="description" [class.is-invalid]="!description.valid && description.touched"></textarea>
          <label for="description">Descripcion</label>
        </div>
        <app-schedule-edit [setSchedules]="setSchedules" (schedulesChange)="scheduleChanged($event)"  [validateSchedulesEvent]="validateDaySchedulesEvent" (isValidSchedulesEvent)="schedulesValidChange($event)">
        </app-schedule-edit>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
             <button type="submit" class="col btn btn-primary">
            Create
        </button>
        <button type="button" (click)="cancel()" class="col btn btn-secondary">
          Cancelar
      </button>
      </div>
        <hr>
    </form>
</div>
