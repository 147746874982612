import { Component, OnInit } from '@angular/core';
import { SchoolModel } from 'src/domain/models/school.model';
import { CourseModel } from 'src/domain/models/course.model';
import { StudentModel } from 'src/domain/models/student.model';
import { SchoolDeleteUseCase } from 'src/domain/usecases/school/school-delete.usecase';
import { CourseDeleteUseCase } from 'src/domain/usecases/course/course-delete.usercase';
import { StudentDeleteUseCase } from 'src/domain/usecases/student/student-delete-usecase';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { GlobalSearchUseCase } from 'src/domain/usecases/common/global-search.usecase';
import { ParentModel } from 'src/domain/models/parent.model';
import { ParentDeleteUseCase } from 'src/domain/usecases/parent/parent-delete.usercase';
import { GlobalSearchService } from 'src/domain/services/global-search.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent implements OnInit {

  schools:SchoolModel[];
  courses:CourseModel[];
  students:StudentModel[];
  parents: ParentModel[];


  constructor(private contextUseCase: ContextUseCase,
              private globalSearchUseCase:GlobalSearchUseCase,
              private schoolDeleteUseCase:SchoolDeleteUseCase,
              private courseDeleteUserCase: CourseDeleteUseCase,
              private studentDeleteUseCase: StudentDeleteUseCase,
              private parentDeleteUseCase: ParentDeleteUseCase,
              private globalSearchService:GlobalSearchService,
              private router: Router) {
                this.schools = [];
                this.courses = [];
                this.students = [];
                this.parents = [];      
               }

  ngOnInit(): void {

    this.globalSearchUseCase.changedResults.subscribe((result)=>{
      this.schools = result.schools;
      this.courses = result.courses;
      this.students = result.students;
      this.parents = result.parents
      this.contextUseCase.hideSpinner();
     });

      if(this.globalSearchService.searchGlobalResult != undefined){
        this.schools = this.globalSearchService.searchGlobalResult.schools;
        this.courses = this.globalSearchService.searchGlobalResult.courses;
        this.students = this.globalSearchService.searchGlobalResult.students;
        this.parents = this.globalSearchService.searchGlobalResult.parents
        this.contextUseCase.hideSpinner();
       }
       else{
        this.router.navigate(["/schools"]);
       }

      }

      deleteSchoolHandler(school:SchoolModel):void{
        this.schoolDeleteUseCase.execute(school).subscribe({
          next:()=>{
            this.schools = this.schools.filter(f=>f.id != school.id);
          }
        });
      }


      deleteStudentHandler(student: StudentModel):void{
        this.studentDeleteUseCase.execute(student).subscribe({
          next:()=>{
            this.students = this.students.filter(f=>f.id != student.id);
          }
        });
      }

      deleteParentHandler(parent:ParentModel){
        this.parentDeleteUseCase.execute(parent).subscribe({
          next:()=>{
            this.parents = this.parents.filter(f=>f.id != parent.id);
          }
        });
      }

      deleteCourseHandler(course:CourseModel){
           this.courseDeleteUserCase.execute(course).subscribe({
            next:()=>{
              this.courses = this.courses.filter(f=>f.id != course.id);
            }
          });
      }

      

}
