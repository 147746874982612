import { ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from 'src/presentation/app/app-routing.module';
import { AppComponent } from 'src/presentation/app/app.component';
import { LoginComponent } from './user/login/login.component';
import { NavComponent } from './nav/nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RegisterComponent } from './user/register/register.component';
import { HomeComponent } from './home/home.component';
import { StudentListComponent } from './student/student-list/student-list.component';
import  {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from './common/spinner/spinner.component';
import { PageNotFoundComponent } from './common/page.not.found/page.not.found.component';
import { NavTopComponent } from './nav-top/nav-top.component';
import { NotificationModalComponent } from './modals/notification-modal/notification-modal.component';
import { SchoolListComponent } from './school/school-list/school-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SchoolCreateComponent } from './school/school-create/school-create.component';
import { CommonModule } from '@angular/common';
import { SpinnerMiniComponent } from './common/spinner-mini/spinner-mini.component';
import { CardNavComponent } from './common/card-nav/card-nav.component';
import { StudentCreateComponent } from './student/student-create/student-create.component';
import { ModalComponent } from './modals/modal/modal.component';
import { AutocompleteComponent } from './common/autocomplete/autocomplete.component';
import { SchoolEditComponent } from './school/school-edit/school-edit.component';
import { StudentTableComponent } from './student/student-table/student-table.component';
import { CourseListComponent } from './course/course-list/course-list.component';
import { CourseCreateComponent } from './course/course-create/course-create.component';
import { SchoolDetailComponent } from './school/school-detail/school-detail.component';
import { CourseDetailComponent } from './course/course-detail/course-detail.component';
import { CourseEditComponent } from './course/course-edit/course-edit.component';
import { ToastAlertComponent } from './common/toasts/toasts-alert/toasts-alert.component';
import { CourseContainerComponent } from './course/course-container/course-container.component';
import { StudentContainerComponent } from './student/student-container/student-container.component';
import { CourseTableComponent } from './course/course-table/course-table.component';
import { SchoolTableComponent } from './school/school-table/school-table.component';
import { SchoolContainerComponent } from './school/school-container/school-container.component';
import { StudentEditComponent } from './student/student-edit/student-edit.component';
import { StudentDetailComponent } from './student/student-detail/student-detail.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { CalendarComponent } from './calendar/calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import * as moment from 'moment';
import { ScheduleContainerComponent } from './schedule/schedule-container/schedule-container.component';
import { ScheduleDetailComponent } from './schedule/schedule-detail/schedule-detail.component';
import { ScheduleEditComponent } from './schedule/schedule-edit/schedule-edit.component'
import { ScheduleItemComponent } from './schedule/schedule-item/schedule-item.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MapComponent } from './map/map.component';
import { AutocompleteAddressComponent } from './common/autocomplete-address/autocomplete-address.component';
import { StudentImportComponent } from './student/student-import/student-import.component';
import { GlobalErrorHandler } from 'src/core/global-error-handler';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RepositoryModule } from 'src/data/data.module';
import { GatewayModule } from 'src/presentation/gateways/gateway.module';
import { ContainerModalsComponent } from './modals/container-modals/container-modals.component';
import { ToastsContainerComponent } from './common/toasts/toasts-container/toasts-container.component';
import { TokenInterceptor } from 'src/http-interceptors/TokenInterceptor';
import { ServicesModule } from 'src/presentation/services/service.module';
import { MapModalComponent } from './map-modal/map-modal.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ParentContainerComponent } from './parent/parent-container/parent-container.component';
import { ParentDetailComponent } from './parent/parent-detail/parent-detail.component';
import { ParentEditComponent } from './parent/parent-edit/parent-edit.component';
import { ParentCreateComponent } from './parent/parent-create/parent-create.component';
import { ParentListComponent } from './parent/parent-list/parent-list.component';
import { ParentTableComponent } from './parent/parent-table/parent-table.component';
import { ProfileComponent } from './user/profile/profile.component';
import { PasswordChangeComponent } from './user/password-change/password-change.component';
import { SubscriptionDetailComponent } from './subscription/subscription-detail/subscription-detail.component';
import { RecoverPasswordComponent } from './user/recover-password/recover-password.component';
import { EmailConfirmationComponent } from './user/email-confirmation/email-confirmation.component';
import { RecoverPasswordSendComponent } from './user/recover-password-send/recover-password-send.component';
import { HasPermisionDirective } from './directives/has-permision.directive';
import { GoogleMapsModule } from '@angular/google-maps';
import { SubscriberListComponent } from './subscriber/subscriber-list/subscriber-list.component';
import { SubscriberDetailComponent } from './subscriber/subscriber-detail/subscriber-detail.component';
import { SubscriberEditComponent } from './subscriber/subscriber-edit/subscriber-edit.component';

 

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavComponent,
    RegisterComponent,
    HomeComponent,
    SpinnerComponent,
    PageNotFoundComponent,
    NavTopComponent,
    NotificationModalComponent,
    ContainerModalsComponent,
    SchoolListComponent,
    DashboardComponent,
    SchoolCreateComponent,
    SpinnerMiniComponent,
    CardNavComponent,
    ModalComponent,
    AutocompleteComponent,
    StudentTableComponent,
    StudentCreateComponent,
    StudentListComponent,
    CourseListComponent,
    CourseDetailComponent,
    ToastAlertComponent,
    CourseContainerComponent,
    StudentContainerComponent,
    CourseTableComponent,
    SchoolTableComponent,
    SchoolContainerComponent,
    StudentEditComponent,
    StudentDetailComponent,
    CalendarComponent,
    ScheduleContainerComponent,
    ScheduleDetailComponent,
    ScheduleEditComponent,
    ScheduleItemComponent,
    MapComponent,
    AutocompleteAddressComponent,
    StudentImportComponent,
    SchoolEditComponent,
    CourseCreateComponent,
    CourseEditComponent,
    SearchResultComponent,
    SchoolDetailComponent,
    AutocompleteComponent,
    ScheduleDetailComponent,
    ToastsContainerComponent,
    MapModalComponent,
    ParentContainerComponent,
    ParentDetailComponent,
    ParentEditComponent,
    ParentCreateComponent,
    ParentListComponent,
    ParentTableComponent,
    ProfileComponent,
    PasswordChangeComponent,
    SubscriptionDetailComponent,
    RecoverPasswordComponent,
    EmailConfirmationComponent,
    RecoverPasswordSendComponent,
    HasPermisionDirective,
    SubscriberListComponent,
    SubscriberDetailComponent,
    SubscriberEditComponent
  ],
  imports: [
    GoogleMapsModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    FullCalendarModule,
    LeafletModule,
    RepositoryModule,
    GatewayModule,
    ServicesModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
