import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddressModel } from 'src/domain/models/address.model';

@Component({
  selector: 'app-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.css']
})
export class MapModalComponent {

  @Output() callBackOkEvent = new EventEmitter<AddressModel | undefined>();  
  @Output() callBackCancelEvent = new EventEmitter();  
  @Input() currentAddress:AddressModel | undefined;  

  constructor(){
   
  }

  setAddressSelect(address:AddressModel | undefined){
    this.currentAddress = address;
  }

  okHandler(){
    this.callBackOkEvent.emit(this.currentAddress);
  }

  cancelHandler(){
    this.callBackCancelEvent.emit();
  }
}
