import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { matchValidator } from 'src/presentation/app/common/forms/form.match.validation';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { NotificationUseCase } from 'src/domain/usecases/common/notification.usecase';
import { UserRegisterUseCase } from 'src/domain/usecases/user/user-register.usecase';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  form: FormGroup;
  full_name: FormControl = new FormControl('', Validators.required);
  email: FormControl = new FormControl('', Validators.required);
  password: FormControl = new FormControl('', [Validators.required]);
  password_confirmation: FormControl = new FormControl('', Validators.required);
  telephone_number: FormControl = new FormControl('', Validators.required);

  constructor(private router:Router, 
              private formBuilder: FormBuilder,
              private contextUseCase:ContextUseCase,
              private userRegisterUseCase:UserRegisterUseCase,
              private notificationUseCase:NotificationUseCase) {

    this.form = formBuilder.group({
      full_name: this.full_name,
      email: this.email,
      password: this.password,
      password_confirmation: this.password_confirmation,
      telephone_number: this.telephone_number
    },
    {
      validator: matchValidator("password", "password_confirmation")
    });
   }


   ngOnInit(): void { 

   }
 
   saveHandler(): void{

    if(this.form.invalid){
      this.form.markAllAsTouched();
      return;
    }

    this.contextUseCase.showSpinner();
     this.userRegisterUseCase.execute(this.form.value).subscribe(
     {
       next:(response) =>{
        this.contextUseCase.hideSpinner();
         this.notificationUseCase.showInfo("Registracion Exitosa", "Se ha registrado con exito!!! Se le envio un email para confirmar su cuenta.");
         this.router.navigate(['/login']);
       },
       error:(error)=>{
        this.contextUseCase.hideSpinner();
        this.notificationUseCase.showError('Error', error.error.message);
       }
     });
   }
 
 }
 