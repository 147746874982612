import { TEntity } from "./base.model";

export class ReferenceModel {
    static empty(): ReferenceModel {
       return new ReferenceModel(0,'');
    }

    name: string;
    id: number;
    
    constructor(id: number, name:string){
        this.name = name;
        this.id = id;
    }
 }