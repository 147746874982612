import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { ParentModel } from 'src/domain/models/parent.model';
import { ParentRepository } from 'src/domain/repositories/parent.repository';


@Injectable({
    providedIn: 'root',
})
export class ParentGetByStudentUseCase implements UseCase<number, ParentModel[]> {

    constructor(private parentRepository: ParentRepository) { }

    execute(
        studentId: number,
    ): Observable<ParentModel[]> {
        return this.parentRepository.getParentsByStudent(studentId);
    }
}
