import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { UserRepository } from 'src/domain/repositories/user.repository';
import { SchoolRepository } from 'src/domain/repositories/school.repository';
import { UserImplementationRepository } from './repositories/user-implementation.repository';
import { SchoolImplementationRepository } from './repositories/school-implementation.repository';
import { CourseImplementationRepository } from './repositories/course-implementation.repository';
import { CalendarElementImplementationRepository } from './repositories/calendar-element-implementation.repository';
import { CourseRepository } from 'src/domain/repositories/course.repository';
import { CalendarElementRepository } from 'src/domain/repositories/calendar-element.repository';
import { LookupRepository } from 'src/domain/repositories/lookup.repository';
import { LookupImplementationRepository } from './repositories/lookup-implementation.repository';
import { GlobalSearchRepository } from 'src/domain/repositories/global-search.repository';
import { GlobalSearchImplementationRepository } from './repositories/global-search-implementation.repository';
import { StudentRepository } from 'src/domain/repositories/student.repository';
import { StudentImplementationRepository } from './repositories/student-implementation.repository';
import { ParentRepository } from 'src/domain/repositories/parent.repository';
import { ParentImplementationRepository } from './repositories/parent-implementation.repository';
import { ProvinceRepository } from 'src/domain/repositories/province.repository';
import { ProvinceImplementationRepository } from './repositories/province-implementation.repository';
import { SubscriptionRepository } from 'src/domain/repositories/subscription.repository';
import { SubscriptionImplementationRepository } from './repositories/subscription-implementation.repository';
import { SubscriptorRepository } from 'src/domain/repositories/subscriptor.repository';
import { SubscriptorImplementationRepository } from './repositories/subscriptor-implementation.repository';



@NgModule({
    providers: [
        { provide: UserRepository, useClass: UserImplementationRepository },
        { provide: SchoolRepository, useClass: SchoolImplementationRepository},
        { provide: CourseRepository, useClass: CourseImplementationRepository },
        { provide: CalendarElementRepository, useClass: CalendarElementImplementationRepository },
        { provide: LookupRepository, useClass: LookupImplementationRepository },
        { provide: GlobalSearchRepository, useClass: GlobalSearchImplementationRepository },
        { provide: StudentRepository, useClass: StudentImplementationRepository },
        { provide: ParentRepository, useClass: ParentImplementationRepository },
        { provide: ProvinceRepository, useClass: ProvinceImplementationRepository },
        { provide: SubscriptionRepository, useClass: SubscriptionImplementationRepository },
        { provide: SubscriptorRepository, useClass: SubscriptorImplementationRepository },
        
    ],
    imports: [
        CommonModule,
        HttpClientModule,
    ],
})
export class RepositoryModule { }
