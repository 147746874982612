<div class="col text-center">
      <div class="col text-center p-5">
          <img src="presentation/assets/logo.png" alt="" style="max-width: 90px;">
     </div>
</div>
<div *ngIf="isConfirmed == false" class="col text-center">
    <div>Aguarde por favor, estamos confirmando su email...</div>
</div>
<div *ngIf="isConfirmed == true" class="col text-center">
<div class="m-5"><strong>Email confirmado con exito!!!!</strong></div>
<div>Muchas gracias por confirmar tu email. Te avisaremos cuando tu usuario este listo. Esto puede demorar como maximo 24 horas.</div>
<button type="button" class="btn btn-primary btn-user m-5" onclick="window.top.close();">Cerrar</button>
</div>

