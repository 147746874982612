import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { StudentModel } from 'src/domain/models/student.model';
import { StudentGetByIdUseCase } from 'src/domain/usecases/student/student-get-by-id-usecase';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { StudentUpdateUseCase } from 'src/domain/usecases/student/student-update.usecase';

@Component({
  selector: 'app-student-edit',
  templateUrl: './student-edit.component.html',
  styleUrls: ['./student-edit.component.css']
})
export class StudentEditComponent implements OnInit {

  id!: number;
  student: StudentModel | undefined;
  form: FormGroup;
  key: FormControl = new FormControl('');
  full_name: FormControl = new FormControl('', Validators.required);
  email: FormControl = new FormControl('', [Validators.email]);
  full_address: FormControl = new FormControl('');
  telephone_number: FormControl = new FormControl('');
  school_id: FormControl = new FormControl('');
  course_id: FormControl = new FormControl('');
  description: FormControl = new FormControl('');
  dni: FormControl = new FormControl('');
  age: FormControl = new FormControl('');
  allergies: FormControl = new FormControl('');
  photo: string = 'presentation/assets/avatar-user.png';
  birth_date: FormControl = new FormControl('');
  year_of_entry:FormControl = new FormControl('');
  vocal_cord:FormControl = new FormControl('');



  constructor(private formBuilder: FormBuilder,
              private activatedRoute:ActivatedRoute,
              private studentGetByIdUseCase:StudentGetByIdUseCase,
              private contextUseCase: ContextUseCase,
              private studentUpdateUseCase:StudentUpdateUseCase,
              private location:Location) { 

                this.form = formBuilder.group({
                  id: this.key,
                  full_name: this.full_name,
                  email: this.email,
                  dni: this.dni,
                  telephone_number: this.telephone_number,
                  description: this.description,
                  age: this.age,
                  allergies: this.allergies,
                  photo: this.photo,
                  birth_date:this.birth_date,
                  year_of_entry:this.year_of_entry,
                  vocal_cord:this.vocal_cord
                });
              }

  ngOnInit(): void {
    this.contextUseCase.hideToggledNav();
    this.contextUseCase.showSpinner();
    this.id = this.activatedRoute.snapshot.params['id'];
   
    this.loadStudent(this.id);
  }

  loadStudent(id: number) {
    this.studentGetByIdUseCase.execute(id).subscribe({
      next:(student)=>{
       this.student = student;
       this.form.controls['id'].setValue(this.student?.id); 
       this.form.controls['dni'].setValue(this.student?.dni); 
       this.form.controls['full_name'].setValue(this.student?.full_name); 
       this.form.controls['email'].setValue(this.student?.email); 
       this.form.controls['telephone_number'].setValue(this.student?.telephone_number); 
       this.form.controls['description'].setValue(this.student?.description); 
       this.form.controls['age'].setValue(this.student?.age); 
       this.form.controls['allergies'].setValue(this.student?.allergies); 
       this.form.controls['photo'].setValue(this.student?.photo); 
       this.form.controls['birth_date'].setValue(this.student?.birth_date); 
       this.form.controls['year_of_entry'].setValue(this.student?.year_of_entry); 
       this.form.controls['vocal_cord'].setValue(this.student?.vocal_cord); 

       if(this.student?.photo != null)
          this.photo = this.student?.photo;

       this.contextUseCase.hideSpinner();
      }
    });
  }


  updatePhotoHandler(event:any) {
    let photoFile = event.target.files[0];
    
    const reader = new FileReader();
    reader.readAsDataURL(photoFile);
    reader.onload = () => {
      this.photo = reader.result + '';
      this.form.controls['photo'].setValue(this.photo); 
    };
  }


  cancel(){
    this.location.back();
  }

  saveHandler(): void{
    if(this.form.invalid){
      
      this.form.markAllAsTouched();
      return;
    }

    this.studentUpdateUseCase.execute(this.form.value).subscribe();

   }
 
 }
 