import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { SubscriptorModel } from 'src/domain/models/subscriptor.model';
import { SubscriptorRepository } from 'src/domain/repositories/subscriptor.repository';

@Injectable({
    providedIn: 'root',
})
export class SubscriptorGetAllUseCase implements UseCase<Boolean, SubscriptorModel[]> {

    constructor(private subscriptorRepository: SubscriptorRepository) { }

    execute(
        force: Boolean,
    ): Observable<SubscriptorModel[]> {
        return this.subscriptorRepository.getAll(force);
    }
}
