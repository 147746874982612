import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SchoolModel } from 'src/domain/models/school.model';

@Component({
  selector: 'app-school-table',
  templateUrl: './school-table.component.html',
  styleUrls: ['./school-table.component.css']
})
export class SchoolTableComponent implements OnInit {

  @Input() schools: SchoolModel[] = [];
  @Output() deleteEvent = new EventEmitter<SchoolModel>();

  constructor() { }

  ngOnInit(): void {
  }

  deleteHandler(school:SchoolModel){
    this.deleteEvent.emit(school);
  }

}
