import { Observable } from 'rxjs';
import { BaseRepository } from './base.repository'
import { ParentModel } from '../models/parent.model';


export abstract class ParentRepository implements BaseRepository<ParentModel>{
    abstract getById(id: number): Observable<ParentModel>;
    abstract getAll(force:Boolean): Observable<ParentModel[]>;
    abstract create(model:ParentModel): Observable<ParentModel>;
    abstract update(model:ParentModel): Observable<ParentModel>;
    abstract getCurrent():ParentModel | undefined;
    abstract delete(model:ParentModel): Observable<void>;
    abstract getParentsByStudent(studentId:number): Observable<ParentModel[]>;

}