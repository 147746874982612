    <div class="card shadow mb-4">
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="text-center small">Nombre</th>
                            <th class="text-center small">DNI</th>
                            <th class="text-center small">Email</th>
                            <th class="text-center small">Telefono</th>
                            <th class="text-center small">Descripcion</th>
                            <th class="text-center small" style="width: 40px;" ></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let student of students;">
                            <td class="small p-1">{{student.full_name}}</td>
                            <td class="small p-1">{{student.dni}}</td>
                            <td class="small p-1">{{student.email}}</td>
                            <td class="small p-1">{{student.telephone_number}}</td>
                            <td class="small p-1">{{student.description}}</td>
                            <td routerLink="/student/{{student.id}}/detail" class="wlm-link text-center" style="width: 40px;" title="abrir registro"><i class="fas fa-eye"></i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
