import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { ReferenceModel } from 'src/domain/models/reference.model';
import { LookupUseCase } from 'src/domain/usecases/common/lookup.usecase';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent implements OnInit, OnDestroy {

  @Output() referenceSelect = new EventEmitter<ReferenceModel | undefined>();  
  @Input() label:string = '';
  @Input() entityName:string = '';
  @Output() changedStateEvent = new EventEmitter<Boolean>();
  @Input() validateEvent = new EventEmitter<void>();
  @Input() referenceSet = new EventEmitter<ReferenceModel>();  
  @Input() readOnly:Boolean = false;
  currentReference: ReferenceModel | undefined;
  searching: Boolean = false;
  referenceSearch:string | undefined;
  referenceResult:Observable<ReferenceModel[]> = of([]);
  suscribeLoading!: Subscription;
  notResult:Boolean = false;
  isValid:Boolean = false;
  isChanged:Boolean = false;

  constructor(private lookupUseCase: LookupUseCase) { 
  }

  ngOnDestroy(): void {
    this.suscribeLoading?.unsubscribe();
  }
  ngOnInit(): void {
    this.validateEvent.subscribe(()=>{
      this.changedState();
     });  
     
    this.referenceSet.subscribe((value)=>{
       this.currentReference = value;
       this.referenceSearch = value?.name;

       if(value != undefined)
          this.isValid = true;
    });
}

changedState(){
  this.isValid = true;

  if(this.currentReference == undefined){
    this.isValid = false;
    this.isChanged = true;
  }
  
  this.changedStateEvent.emit(this.isValid);
}

blurHandler(){
  setTimeout(()=>{
      this.referenceResult = of([]);

      if(this.referenceResult == null)
        this.referenceSearch = '';

      if(this.isChanged && this.referenceResult == undefined)
         this.referenceSelect.emit(undefined);
  }, 1000);
}

searchHandler(){

  if(this.referenceSearch == undefined || this.referenceSearch == ' ' || this.referenceSearch == '')
    return;

  this.searching = true;
  this.notResult = false;
  this.suscribeLoading?.unsubscribe();
  
  this.referenceResult = this.lookupUseCase.search(this.entityName, this.referenceSearch);

  this.suscribeLoading = this.referenceResult.subscribe(
    {
      next:(referenceResult)=>{
        setTimeout(()=>{this.searching = false;}, 1500);
        if(referenceResult.length == 0)
           this.notResult = true;
      }
    }
  );


}

onSelectHandler(value:ReferenceModel){
  this.isChanged = true;
  this.isValid = true;
  this.currentReference = value;
  this.referenceSelect.emit(value);
  this.referenceSearch = value.name;
  this.referenceResult = of([]);
}

}

