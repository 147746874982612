import { ComponentRef, EventEmitter, Injectable} from '@angular/core';
import { ModalModel } from '../models/modal.model';


@Injectable({
  providedIn: 'root'
})
export abstract class ModalService {
   

}
