import { Component, OnInit } from '@angular/core';
import { StudentModel } from 'src/domain/models/student.model';
import { ContextUseCase } from 'src/domain/usecases/common/context.usecase';
import { StudentImportUseCase } from 'src/domain/usecases/student/student-import.usecase';

@Component({
  selector: 'app-student-import',
  templateUrl: './student-import.component.html',
  styleUrls: ['./student-import.component.css']
})
export class StudentImportComponent implements OnInit{

  progressBarWidth:string = '0';
  fileImport!:File;
  processingFile:Boolean = false;
  students:StudentModel[] = [];
  collapseOne:Boolean = true;
  collapseTwo:Boolean = false;
  collapseThree:Boolean = false;
  collapseFour:Boolean = false;
  collapseFive:Boolean = false;
  phaseOneCompleted:Boolean = false;
  phaseTwoCompleted:Boolean = false;
  phaseThreeCompleted:Boolean = false;
  phaseFourCompleted:Boolean = false;
  phaseFiveCompleted:Boolean = false;

  constructor(private studentImportUseCase:StudentImportUseCase,
              private contextUseCase:ContextUseCase) { 

  }
  ngOnInit(): void {
    this.contextUseCase.hideToggledNav();
  }


  onChangeFileHandler(event:any) {
    this.fileImport = event.target.files[0];
 }

 nextOneHandler() {

  this.processingFile = true;

    const formData = new FormData(); 
      
    formData.append("file", this.fileImport, this.fileImport.name);
      
    this.studentImportUseCase.execute(formData).subscribe({
      next:(students)=>{
        this.students = students;
        this.processingFile = false;
        this.phaseOneCompleted = true;
        this.collapseTwo = true;
        this.collapseOne = false;
      },
      error:(error)=>{
        this.processingFile = false;
      }
    });
}

nextTwoHandler(){
  this.phaseTwoCompleted = true;
  this.collapseThree = true;
  this.collapseTwo = false;
}

nextThreeHandler(){
  this.phaseThreeCompleted = true;
  this.collapseFour = true;
  this.collapseThree = false;
}

nextFourHandler(){
  this.phaseFourCompleted = true;
  this.collapseFive = true;
  this.collapseFour = false;
  this.progressBarWidth = '30%';

  debugger
}


}
