    <div class="card shadow mb-4">
        <div class="card-body m-1 p-0">
            <div class="table-responsive">
                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th class="text-center small">Nombre</th>
                            <th class="text-center small">Direccion</th>
                            <th class="text-center small">Email</th>
                            <th class="text-center small">Telefono</th>
                            <th class="text-center small" style="width: 40px;" ></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let school of schools;">
                            <td class="small p-1">{{school.name}}</td>
                            <td class="small p-1">{{school.full_address}}</td>
                            <td class="small p-1">{{school.email}}</td>
                            <td class="small p-1">{{school.telephone_number}}</td>
                            <td routerLink="/school/{{school.id}}/{{school.name}}/detail" class="wlm-link text-center" style="width: 40px;" title="abrir registro"><i class="fas fa-eye"></i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>