import { Observable } from 'rxjs';
import { BaseRepository } from '../repositories/base.repository'
import { ScheduleModel } from '../models/schedule.model';


export abstract class ScheduleRepository implements BaseRepository<ScheduleModel>{
    abstract getById(id: number): Observable<ScheduleModel>;
    abstract getAll(force:Boolean): Observable<ScheduleModel[]>;
    abstract create(model:ScheduleModel): Observable<ScheduleModel>;
    abstract update(model:ScheduleModel): Observable<ScheduleModel>;
    abstract getCurrent():ScheduleModel | undefined;
    abstract delete(model:ScheduleModel):Observable<void>;
    abstract getScheduleByCourse(courseId:number):Observable<ScheduleModel[]>;
}