import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { CourseModel } from 'src/domain/models/course.model';
import { ToastTypes } from 'src/domain/models/toast-types';
import { CourseRepository } from 'src/domain/repositories/course.repository';
import { ContextUseCase } from '../common/context.usecase';
import { NotificationUseCase } from '../common/notification.usecase';
import { GlobalSearchUseCase } from '../common/global-search.usecase';
import { StudentRepository } from 'src/domain/repositories/student.repository';


@Injectable({
    providedIn: 'root',
})
export class CourseDeleteUseCase implements UseCase<CourseModel, void> {

   constructor(private courseRepository: CourseRepository,
        private notificationUseCase: NotificationUseCase,
        private contextUseCase:ContextUseCase,
        private globalSearchUseCase:GlobalSearchUseCase,
        private studentRepository: StudentRepository) { }

    execute(
        course: CourseModel,
    ): Observable<void> {
         return new Observable<void>((observable)=>{

        let suscription = this.notificationUseCase.showQuestion('Eliminar', '¿Desea eliminar el curso ' + course.name +'?').subscribe((response)=>{
  
            if(response == true){
                this.courseRepository.delete(course).subscribe({
                   next: ()=> {
                        this.contextUseCase.showSpinner();
                        this.globalSearchUseCase.updateResults.emit();
                        this.contextUseCase.addToast('Eliminado', 'curso eliminado con exito', ToastTypes.Success);
                        observable.next();
                    },
                   error: ()=>{
                        this.contextUseCase.hideSpinner();
                        observable.error()
                   },
                   complete:()=>{
                    this.studentRepository.getAll(true).subscribe();
                    this.contextUseCase.hideSpinner();
                     observable.complete();
                     observable.unsubscribe();
                     suscription.unsubscribe();
                   } 
                });
            }
        });

    })
    }
}
