import { Injectable } from '@angular/core';

declare var bootstrap: any;

@Injectable({
    providedIn: 'root',
})
export class BoostrapUseCase {

    constructor() { }
     

        public initBoostrap(){
            var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new bootstrap.Tooltip(tooltipTriggerEl)
            })   
       }
   
       public destroyBoostrap(){
           var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
           var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
           return new bootstrap.Tooltip(tooltipTriggerEl).dispose()
           })   
        }

}