import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from 'src/core/use-case';
import { CourseModel } from 'src/domain/models/course.model';
import { ToastTypes } from 'src/domain/models/toast-types';
import { CalendarElementRepository } from 'src/domain/repositories/calendar-element.repository';
import { CourseRepository } from 'src/domain/repositories/course.repository';
import { ContextUseCase } from '../common/context.usecase';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class CourseCreateUseCase implements UseCase<CourseModel, CourseModel> {

    constructor(private courseRepository: CourseRepository,
                private calendarElementRepostory: CalendarElementRepository,
                private contextUseCase:ContextUseCase,
                private location: Location) { }

    execute(
        course: CourseModel,
    ): Observable<CourseModel> {
        return new Observable<CourseModel>((observer)=>{
        this.contextUseCase.showSpinner();
      
        this.courseRepository.create(course).subscribe(
           {
             next:(coursePost) =>{
               this.calendarElementRepostory.updateCourseCalendar(coursePost).subscribe({
                    next:()=>{
                        this.contextUseCase.addToast("registro creado", "el curso se creo con exito!!!", ToastTypes.Success);
                        this.location.back();
                    },
                    complete: ()=> {
                        this.contextUseCase.hideSpinner();
                        observer.complete();
                    }
                });    
             
             },
             error: (error) =>{
              this.contextUseCase.hideSpinner();
              observer.error(error);
             },
             complete:()=>observer.complete()
           });
        })
    }
}
