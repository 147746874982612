import { BaseRepository } from './base.repository';
import { CalendarElementModel } from '../models/calendar-element.model';
import { Observable } from 'rxjs';
import { CourseModel } from '../models/course.model';

export abstract class CalendarElementRepository implements BaseRepository<CalendarElementModel> {
    abstract getById(id: number): Observable<CalendarElementModel>
    abstract getAll(force:Boolean): Observable<CalendarElementModel[]> 
    abstract create(model: CalendarElementModel): Observable<CalendarElementModel> 
    abstract update(model: CalendarElementModel): Observable<CalendarElementModel> 
    abstract getCurrent(): CalendarElementModel | undefined 
    abstract delete(model: CalendarElementModel): Observable<void>
    abstract updateCourseCalendar(course: CourseModel):Observable<void> 
}
